@import 'constants/styles/breakpoints.constant.module.scss';

.root {
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 32px 32px 24px 32px;
}

.value {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.percent {
  padding: 24px 32px 32px 32px;
}

@include from767andLower {
  .header {
    padding: 32px 24px 24px 24px;
  }
  .value {
    padding: 24px;
  }
  .percent {
    padding: 24px 24px 32px 24px;
  }
}
