@import 'constants/styles/colors.constant.module';
@import 'constants/styles/breakpoints.constant.module.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.section {
  width: 100%;
  padding: 30px 40px;
  overflow: unset !important;
}

.underline {
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background-color: $color-grey-3;
}

.content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 29.5px;
}

.list {
  display: grid;
  grid-template-columns: 1fr 196px 196px;
  grid-template-rows: auto;
  column-gap: 16px;
  row-gap: 16px;
}

.listTimezone {
  flex-direction: column;
  display: flex;
  gap: 16px;
}

.labelClassName {
  height: 0 !important;
  margin: 0 !important;
}

.errorClassName {
  height: 0 !important;
  margin-bottom: 0 !important;
}

.selectInput {
  & label {
    display: none !important;
  }

  & * {
    color: $color-primary-text !important;
  }
}

@include from767andLower {
  .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 16px;
    row-gap: 16px;
  }

  .dayText,
  .inputDayName,
  .timezonePlaceholder {
    display: none !important;
  }

  .section {
    padding: 24px 18px;
  }

  .selectInput {
    & label {
      display: block !important;
    }
  }
}
