@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.form {
  width: 100%;
}

.formFields {
  display: grid;
  grid-template-rows: '1fr 1fr';
}

.rememberBlock {
  display: flex;
  margin-bottom: 36px;
  justify-content: space-between;
}

.forgotLink {
  font-size: 14px;
  font-family: $font-family-regular;
  color: $color-typography-body-greyed;
  text-align: right;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
