@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.dialogOverlay {
  z-index: 11;
}

.dialogContent {
  width: 913px !important;
  z-index: 13;
  padding: 24px;
}

.dialogBody {
  margin-top: 16px;
}

.button {
  min-width: 224px;
  height: 48px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
}
.description {
  margin: 24px 0px;
}
.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

@include from1049andLower {
  .dialogContent {
    width: unset !important;
  }
}

@include from524andLower {
  .footer {
    flex-direction: column;

    .button {
      width: 100%;
    }
  }

  .dialogBody {
    margin-top: 8px;
  }

  .dialogDescription {
    margin-top: 8px;
  }
}
