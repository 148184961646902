@import 'constants/styles/breakpoints.constant.module.scss';

.smallText {
  margin-left: 10px;
  white-space: nowrap;
}

.grid {
  display: grid;
  column-gap: 16px;
  row-gap: 16px;

  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: 254px auto;

  grid-template-areas:
    'total leads tier'
    'orders orders tier';
}

.boxTotal {
  grid-area: total;
}

.boxLeads {
  grid-area: leads;
}

.boxOrders {
  grid-area: orders;
}

.boxTier {
  grid-area: tier;
}

@include from1439andLower {
  .grid {
    grid-template-columns: 1fr 1fr;

    grid-template-areas:
      'total leads'
      'orders orders'
      'tier tier';
  }
}

@include from767andLower {
  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    grid-template-areas:
      'total'
      'leads'
      'orders'
      'tier';
  }
  .boxTier {
    height: auto;
  }
}
