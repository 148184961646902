@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.dropdownMenuContent {
  width: 140px !important;
  max-height: 300px;
  overflow-y: scroll;
}

.divider2 {
  display: none !important;
}

.oneLineText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.isNotClickable {
  cursor: not-allowed !important;
}

.dropdownMenuItemInner {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px 8px;
  border-radius: 0;
  font-family: $font-family-regular;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    background-color: $color-grey-7;
  }
}

.isDisabledDropdownAssignmentMenu {
  pointer-events: none;
  cursor: not-allowed !important;
}

.isAssigneeDisabled {
  pointer-events: none;

  .dropdownMenuItemInner {
    background-color: $color-grey-7;
  }
}

.divider {
  visibility: hidden;
}

.dropdownMenuItem {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header {
  background: $color-primary-background;
  display: flex;
  flex-direction: column;

  .headerRow {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(min-content, 120px) 162px 1fr 162px auto;
    column-gap: 24px;
    width: 100%;
    padding: 8px 20px;
    min-height: 42px;

    & > * {
      display: flex;
      align-items: center;
    }

    .orderNumberWrapper {
      text-align: left;
      display: flex;
      flex-wrap: wrap;

      .orderNumber {
        margin-right: 4px;
      }

      .secondText {
        color: $color-secondary-text;
      }
    }

    .orderHolder,
    .orderHolderMobile {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .pink {
        &:first-child {
          text-align: right;
        }
      }

      .dash {
        color: $color-primary-text;
        margin: 0 4px;
      }
    }

    .assignOrderText {
      max-width: 110px;
      width: min-content;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .assignToMeOrderText {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .profileIcon {
      margin-right: 8px;
    }

    .triggerAssignment {
      border: none;
      background-color: transparent;
      display: flex;
      outline: none;
      text-align: right;
      justify-content: flex-end;
      padding: 0;
    }

    .isDisabledDropdownAssignmentMenu {
      pointer-events: none;
    }

    .dropdownAssignmentMenu {
      outline: none;
    }

    .chevronDown {
      transition: 0.2s transform;
      outline: none;
      margin-left: 8px;
    }

    .chevronUp {
      transition: 0.2s transform;
      transform: rotate(180deg);
    }

    .date {
      text-align: end;
    }
  }
}

@include from1023andLower {
  .header {
    .headerRow {
      grid-template-rows: 1fr 1px 1fr;
      grid-template-columns: 1fr auto;
      column-gap: 24px;
      row-gap: 8px;
      min-height: 36px;

      .orderHolder,
      .orderHolderMobile {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .orderNumberWrapper {
      grid-column: 1/2;
      grid-row: 1/2;
    }

    .date {
      grid-column: 2/3;
      grid-row: 1/2;
      text-align: right;
      justify-content: flex-end;
    }

    .divider {
      visibility: visible;
      grid-column: 1/3;
      grid-row: 2/3;
    }

    .orderHolder {
      text-align: left;
      justify-content: flex-start !important;
    }
  }
}

@include from767andLower {
  .header {
    .headerRow {
      grid-template-rows: auto 1px auto 1px auto;
      grid-template-columns: auto auto;
      column-gap: 24px;
      row-gap: 8px;

      justify-content: space-between;

      .divider2 {
        display: flex !important;
        grid-column: 1/3;
        grid-row: 4/5;
      }

      .orderHolder {
        grid-column: 1/3;
        grid-row: 3/4;
        justify-content: center !important;
        text-align: center;
      }

      .triggerAssignment {
        grid-column: 2/3;
        grid-row: 5/6;
      }
    }
  }
}
