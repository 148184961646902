@import 'constants/styles/breakpoints.constant.module.scss';

.block {
  padding: 40px;
  height: inherit;
  text-align: center;
  width: 680px;
}

@include from1919andLower {
  .block {
    width: 664px;
  }
}

@include from1419andLower {
  .block {
    width: 728px;
  }
}

@include from1023andLower {
  .block {
    width: 536px;
  }
}

@include from767andLower {
  .block {
    padding: 24px;
    width: 294px;
  }
}
