@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: initial;
}

.twoInRowContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 6px;

  .input {
    flex: 1;
  }
}

div.inputWrapper {
  border-radius: 100px;
}

@include from767andLower {
  .input {
    flex: none;
    min-width: 100%;
  }

  .scrollable {
    overflow-y: auto;
    max-height: 300px;
  }
}
