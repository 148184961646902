@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 913px !important;
  z-index: 13;
  padding: 24px;
  hr {
    margin-top: 24px;
  }
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.btn {
  width: 224px;
  height: 48px;
}

.link {
  color: $color-pink;
}

.textWrapper {
  min-height: 52px;
  margin-top: 24px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .titleClassName {
    max-width: 250px;
  }
  .btn {
    min-width: 178.5px;
  }
}

@include from524andLower {
  .controlButtons {
    display: flex;
    flex-direction: column;
    button {
      margin-top: 5px;
      width: 100%;
    }
  }
}
