@import 'constants/styles/breakpoints.constant.module';

.content {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  height: 90%;
}

@include from767andLower {
  .content {
    height: 95%;
  }
}
