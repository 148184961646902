@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';

.header {
  display: flex;
  flex-direction: row;

  button {
    height: 32px;
    width: 200px;
    margin-left: 10px;
  }
}

.discardButton {
  background-color: $color-primary-background;
}

@include from767andLower {
  .header {
    background-color: $color-primary-background;
    width: 100%;
    padding: 16px 24px 24px 24px;
    gap: 16px;
    left: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 15;
    height: 150px;

    button {
      height: 80px;
      width: 100%;
      margin: 0;
    }
  }
}
