@import 'constants/styles/breakpoints.constant.module.scss';

.submitted {
  display: grid;
  grid-template-columns: 520px;
  grid-template-rows: auto;
  row-gap: 20px;

  div {
    text-align: center;
  }
}

@include from1439andLower {
  .submitted {
    grid-template-columns: 632px;
  }
}

@include from1023andLower {
  .submitted {
    grid-template-columns: 456px;
  }
}

@include from767andLower {
  .submitted {
    grid-template-columns: 279px;
  }
}
