@import 'constants/styles/breakpoints.constant.module.scss';

.frame {
  z-index: 15;
}

@include from767andLower {
  .frame {
    z-index: 15;
  }
}
