@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';
@import 'constants/styles/fonts.constant.module.scss';

.popup-title {
  text-transform: uppercase;
  font-size: 24px;
}
.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  z-index: 13;
  padding: 24px;
}

.orderLink {
  margin-top: 20px;
}

.itemName {
  font-family: $font-family-bold;
  color: $color-primary-text;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.mockupBlock {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-right: 20px;

  .pageCounter {
    text-align: center;
    color: $color-text-900;
    background-color: $color-light-blue;
    border: 1px solid $color-light-blue;
    border-radius: 25px;
    width: 35%;
    margin-bottom: 10%;
    height: 5%;
    font-family: $font-family-regular;
  }
  .previewWrapper {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 300px;
      max-height: 350px;
    }
  }
}

.mockupInfoBlock {
  width: 60%;
}

.subTitle {
  font-size: 13px;
  font-weight: bold;
}
.scrollableBlock {
  max-height: 120px;
  min-height: 120px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: $color-grey-5;
  padding: 0px 5px;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-grey-5;
  }
}

.colorsBlock {
  border: 1px solid $color-grey-3;
  padding: 5px;
  border-radius: 10px;
  padding: 5px;
  margin-top: 15px;
}

.itemCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $color-grey-3;
  padding: 5px 5px;
  border-radius: 10px;
  margin-top: 16px;
  .itemMockup {
    display: flex;
    img {
      width: 47px;
      height: 60px;
      margin-right: 10px;
      object-fit: contain;
    }
  }
}

.dialogFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  button {
    width: 200px;
  }
}

.link {
  color: $color-pink;
  text-decoration: none;
}

.addColorsBlock {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  .input {
    width: 55%;
  }
  button {
    width: 200px;
    cursor: pointer;
  }
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $color-grey-3;
  margin: 1em 0;
  padding: 0;
}

.colorsTitle {
  margin: 5px 10px;
  font-family: $font-family-bold;
  color: $color-primary-text;
  font-size: 16px;
  line-height: 26px;
}

.pantoneColorsCatalog {
  margin: 5px 10px;
  color: $color-primary-text;
  font-family: $font-family-regular;
}

.colorPicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  img {
    cursor: pointer;
  }
}

.color {
  display: block;
  width: 65%;
  height: 40px;
  border: 1px solid $color-black;
  border-radius: 20px;
  margin: 10px 0;
}

.colorName {
  margin-left: 10px;
  font-size: 16px;
  line-height: 26px;
  color: $color-pink;
  font-family: $font-family-regular;
}

.controlButton {
  background-color: transparent;
  outline: none;
  border: 1px solid $color-black;
  border-radius: 100px;
  padding: 7px;
}

.noColor {
  background-color: $color-grey-3;
  background-image: linear-gradient(
      45deg,
      $color-grey-6 25%,
      transparent 25%,
      transparent 75%,
      $color-grey-6 75%,
      $color-grey-6
    ),
    linear-gradient(
      45deg,
      $color-grey-6 25%,
      transparent 25%,
      transparent 75%,
      $color-grey-6 75%,
      $color-grey-6
    );
  background-size: 10px 10px;
  background-position:
    0 0,
    5px 5px;
}

.labelWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

.label {
  border-radius: 24px;
  padding: 0px 8px;
  height: 24px;
  display: flex;
  align-items: center;
  background-color: $color-light-blue;
  text-transform: capitalize;
}

@include from1439andLower {
  .addColorsBlock {
    flex-direction: column;
    .input {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }
}

@include from767andLower {
  .main {
    flex-direction: column;
    overflow: auto;
  }

  .mockupBlock,
  .mockupInfoBlock {
    width: 100%;
    margin-right: 0;
  }

  .dialogContentWrapperClassName {
    height: calc(100% - 48px);
    display: grid;
    grid-template-rows: 52px auto 30px 138px;
  }
}

@include from524andLower {
  .previewWrapper img {
    width: 100%;
  }

  .colorPicker img {
    width: 36px;
  }

  .color {
    width: 50%;
  }

  .labelWrapper {
    padding-right: 0;
  }

  .dialogFooter {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
}

@media screen and (max-height: 774px) and (min-width: $bp767) {
  // == 729 + (window height + (24 + 24))
  .dialogContentWrapperClassName {
    height: calc(100% - 48px);
    display: grid;
    grid-template-rows: 52px auto 30px 86px;
  }

  .main {
    overflow: auto;
  }
}
