.link {
  text-decoration: none;
}

.header {
  display: block !important;
  margin-bottom: 8px !important;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.7;
}
