@import 'constants/styles/breakpoints.constant.module.scss';

.pagination {
  margin-top: 48px;
  margin-bottom: 128px;
  justify-content: center;
}

@include from1023andLower {
  .pagination {
    margin-top: 32px;
    margin-bottom: 96px;
  }
}
