@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.container {
  padding: 24px;

  .itemsCardsWrapper {
    padding: 1px;
    border-radius: 16px;
    overflow: hidden;

    .inventoryHeader {
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-family-regular;
      font-size: 15px;
      line-height: 26px;
      color: $color-primary-text;
      background-color: $color-light-pink-2;
    }
  }
}
