@import 'constants/styles/breakpoints.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  z-index: 13;
}

.dialogBody {
  overflow-y: scroll;
  max-height: 400px;
}

.dialogContent {
  display: flex;
  padding: 2px;
  flex-direction: column;
  box-sizing: border-box;
}

.dialogFooter {
  width: 100%;

  & > div {
    width: 50%;
    align-self: flex-end;
    display: flex;
    gap: 10px;

    & > * {
      flex: 1;
    }
  }
}

.inputsRow {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.input {
  flex: 1;
}

.stripeForm {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > * {
    width: auto;
    flex: 1;
  }
}

@include from767andLower {
  .inputsRow {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .input {
    width: 100%;
  }

  .dialogFooter {
    width: 100%;

    & > div {
      width: 100%;
      align-self: flex-end;
      display: flex;
      gap: 10px;

      & > * {
        flex: 1;
      }
    }
  }

  .stripeForm {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: left !important;
    gap: 0;
    & > * {
      width: 100% !important;
      flex: 1;
    }
  }
}
