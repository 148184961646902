@import 'constants/styles/breakpoints.constant.module.scss';

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  width: 180px;

  &:last-child {
    margin-bottom: 0;
  }
}

@include from767andLower {
  .row {
    width: 100%;
  }
}
