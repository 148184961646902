@import 'constants/styles/breakpoints.constant.module.scss';

.outer {
  margin-top: 40px;
}

.inner {
  padding: 85px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  margin-bottom: 24px;
}

.description {
  margin-bottom: 32px;
}

button.button {
  max-width: 343px;
  width: 100%;
  height: 50px;
}

@include from1023andLower {
  .outer {
    margin-top: 32px;
  }
}

@include from767andLower {
  button.button {
    max-width: 190px;
    white-space: nowrap;
  }
  .inner {
    padding: 85px 13px;
  }
  .title,
  .description {
    text-align: center;
  }

  .smallerTitle {
    font-size: 2rem;
  }
}
