@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.editorWrapper {
  display: flex;
  flex-direction: column;
  background-color: $color-neutral-100;
  border-radius: 16px;
  height: 240px !important;
  width: 100%;

  & > div {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
}

.editorText {
  padding: 0px 11px;
  flex: 1;
  *[style*='font-weight: bold;'] {
    font-family: $font-family-bold;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-bold;
    font-size: revert;
    font-weight: bold;
  }
}

@include from767andLower {
  .editorWrapper {
    height: 487px !important;
  }
}
