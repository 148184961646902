@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
.tooltipBody {
  max-height: 358px;
  overflow-y: auto;
  padding-right: 12px;
}
.divider {
  margin: 10px 0;
  display: block;
  width: 100%;
  height: 1px;
  background-color: $color-divider;
}

.colorIcon {
  height: 14px;
  width: 14px;
  cursor: default;
  margin: 0 5px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #ebebeb;
}

.name {
  text-transform: capitalize;
}

.row {
  display: flex;
  flex-direction: column;
}

.innerRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.name {
  text-transform: capitalize;
}

.colors {
  display: flex;
  align-items: center;
}
