@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.layout {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1200;
  pointer-events: auto;
  left: -100%;
}

.sidebar {
  background-color: $color-white;
  transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-shadow:
    0px 0px 1.5px rgba(0, 0, 0, 0.2),
    0px 6px 20px rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 560px;
  position: fixed;
  z-index: 1300;
  left: auto;
  top: 0px;
  transform: translate(590px, 0px);
  overflow: visible;
  right: 0px;
}

.isOpen .layout {
  left: 0;
  opacity: 1;
}

.isOpen .sidebar {
  transform: translate(0px, 0px);
}

.sideItem {
  padding: 32px;
  border-bottom: 1px solid $color-grey-3;
  cursor: pointer;
}

.sideHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $color-grey-3;
  padding: 32px;
}

.sideClose {
  cursor: pointer;
}

.unlink {
  text-decoration: none;
  color: $color-dark-blue;
  width: 100% !important;
  display: block;

  &:hover {
    text-decoration: none;
  }
}

@include from767andLower {
  .sidebar {
    width: 265px;
    transform: translate(275px, 0px);
  }
}
