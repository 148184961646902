@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/single-container.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.root {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background: $color-primary-blue-opacity;
}

.container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.logo {
  height: $single-container-logo-height;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content {
  margin-top: $single-container-logo-height;
  transform: translateY(-$single-container-logo-height);
}

.stars1 {
  position: fixed;
  left: 209px;
  top: 113px;
}

.stars2 {
  position: fixed;
  right: 189px;
  bottom: 168px;
}

.hRingLeft {
  position: fixed;
  left: 0;
  bottom: 0;
}

.hRingRight {
  position: fixed;
  right: 0;
  top: 0;
}
