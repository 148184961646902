@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';

.container {
  margin-bottom: 48px;
}

.tabs {
  transition: all 300ms cubic-bezier(0.4, 0, 1, 1);
}

div.mobileDropdownItem {
  border-radius: 100px;
  padding: 8px 16px;
}

@include from1023andLower {
  .container {
    margin-bottom: 32px;
  }
}

@include from767andLower {
  .container {
    gap: 0;
    flex-wrap: nowrap;
    height: 72px;
  }

  .tabs {
    display: none;
  }

  div.mobileContainer {
    display: flex;
    cursor: pointer;
    background-color: $color-tabs-selection;
    border-radius: 100px;
    padding: 12px 16px;

    & > div {
      display: flex;
      align-items: flex-end;
    }
  }

  div.mobileDropdownItem {
    border-radius: 8px;
    padding: 13px 8px;
    width: 310px;
    margin: 2px 0;
    font-size: 1rem;
  }

  .mobileTrigger {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 100px;
  }

  .arrow {
    margin-left: 13px;
  }
}
