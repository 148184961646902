@import '../common.module';

.block {
  padding: 24px 32px 0;
}

.wrapper {
  margin-bottom: 4px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}
