@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.boxHeader {
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-regular;
  color: $color-primary-text;
  background: $color-light-pink;
  font-size: 15px;
  line-height: 26px;
}
