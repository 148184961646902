@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.form {
  width: 100%;
}

.formFields {
  width: 100%;
  display: grid;
  grid-template-columns: 482;
  grid-template-rows: '1fr 1fr';
}
