@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.table {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.body {
  display: block;
  max-height: 235px;
  overflow: auto;
}

.row {
  width: 100%;
  padding: 16px 0;
  min-height: 48px;
  border-bottom: 1px solid $color-divider;
  display: flex;
  align-items: center;

  &:last-child {
    border: none;
  }
}

.cell {
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
}

.setupFee,
.unitPrice,
.quantity {
  @extend .cell;
  width: 80px;
}

.name {
  @extend .cell;
  width: 120px;
}

.amount {
  @extend .cell;
  width: 96px;
}

.tax {
  @extend .cell;
  width: 150px;
}

.shipping {
  @extend .cell;
  width: 115px;
}

.error {
  margin-bottom: 0;
}

.column,
.block {
  display: none;
}

.reset {
  border: none;
  margin: 0;
}
.divider,
.input {
  /* not empty */
}

.pink {
  color: $color-accent-500;
}

.colorDisabled {
  color: $color-border-default;
}

@include from1023andLower {
  .quantity {
    width: 62px;
  }
  .unitPrice {
    width: 70px;
  }
  .setupFee {
    width: 72px;
  }
  .shipping {
    width: 83px;
  }
  .tax {
    width: 80px;
  }
  .amount {
    width: 58px;
  }
}

@include from767andLower {
  .header,
  .divider {
    display: none;
  }
  .row {
    display: none;
  }
  .column {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    border-bottom: 1px solid $color-divider;
  }
  .block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;

    & > div:first-child {
      width: 35%;
      margin-right: 5px;
    }

    & > div:last-child {
      width: calc(100% - 35% - 5px);
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }
  }
  .input {
    max-width: 120px;
  }
}
