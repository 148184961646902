@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.dialogOverlay {
  z-index: 11;
}

.dialogContent {
  width: 890px !important;
  z-index: 13;
  padding: 24px !important;

  hr {
    margin-top: 24px;
  }
}

.dialogDescription {
  margin-top: 24px;
}

.dialogBody {
  margin-top: 24px;
}

.dialogBodyWrapper {
  display: flex;
  flex-direction: column;
}

.input {
  pointer-events: none;
}

.information {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;

  &:before {
    margin: 0 8px;
    content: '•';
    font-size: 1.3em;
    color: $color-secondary-text;
  }
}

.closeIcon {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.error {
  margin-bottom: 0;
}

.footer {
  margin-top: 24px;
}

.button {
  min-width: 224px;
  height: 48px !important;
}

@include from1049andLower {
  .dialogContent {
    width: unset !important;
  }
}

@include from767andLower {
  .button {
    min-width: 192px;
  }
  .dialogBody {
    margin-top: 8px;
  }
}

@include from524andLower {
  .footer {
    flex-direction: column;

    .button {
      width: 100%;
    }
  }

  .dialogBodyWrapper {
    overflow: scroll;
    max-height: 300px;
  }

  .dialogDescription {
    margin-top: 8px;
  }
}
