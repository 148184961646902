@import 'constants/styles/breakpoints.constant.module.scss';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: initial;
}

.twoInRowContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .input,
  .checkbox,
  .additionDiscounts {
    flex: 1;
  }
}

.errorClassName {
  margin-bottom: 0 !important;
}

.additionDiscounts {
  display: flex;
  flex-direction: column;

  label {
    line-height: 20px;
  }
  .checkboxList {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 11px 0px;
  }
}

.discountTypeIcon {
  margin-right: 8px;
}

.additionDiscountsLabel {
  margin-bottom: 4px;
}

@include from767andLower {
  .twoInRowContainer {
    flex-direction: column;
    gap: 30px;
  }
  .additionDiscounts {
    .checkboxList {
      flex-direction: column;
      padding: 6px 0px 0px;
      gap: 12px;
    }
  }

  .button {
    width: 100%;
  }
}
