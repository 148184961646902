@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  z-index: 13;
  padding: 24px !important;
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.link {
  color: $color-pink;
  white-space: nowrap;
}

.footer {
  margin-top: 24px;
  gap: 8px;
  button {
    min-width: 224px;
    height: 48px !important;
  }
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .btn {
    min-width: 100%;
  }
  .footer {
    display: flex;
    flex-direction: column;
  }
}
