@import 'constants/styles/box-widget.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.boxWidget {
  border-radius: $box-bor-rad;
  box-shadow: $box-shadow;
  background-color: $color-white;
  display: flex;
}

.gradientHead {
  background: $linear-gradient;
  height: 100px;
  width: 100%;
  border-radius: $box-bor-rad $box-bor-rad 0 0;
}
