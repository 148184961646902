$color-main: #2d0b61;
$color-white: #ffffff;
$color-black: #000000;
$color-dark-blue: #180733;
$color-violet: $color-main;
$color-pink: #e73af6;
$color-light-pink: #fabeff;
$color-light-pink-2: #eee0ff;
$color-border-subdued-color: #e0e0e0;
$color-grey-1: #777777;
$color-typography-body: #5f5f5f;
$color-grey-4: #fafafa;
$color-text-disabled: #b0b4be;
$color-grey-3: #f3f3f3;
$color-grey-5: #eaeaea;
$color-grey-6: #7d8283;
$color-grey-7: #f6f6f7;
$color-primary-background: #f6f6f7;
$color-select-hover: #deebff;
$color-tabs-selection: #e6edff;
$color-grey-dark: #2f2243;
$color-light-gray-warm: #eaeaea;
$color-light-gray: #b6bdc4;
$color-primary-blue: #4e41f9;
$color-light-blue: #d0ddff;
$color-primary-blue-opacity: rgba(78, 65, 249, 0.1);
$color-primary-purple: #8f39ff;
$color-hover-purple: #691ee0;
$color-green: green;
$color-light-green: #258750;
$color-green-primary: #258750;
$mono-gray-2: #acadad;
$color-interactive: #415ef9;
$color-primary-text: #241240;
$color-secondary-text: #6d6d75;
$color-typography-headings: #2f2243;
$color-typography-body-greyed: #a6a6a6;
$color-border-default: #b6bdc3;
$color-divider: #edeeef;
$color-error-text: #d83a52;
$color-text-subdued: #6d6d75;
$color-text-icon: #5c5f62;
$color-state-pending: #f2af2f;
$color-green: #86d169;
$color-red: #f44336;
$color-yellow: #ffd400;

$color-text-500: #8c9196;
$color-text-600: $color-secondary-text;
$color-text-900: #241240;
$color-success-700: #258750;
$color-pending-500: $color-state-pending;
$color-pending-700: #e49c12;
$color-secondary-text-500: #3e85f0;
$color-destructive-100: #fff0f0;
$color-destructive-300: #f0bfc7;
$color-destructive-700: #aa2d3d;
$color-neutral-300: #d2d5d8;
$color-neutral-200: #edeeef;
$color-neutral-250: #e2e7ea;
$color-neutral-100: $color-primary-background;
$color-accent-500: $color-pink;
$color-sidebar-background: #d9d9d9;
