.outletWrapper {
  display: flex;
  justify-content: center;
  margin: 40px 0 80px 0;
}

.outletWrapperOnlyMyOrders {
  margin: 0 0 80px 0;
}

.outletWrapperInner {
  width: 100%;
}

.navigateMobileTrigger {
  text-wrap: nowrap;
}
