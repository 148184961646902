@import '../../../constants/styles/breakpoints.constant.module.scss';

.container {
  padding: 30px 40px;
}

div.header {
  display: block;
  margin-bottom: 24px;
}

.separator {
  margin-bottom: 30px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 16px;

  & > div {
    flex: 1;
    width: 100%;
  }
}

.hidden {
  display: none;
}

button.button {
  margin-top: 6px;
  height: 48px;
  min-width: 224px;
}

@include from767andLower {
  .container {
    padding: 32px 24px;
  }

  .row {
    flex-direction: column;
    gap: 6px;
  }

  button.button {
    width: 100%;
  }
}
