@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.itemRow {
  display: flex;

  > div {
    flex: 50%;
  }
  > div:first-child {
    justify-content: flex-start;
  }
  > div:last-child {
    justify-content: flex-end;
  }
}

.tooltipWrapper,
.tooltipTrigger,
.itemRowState {
  @extend .textEllipsis;
}

.tooltipTrigger {
  color: $color-accent-500;
}

.tooltipBody {
  width: 100%;
  word-wrap: break-word;
}

.link {
  cursor: pointer;
  font-weight: 700;
}

.linkHref {
  text-decoration: none;
}

.trashIcon {
  margin-left: 4px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.itemRowLinks {
  display: flex;
  align-items: center;
  column-gap: 4px;
  @extend .textEllipsis;
}

.itemRowStatus {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

@include from767andLower {
  .itemRow {
    display: flex;
    flex-direction: column;
    justify-content: none;
    row-gap: 4px;

    > div {
      flex: auto;
    }

    > div:last-child {
      justify-content: flex-start;
    }
  }

  .itemRowState {
    max-width: calc(100% - 20px);
  }

  .trashIcon {
    position: absolute;
    right: 0;
    top: 2px;
  }
}
