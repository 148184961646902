@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module';

.table {
  max-width: 100%;
  overflow: scroll;
  margin-top: 24px;
  border-radius: 15px;
  transition: all 0.4s ease;
}

.toolbar {
  justify-content: space-between;
}

.wrapper {
  margin-bottom: 128px;
  min-height: 500px;
}

.pagination {
  margin-top: 48px;
  justify-content: center;
}

@include from1023andLower {
  .pagination {
    margin-top: 32px;
  }
  .table {
    margin-top: 32px;
  }
}

@include from767andLower {
  .table {
    margin-top: 24px;
  }
}
