@import 'constants/styles/colors.constant.module.scss';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 48px;
  transform: translateY(100%);
  transition: transform 0.2s ease-in-out;
  background-color: $color-primary-text;
  z-index: 3;
}

.rootShown {
  bottom: 0;
  transform: translateY(0%);
}

.frameExists {
  bottom: 48px;
}

.buttons {
  display: flex;
  gap: 8px;
}

.discardButton {
  background: transparent !important;
  border-width: 2px !important;
}

.titleClassName {
  margin-right: 10px;
}
