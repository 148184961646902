@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module';
@import 'constants/styles/colors.constant.module';

.root {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

div.container {
  padding: 30px 40px;
}

div.containerTeam {
  position: relative;

  &NoData {
    min-height: 146px;
  }
}

div.header {
  display: block;
  margin-bottom: 24px;
}

div.loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  margin: 30px 0;
  border-radius: 16px;
  background-color: $color-neutral-100;
  height: calc(100% - 60px);
  z-index: 10;
}

div.loaderOwner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 86px;
  height: 100%;
}

.list {
  width: 100%;

  &NoData {
    min-height: 146px;
  }
}

button.button {
  width: 238px;
  height: 48px;
  padding: 11px 16px;
  margin-top: 30px;
}

.noTeam {
  margin: 30px 0;
  border-radius: 16px;
  background-color: $color-neutral-100;
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-text-600;
  font-family: $font-family-bold;
}

@include from767andLower {
  div.container {
    padding: 32px 24px;
  }

  div.loaderOwner {
    min-height: 200px;
  }

  div.containerTeam {
    &NoData {
      min-height: 146px;
    }
  }

  .list {
    &NoData {
      min-height: 146px;
    }
  }

  button.button {
    width: 100%;
  }

  div.loaderOwner {
    min-height: 146px;
  }
}
