@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 24px;
  background: $color-primary-background;

  .price {
    width: 254px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include from767andLower {
  .footer {
    align-items: flex-start;
  }
}

@include from524andLower {
  .footer {
    .price {
      width: 100%;
    }
  }
}
