@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/fonts.constant.module.scss';

.wrapper {
  position: relative;
  max-width: 100%;
}

.error {
  margin: 0;
  height: 0;
  display: none;
}

.hint {
  margin: 4px 16px 24px 16px;
}

/* SEARCH LIST START */
.searchList {
  width: 100%;
  position: absolute;
  top: 72px;
  padding: 8px 0;
  z-index: 1;
  overflow: initial;
  max-height: 208px;
  overflow: auto;
  border-radius: 15px;
  background: $color-white;
  box-shadow: 0 2px 24px 6px rgba(108, 109, 110, 0.07);
}

/* SEARCH LIST END */

/* EMPTY SEARCH LIST START */
.emptyList {
  @extend .searchList;
  text-align: center;
  height: initial;
}

/* EMPTY SEARCH LIST END */

/* PRODUCTS LIST ITEM START */
.name {
  color: $color-primary-text;
  flex: 1;
  min-height: 36px;
  position: relative;
  display: flex;
  align-items: center;
}

.nameInner {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
}

.nameWithTrashIcon {
  flex: 1;
}

.product {
  padding: 8px 16px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: $color-divider;
  }

  & > input {
    display: none;
    pointer-events: none;
  }

  &Disabled {
    opacity: 0.5;
  }
}

.greyedOut {
  background-color: $color-neutral-200;
}

.inputWrapper {
  label {
    color: $color-text-900;
  }
  input {
    color: $color-text-900;
    font-family: $font-family-regular;
    line-height: 162.5%;
  }
}

.result {
  border-bottom: 1px solid $color-neutral-250;

  &:last-child {
    border-bottom: none;
  }

  .name {
    max-width: 280px;
  }
}

.checkbox {
  margin-right: 8px;
}

.image {
  margin-right: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  & > div {
    width: 20px;
    height: 20px;

    div {
      width: 20px;
      height: 20px;
      margin: 0;
      border-width: 2px;
    }
  }
}

.remove {
  width: 20px;
  height: 20px;
  cursor: pointer;
  // position: absolute;
  right: 8px;
}

/* PRODUCTS LIST ITEM END */

/* SELECTION LIST START */
.selectionList {
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  .product {
    padding: 8px;

    &:hover {
      background: initial;
      cursor: default;
    }
  }
}

/* SELECTION LIST END */

/* LOADER  START */
.loaderOverlay {
  top: 24px;
  position: absolute;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* LOADER  END */

.searchLoader {
  width: 20px;
  height: 20px;

  div {
    width: 20px;
    height: 20px;
    margin: 0;
    border-width: 2px;
  }
}

@include from1919andLower {
  .wrapper {
    max-width: 333px;
  }
  .result {
    .name {
      max-width: 211px;
    }
  }
}

@include from767andLower {
  .wrapper {
    max-width: 100%;
  }
  .result {
    .name {
      max-width: calc(100% - 65px);
    }
  }
}
