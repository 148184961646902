@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/table.constant.module';

.container {
  width: 1344px;
  margin: 0 auto;
}

.toolbar {
  margin-bottom: 24px;
}

.emptyStateWrapper {
  margin-bottom: 128px;
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.containerWrapperEmpty {
  text-align: center;
}

.pdfBtn {
  height: 40px;
  white-space: nowrap;
  margin-right: 16px;

  & * {
    font-size: 0.875rem;
  }
}

.createCartLinkButton {
  height: 32px;
  width: 140px;
}

.goToBtn {
  cursor: pointer;
}
.number {
  @include indexColumn;
}
.sentAt {
  margin-left: 5px;
}

.manageWrapper {
  display: flex;
  width: 183px;

  .trashIcon {
    border-radius: 50%;
    min-height: 32px !important;
    min-width: 32px !important;
    background-color: $color-divider;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;

    &:last-of-type {
      margin-right: 24px;
    }
  }

  .downloadIcon {
    background-color: transparent;
    border: 1px solid $color-divider;
  }
}

.assignToWrapper {
  padding: 1px;
}

.smallBtn {
  height: 32px;
  cursor: text;
  pointer-events: none;
}

.centerAligned {
  display: flex;
}

.yellow {
  background-color: $color-pending-500;
}

.green {
  background-color: $color-success-700;
}

button.emptyStateButton {
  width: 250px;
  height: 48px;
}

.loaderWrapper {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.pdfBtnWrapper {
  width: auto;
  .pdfBtnDivider {
    display: none;
  }
}

.pagination {
  margin-top: 48px;
  margin-bottom: 128px;
  justify-content: center;
}

@media screen and (max-width: 1440px) {
  .container {
    width: 100%;
    margin: 0;
  }
}

@include from1023andLower {
  .emptyStateWrapper {
    margin-bottom: 96px;
  }

  .pagination {
    margin-top: 32px;
    margin-bottom: 96px;
  }
}

@include from767andLower {
  .pdfBtn {
    margin-right: 0px;
    width: 100%;
  }

  .pdfBtnWrapper {
    width: 100%;
    .pdfBtnDivider {
      display: block;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  button.emptyStateButton {
    max-width: 250px;
    width: 250px;
  }
}
