@import 'constants/styles/breakpoints.constant.module.scss';

.tagline {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 16px;
}

.title {
  margin-bottom: 24px;
}

div.tagline,
div.title,
div.description {
  display: block;
}

@include from1439andLower {
  .title {
    font-size: 2rem;
    line-height: 2rem;
  }
  .description {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
}

@include from1023andLower {
  .title {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  .description {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
}
