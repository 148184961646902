@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.defaultRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row {
  display: flex;
}

.container {
  display: flex;
  width: 100%;
  padding: 24px 24px 24px 10px;
  background: $color-white;
  box-shadow:
    0px 2px 1px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.25);

  &.containerWithRoundedTopBorder {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &.containerWithRoundedBottomBorder {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .left {
    width: 55%;
    padding-right: 100px;
    display: flex;
    justify-content: space-between;

    .imageBlock {
      min-height: 116px;
      min-width: 116px;
      max-height: 116px;
      max-width: 116px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      position: relative;
      margin-right: 8px;
      overflow: hidden;

      .image {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        max-height: 100%;
        max-width: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .blocksWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 16px;

      .titleBlock {
        width: 176px;

        .title {
          margin-bottom: 14px;
          line-height: 0;
        }

        .quantity {
          @extend .defaultRow;
          margin-bottom: 4px;
        }

        .size {
          @extend .defaultRow;

          .multipleSizes {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .boxSample {
          display: flex;
          flex-direction: column;

          .label {
            margin-bottom: 4px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .priceBlock {
        width: 176px;

        .price,
        .setupFee,
        .shipping,
        .kitting {
          @extend .defaultRow;
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .right {
    width: 45%;
    display: flex;
    justify-content: space-between;
    padding-top: 16px;

    .statusBlock {
      width: 282px;

      .status,
      .sentTo,
      .inHandDate {
        @extend .defaultRow;
        justify-content: flex-start;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }

        .label {
          min-width: 132px;
          width: 132px;
        }
      }

      .sentTo {
        .underlined {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .inHandDate {
        .value {
          min-width: 130px;
        }
      }
    }

    .paymentBlock {
      min-width: 176px;

      .payment,
      .totalCost {
        @extend .defaultRow;
        margin-bottom: 4px;
      }

      .totalCost {
        align-items: flex-start;
        margin-bottom: 0;

        .column {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        .reorderTipWrapper {
          display: flex;
          align-items: center;
          cursor: pointer;

          .reorderTip {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.green {
  color: $color-success-700;
}

.grey {
  color: $color-grey-6;
}

.red {
  color: $color-red;
}

.yellow {
  cursor: pointer;
  color: $color-pending-500 !important;
}

.primary {
  color: $color-primary-text;
}

.bold {
  font-family: $font-family-bold;
}

.downloadIcon,
.trackingNumberIcon {
  margin-top: 5px;
  margin-left: 6px;
  cursor: pointer;
}

.trackingNumberIcon {
  margin-top: 4px;
}

@include from1439andLower {
  .container {
    .left {
      width: 50%;
      padding-right: 30px;

      .imageBlock {
        min-height: 100px;
        min-width: 100px;
        max-height: 100px;
        max-width: 100px;
      }

      .blocksWrapper {
        .titleBlock {
          width: 118px;
        }

        .priceBlock {
          width: 154px;
        }
      }
    }

    .right {
      width: 50%;

      .statusBlock {
        width: 238px;

        .status,
        .sentTo,
        .inHandDate {
          .label {
            min-width: 108px;
            width: 108px;
          }
        }

        .inHandDate {
          .value {
            min-width: none;
          }
        }
      }
    }
  }
}

@include from1023andLower {
  .container {
    .left {
      width: 55%;
      padding-right: 60px;

      .imageBlock {
        min-height: 90px;
        min-width: 90px;
        max-height: 90px;
        max-width: 90px;
      }

      .blocksWrapper {
        padding-top: 0;
        flex-direction: column;
        justify-content: flex-start;

        .titleBlock,
        .priceBlock {
          width: 100%;
        }

        .titleBlock {
          .quantity,
          .size,
          .boxSample {
            margin-bottom: 4px;
          }
        }
      }
    }

    .right {
      width: 45%;
      padding-top: 0;
      flex-direction: column;
      justify-content: flex-end;

      .statusBlock,
      .paymentBlock {
        width: 100%;
      }

      .statusBlock {
        .status,
        .sentTo,
        .inHandDate {
          justify-content: space-between;

          &:last-child {
            margin-bottom: 4px;
          }

          .label {
            width: unset;
          }

          .value {
            text-align: end;
          }
        }

        .inHandDate {
          .value {
            text-align: right;
          }
        }
      }
    }
  }
}

@include from767andLower {
  .container {
    flex-direction: column;
    padding: 0;

    .left,
    .right {
      width: 100%;
      display: block;
    }

    .left {
      flex-direction: column;
      padding-right: 0;

      .imageBlock {
        margin: 24px 0 12px 10px;
      }

      .blocksWrapper {
        .titleBlock {
          padding: 0px 24px 16px;
          border-bottom: 1px solid $color-light-gray-warm;

          .title {
            margin-bottom: 16px;
          }

          .quantity,
          .size,
          .boxSample {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .priceBlock {
          padding: 16px 24px;
          border-bottom: 1px solid $color-light-gray-warm;
        }
      }
    }

    .right {
      .statusBlock {
        padding: 16px 24px;
        border-bottom: 1px solid $color-light-gray-warm;

        .inHandDate {
          .value {
            min-width: unset;
          }
        }
      }

      .paymentBlock {
        padding: 16px 24px 24px;
      }
    }
  }
}

.sizesTooltipContent {
  display: flex;
  align-items: flex-start;
  white-space: nowrap;

  .sizeColor {
    width: 10px;
    min-width: 10px;
    height: 10px;
    margin-top: 5px;
    margin-right: 12px;
    border-radius: 50%;
  }

  .sizeQuantity {
    max-width: 152px;
    display: flex;
    align-items: flex-start;
    white-space: wrap;
    flex-wrap: wrap;
  }
}

.reorderTipTooltipContent {
  max-width: 200px;
}

.addressTooltipContent {
  display: flex;
  flex-direction: column;
}

.toolTipWrapper {
  display: flex;
}
