@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 913px !important;
  z-index: 13;
}

.closeIcon {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px !important;
    height: 13px !important;
  }
}

.footer {
  display: flex;
  gap: 8px;

  > button {
    height: 48px !important;
    min-width: 224px;
  }

  .confirmButtonDanger {
    background-color: $color-error-text !important;

    &:hover {
      background-color: $color-error-text !important;
      filter: brightness(0.9);
    }
  }

  .confirmButtonDangerLoading {
    background-color: $color-destructive-100 !important;

    &:hover {
      background-color: $color-destructive-100 !important;
      filter: none;
    }
  }
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .footer {
    flex-direction: column;

    > button {
      min-width: unset;
    }
  }
}
