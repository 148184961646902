@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/animation.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.section {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textBlock {
  display: flex;
  flex-direction: column;
  max-width: 460px;
  width: 100%;
}

.mainHeading {
  margin-top: 26px;
}

.text {
  margin-top: 32px;
}

.outerBoxClassName {
  max-width: 1019px;
  width: 100%;
  height: 112px;
  margin-left: 48px;
}

.innerBoxClassName {
  display: flex;
  padding: 43px;
  width: 100%;
  height: 100%;
  position: relative;
}

.storeName {
  display: flex;
}

.inputSizer {
  display: inline-grid;

  position: relative;
  font: inherit;
  padding: 0;
  margin: 0;

  &::after,
  .storePrefixName {
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    font: inherit;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    text-align: right;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    height: 1.5rem;
    line-height: 1.5rem;
  }

  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
  }
}

div.errorText {
  position: absolute;
  bottom: -50px;
  left: 0;
  height: 40px;
}

@include from1919andLower {
  .textBlock {
    max-width: 397px;
  }

  .outerBoxClassName {
    max-width: 891px;
  }
}

@include from1439andLower {
  .section {
    flex-direction: column;
  }

  .textBlock {
    text-align: center;
    max-width: 100%;
  }

  .outerBoxClassName {
    max-width: 100%;
    margin: 0;
    margin-top: 48px;
  }
}

@include from767andLower {
  .storeName {
    display: block;
  }

  .outerBoxClassName {
    height: auto;
  }

  div.innerBoxClassName {
    display: flex;
    padding: 19px;
    width: 100%;
    height: auto;
    position: relative;
    & * {
      font-size: 1rem;
    }
  }
}
