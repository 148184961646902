@import 'constants/styles/breakpoints.constant.module.scss';

.container {
  display: flex;
  padding: 32px 0;
  max-width: 448px;
  min-width: 448px;
  max-height: 1004px;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 32px;
}

@include from1919andLower {
  .container {
    max-width: 437px;
    min-width: 437px;
  }
}

@include from1439andLower {
  .container {
    max-width: 480px;
    min-width: 480px;
  }
}

@include from1049andLower {
  .container {
    max-width: 720px;
    min-width: 720px;
  }
}

@include from767andLower {
  .container {
    max-width: 327px;
    min-width: 327px;
    max-height: 1404px;
  }
}
