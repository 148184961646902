@import '../../../../../constants/styles/breakpoints.constant.module';

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

@include from767andLower {
  .container {
    flex-direction: column;
  }
}
