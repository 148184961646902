@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module';

.dialogContent {
  z-index: 10;
  min-width: 912px;

  & > hr {
    margin-top: 8px;
  }
}

.dialogOverlay {
  z-index: 10;
}

.pointer {
  cursor: pointer;
}

.body {
  display: flex;
  overflow: auto;
  max-height: 500px;
  max-width: 885px;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2px;
}

.block {
  width: 277px;
  height: 137px;
  border-radius: 16px;
  background: $color-white;
  box-shadow:
    0 2px 1px 0 rgba(0, 0, 0, 0.05),
    0 0 1px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
  margin-right: 14px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.blockWithImage {
  & > .column {
    flex-direction: row;
    align-items: center;
  }
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  display: block;
}

.titleWithImage {
  overflow: initial;
  text-overflow: initial;
  white-space: initial;
}

.image {
  display: flex;
  min-width: 86px;
  max-width: 86px;
  height: 105px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-right: 16px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.tooltipTitle {
  text-transform: capitalize;
}

.footer {
  display: flex;
  gap: 8px;

  > button {
    height: 48px !important;
    min-width: 224px;
  }
}

@include from1023andLower {
  .dialogContent {
    z-index: 10;
    min-width: 622px;
    width: 622px;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);

    & > hr {
      margin-top: 8px;
    }
  }

  .body {
    max-width: 574px;
    margin: 0 auto;
  }

  .block {
    &:nth-child(3n) {
      margin-right: 16px;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .row {
    padding: 24px 2px 0;
  }
}

@include from767andLower {
  .dialogContent {
    left: 24px;
    width: auto;
    min-width: auto;
    right: 24px;
    transform: translateY(-50%);
  }

  .body {
    max-width: initial;
  }

  .row {
    flex-direction: column;
    padding: 24px 2px 0;
  }

  .block {
    width: 100%;
    margin-right: 0;
    padding: 15px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .footer {
    flex-direction: column;

    > button {
      min-width: unset;
      width: 100%;
    }
  }
}

@include from524andLower {
  .row {
    width: 90%;
    margin: 0 5%;
  }

  .body {
    overflow-x: hidden;
    max-height: 400px;
  }
}
