@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 913px !important;
  z-index: 13;
}

.dialogBody {
  overflow-y: scroll;
  max-height: 520px;
}

.closeIcon {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px !important;
    height: 13px !important;
  }
}

.imgWrapper {
  height: 390px;
  width: 100%;
  margin-bottom: 24px;

  .img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}

.footer {
  display: flex;
  gap: 8px;

  .button {
    height: 48px !important;
    min-width: 224px;
  }
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .dialogBody {
    max-height: 300px;
  }

  .imgWrapper {
    height: 200px;
  }

  .footer {
    flex-direction: column;

    .button {
      min-width: unset;
    }
  }
}
