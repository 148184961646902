@import 'constants/styles/colors.constant.module';

.root {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.section {
  width: 100%;
  padding: 30px 40px;
  overflow: initial !important;
}

.underline {
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background-color: $color-grey-3;
}

.content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 29.5px;
}
