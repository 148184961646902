@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';
@import 'constants/styles/fonts.constant.module.scss';

.contentWithoutSidebar {
  justify-content: center !important;
}

.dialogContentWrapperClassName {
  position: fixed;
  z-index: 13;
  background-color: $color-primary-background;
}

.dialogOverlayClassName {
  z-index: 11;
}

.previewIframe {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: scroll;
}

@include from1023andLower {
  .dialogContentWrapperClassName {
    position: absolute;
    bottom: -45%;
    left: 0;
    padding: 24px;
  }
}

@include from767andLower {
  .dialogContentWrapperClassName {
    padding: 0;
  }
}
