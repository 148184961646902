@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module';
@import 'constants/styles/table.constant.module';

.root {
  display: flex;
  padding: 32px 0 32px;
  height: initial;
  flex-direction: column;
}

.tableWrapper {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 32px;
  position: sticky;
  left: 0;
}

.headerDivider {
  position: sticky;
  left: 0;
}

.footer {
  margin-top: 20px;
  padding: 0 32px;
}

.title {
  margin-bottom: 0;
}

.tableHeader {
  background: $color-white;
  padding: 0 32px;
  height: initial;
}

.tableRow {
  @extend .tableHeader;
  border-bottom-width: 1px;
  position: relative;

  &:last-child {
    border-bottom: 1px solid $color-neutral-200;
  }
}

.headerCell,
.tableCell {
  @include flexCellsExcludingIndex;
  padding: 16px 0;
  margin-right: 24px;
}

.inline {
  display: inline;
}

@include from767andLower {
  .tableWrapper {
    width: 720px;
  }

  .root {
    max-width: 327px;
    min-width: 327px;
  }
}
