@import 'constants/styles/breakpoints.constant.module.scss';

.notification {
  max-width: 600px;
  min-height: 42px;
  height: unset;
  position: relative;

  & > div {
    white-space: initial;
    height: unset;
    min-height: 20px;
    padding: 11px 0;
  }
}

ol:has(> .notification) {
  z-index: 15;
}

.text {
  line-height: 162% !important;
}

.close {
  height: 18px;
  width: 18px;
  position: relative;
  align-self: start;
  margin-top: 14px;
}

@include from767andLower {
  .notification {
    max-width: 100%;
  }
}
