@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 912px !important;
  z-index: 13;
  padding: 24px;
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.contentWrapper {
  margin-top: 24px;
}

.inputError {
  margin-bottom: 0;
}

.footer {
  margin-top: 24px;
  display: flex;

  > button {
    width: 224px;
    height: 48px !important;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.copyIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .dialogContentWrapperClassName {
    height: auto !important;
  }

  .titleClassName {
    max-width: 250px;
  }

  .footer {
    flex-direction: column-reverse;

    & > button {
      min-width: 100%;
      width: 100%;
    }
  }
}
