@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.footerInner,
.footerInner a {
  line-height: 22px;
}

.footerOuter {
  padding: 128px 0;
}

.contentWrapper {
  margin-top: calc(#{$header-height} + #{$trial-label-height} + #{$nav-bar-height});
  height: calc(100vh - #{$header-height} - #{$trial-label-height} - #{$nav-bar-height});
  width: 100vw;
  overflow-y: scroll;

  &WithContractBar {
    margin-top: calc(
      #{$header-height} + #{$trial-label-height} + #{$nav-bar-height} + #{$contract-bar-height}
    );
    height: calc(
      100vh - #{$header-height} - #{$trial-label-height} - #{$nav-bar-height} - #{$contract-bar-height}
    );
  }
}

.pageWrapper {
  overflow-y: hidden;
}

.mainContentWrapper {
  height: auto;
  width: $header-width-1440-and-higher;
  margin: $main-content-wrapper-gap-vertical auto;

  &.mainContentWrapperWithoutSpaces {
    width: 100%;
    margin: 0;
  }
}

.contractBar {
  position: fixed;
  justify-content: center;
  margin-top: $header-height;
  .innerContractBar {
    max-width: $header-width-1440-and-higher;
  }
}

@include from1439andLower {
  .mainContentWrapper {
    width: $content-width-1439-and-lower;

    &.mainContentWrapperWithoutSpaces {
      width: 100%;
    }
  }

  .contentWrapper {
    margin-top: calc(
      #{$header-height} + #{$trial-label-height} + #{$nav-bar-height} + #{$mob-withdraw-section-height}
    );
    height: calc(
      100vh - #{$header-height} - #{$trial-label-height} - #{$nav-bar-height} - #{$mob-withdraw-section-height}
    );
    width: 100vw;
    overflow-y: scroll;

    &WithContractBar {
      margin-top: calc(
        #{$header-height} + #{$trial-label-height} + #{$nav-bar-height} + #{$mob-withdraw-section-height} +
          #{$contract-bar-height}
      );
      height: calc(
        100vh - #{$header-height} - #{$trial-label-height} - #{$nav-bar-height} - #{$mob-withdraw-section-height} -
          #{$contract-bar-height}
      );
    }
  }

  .footerOuter {
    padding: 128px 48px;
  }

  .contractBar {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@include from1023andLower {
  .contentWrapper {
    margin-top: calc(
      #{$header-height} + #{$trial-label-height} + #{$nav-bar-height-tablet}
    );
    height: calc(
      100vh - #{$header-height} - #{$trial-label-height} - #{$nav-bar-height-tablet}
    );

    &WithContractBar {
      margin-top: calc(
        #{$header-height} + #{$trial-label-height} + #{$nav-bar-height-tablet} + #{$contract-bar-height}
      );
      height: calc(
        100vh - #{$header-height} - #{$trial-label-height} - #{$nav-bar-height-tablet} - #{$contract-bar-height}
      );
    }
  }
  .mainContentWrapper {
    width: $content-width-1023-and-lower;

    &.mainContentWrapperWithoutSpaces {
      width: 100%;
    }
  }

  .footerOuter {
    padding: 96px 48px;
  }
}

@include from767andLower {
  .contentWrapper {
    margin-top: calc(
      #{$header-height} + #{$trial-label-height} + #{$nav-bar-height-tablet} + #{$withdraw-section-gap}
    );
    height: calc(
      100vh - #{$header-height} - #{$trial-label-height} - #{$nav-bar-height-tablet} - #{$withdraw-section-gap}
    );

    &WithContractBar {
      margin-top: calc(
        #{$header-height} + #{$trial-label-height} + #{$nav-bar-height-tablet} + #{$withdraw-section-gap} +
          #{$contract-bar-height-mobile}
      );
      height: calc(
        100vh - #{$header-height} - #{$trial-label-height} - #{$nav-bar-height-tablet} - #{$withdraw-section-gap} -
          #{$contract-bar-height-mobile}
      );
    }
  }

  .contractBar {
    position: fixed;
    margin-top: $header-height;
    height: $contract-bar-height-mobile;

    .contractBarRightText {
      display: none;
    }

    .buttonContractBar {
      width: 6.0625rem !important;
    }
  }

  .mainContentWrapper {
    width: $content-width-767-and-lower;

    &.mainContentWrapperWithoutSpaces {
      width: 100%;
    }
  }

  .footerOuter {
    padding: 80px 24px;
  }
}
