@import 'constants/styles/breakpoints.constant.module.scss';

.heading {
  margin: 50px 0;
  display: flex;
  align-items: flex-end;
}

.text {
  white-space: nowrap;
  margin-bottom: 7px;
}

@include from1049andLower {
  .heading {
    flex-direction: column;
    align-items: flex-start;

    & * {
      margin-left: 0;
    }
  }
}
