$main-content-wrapper-gap-vertical: 50px;

$header-width-1440-and-higher: 1344px;
$content-width-1439-and-lower: 976px;
$content-width-1049-and-lower: 720px;
$content-width-1023-and-lower: 720px;
$content-width-767-and-lower: 327px;

$box-shadow:
  0px 0px 1px 0px rgba(0, 0, 0, 0.25),
  0px 2px 1px 0px rgba(0, 0, 0, 0.05);
$box-shadow-deep:
  0px 2px 16px 0px rgba(0, 0, 0, 0.08),
  0px 0px 1px 0px rgba(0, 0, 0, 0.1);

$linear-gradient: linear-gradient(270deg, #8f39ff -1.58%, #e73af6 100%);

$maxWidthContent1920: 1594px;
$maxWidthContent1440: 1344px;
$maxWidthContent1024: 976px;
$maxWidthContent768: 720px;
$maxWidthContent525: 477px;
$maxWidthContent375: 327px;

$footer-height: 98px;
