@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/table.constant.module.scss';
@import '../../../constants/styles/colors.constant.module';

.root {
  max-width: 100%;
  overflow: scroll;
  background: none !important;
  box-shadow: none !important;
  border-radius: unset;
}

.header,
.row {
  background: none !important;
  height: auto !important;
  padding: 0px !important;
}

.row:not(:last-child) {
  border-bottom: 1px solid $color-divider !important;
}

.cell {
  display: flex;
  flex-direction: row;
}

.copyCell {
  position: relative;
}

.copy {
  cursor: pointer;
}

.cell:not(:last-child),
.cellHeader:not(:last-child) {
  flex: 1;
  margin-right: 12px;
}

.cell:last-child,
.cell:first-child,
.cellHeader:last-child,
.cellHeader:first-child {
  width: 30px;
  max-width: 30px;
}

.timesUsed {
  width: 60px;
  max-width: 60px;
}

.timesUsed {
  width: 60px;
  max-width: 60px;
}

.code,
.Value,
.freeShipping,
.freeSetupFee {
  width: 125px;
  max-width: 125px;
}

.cell,
.cellHeader {
  padding: 16px 0 !important;
}

.cell span {
  color: $color-secondary-text !important;
}

.cellHeader {
  color: $color-primary-text !important;
}

.number {
  @include indexColumn;
}

.pagination {
  margin-top: 48px;
  justify-content: center;
}

.noPromo {
  margin-top: 16px;
  display: block !important;
}

.trashIcon {
  width: 26px;
  height: 26px;
  background: $color-divider;
  border-radius: 26px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
