@import '../../constants/styles/breakpoints.constant.module';
@import '../../constants/styles/colors.constant.module';
@import '../../constants/styles/table.constant.module';

.table {
  max-width: 100%;
  overflow: scroll;
  margin-top: 24px;
  border-radius: 15px;
  transition: all 0.4s ease;
}

@include from1023andLower {
  .table {
    margin-top: 32px;
  }
}

@include from767andLower {
  .table {
    margin-top: 24px;
  }
}
