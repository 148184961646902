@import '../common.module';
@import 'constants/styles/colors.constant.module';
@import 'constants/styles/fonts.constant.module.scss';

.root {
  @extend .table;
}

.number {
  @include indexColumn;
}

.headerCell,
.cell {
  @include flexCellsExcludingIndex;

  &.wide {
    flex: 2;
    max-width: 212px;
  }
}

.naStyle span {
  color: $color-text-500 !important;
}

.cell {
  padding: 19px 0;
}

.fixedWidthCell {
  width: 125px;
  max-width: 125px;
}

.assignToCell {
  margin-left: 20px;
}

.statusCell {
  :global(.select__indicator) {
    padding-left: 4px;
  }

  :global(.select__single-value) {
    font-family: $font-family-bold;
    padding-right: 0px !important;
  }

  &.green {
    :global(.select__single-value) {
      color: $color-green-primary;
    }
  }
}
