@import 'constants/styles/colors.constant.module.scss';

.root {
  padding: 28px 24px;
  background: $color-grey-4;
  border-radius: 15px;
}

.textBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 14px;

  .icon {
    min-width: 30px;
  }
}
