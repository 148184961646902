@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 913px !important;
  z-index: 13;

  hr[class*='footerDivider'] {
    margin-top: 8px;
  }
}

.dialogBody {
  overflow-y: scroll;
  max-height: 520px;
}

.closeIcon {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px !important;
    height: 13px !important;
  }
}

.inputForm {
  padding: 0 4px;
}

.companyNameInput {
  margin-top: 16px;
}

.footer {
  align-items: center;
  display: flex;

  .button {
    height: 48px !important;
    min-width: 224px;
  }
}

.labelClassName {
  color: $color-primary-text;
}

.errorClassName {
  margin-bottom: 16px;
}

.goBack {
  margin-right: auto;
  cursor: pointer;
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .dialogBody {
    max-height: 300px;
  }

  .errorClassName {
    height: unset;
    min-height: 20px;
  }

  .footer {
    flex-direction: column;
    align-items: unset;

    .button {
      min-width: unset;
    }
  }

  .goBack {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
