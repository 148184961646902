@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 913px !important;
  z-index: 13;
}

.dialogBody {
  overflow-y: scroll;
  max-height: 520px;
}

.closeIcon {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px !important;
    height: 13px !important;
  }
}

.footer {
  align-items: center;
  display: flex;

  .button {
    height: 48px !important;
    min-width: 224px;
  }
}

.labelClassName {
  color: $color-primary-text;
}

.errorClassName {
  margin-bottom: 0;
  height: 20px;
}

.goBack {
  margin-right: auto;
  cursor: pointer;
}

.imageUpload,
.imageUploadTools {
  margin-top: 16px;
  width: 100%;
}

.imageUpload {
  border: 1px dashed $color-light-gray;
  box-shadow: none;
  height: unset;
}

.imageUploadTools {
  height: 160px;
  padding: 16px 0;
  box-shadow: none;
}

.previewImage {
  background-color: transparent;
}

.uploadBtn {
  width: 200px;
}

.uploadControl {
  > div {
    justify-content: center;
  }
}

.colors {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: unset;
  padding: 0;
  margin-top: 32px;
  box-shadow: none !important;

  > div:nth-child(2) {
    margin-left: 16px;
  }
}

.primaryColorPickerWrapperClassName,
.secondaryColorPickerWrapperClassName {
  top: -300px;
  left: 80px;
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .dialogBody {
    max-height: 300px;
  }

  .imageUpload {
    overflow-x: hidden;
  }

  .imageUploadTools {
    height: 260px;
  }

  .colors {
    margin-top: 16px;
    flex-direction: column;

    > div:nth-child(2) {
      margin-top: 16px;
      margin-left: unset;
    }
  }

  .primaryColorPickerWrapperClassName,
  .secondaryColorPickerWrapperClassName {
    left: -14px;
  }

  .footer {
    flex-direction: column;
    align-items: unset;

    .button {
      min-width: unset;
    }
  }

  .goBack {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .uploadControl {
    > div {
      align-items: center;
    }
  }
}
