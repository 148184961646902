@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module';
@import 'constants/styles/fonts.constant.module.scss';

.root {
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 32px 32px 24px 32px;
}

.link {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.listItem {
  display: grid;
  column-gap: 24px;
  grid-template-columns: 90px 2fr 3fr;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.groupItems {
  padding-top: 16px;
  padding-bottom: 16px;
}

.listItem > div {
  text-align: left;
}

.listItemName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.status {
  cursor: pointer;
  width: max-content;
}

@include from767andLower {
  .header {
    padding: 32px 24px;
  }

  .listItem {
    grid-template-columns: 1fr 2fr 2fr;
    padding-left: 24px;
    padding-right: 24px;
  }
}
