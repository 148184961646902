@import '../common.module';

.root {
  @extend .table;
}

div.tableWrapper {
  box-shadow: none;
  border-radius: initial;
}

.number {
  @include indexColumn;
}

.headerCell,
.cell {
  @include flexCellsExcludingIndex;
}

.assignToCell {
  width: 125px;
  max-width: 125px;
}
