@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-divider;
}

@include from767andLower {
  .header {
    margin: 16px 24px;
    align-items: center;
    border-bottom: none;
    margin-bottom: 0;
  }
}
