.root {
  width: 100%;
  height: 100%;
  position: relative;
}

.iframe {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  transform-origin: top left;
}

.hidden {
  visibility: hidden;
}

.upper {
  z-index: 1;
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms;
}

.enterDone {
  visibility: visible;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 300ms;
}

.exitDone {
  visibility: hidden;
}
