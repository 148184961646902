@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.footer {
  padding: 24px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color-primary-background;

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    border: 1px solid $color-light-gray;
    border-radius: 30px;
    cursor: pointer;

    .button {
      text-decoration: none;
    }
  }

  .swagButton {
    @extend .buttonWrapper;
    border: none;
  }

  .paymentAndPriceWrapper {
    display: flex;
    align-items: center;
    gap: 24px;

    .paymentFailedWrapper {
      display: flex;
      padding: 8px 16px;
      gap: 6px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 8px;
      border: 1px solid $color-destructive-300;
      background: $color-destructive-100;

      a {
        color: $color-error-text;
        font-family: $font-family-bold;
        font-size: 0.875rem;
        line-height: 1.42rem;
        cursor: pointer;
      }
    }

    .priceWrapper {
      display: flex;
      flex-direction: column;

      .price {
        width: 280px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;

        &PaymentFailed {
          width: unset;
          gap: 24px;

          .priceTag {
            color: $color-error-text !important;
          }
        }

        .totalLabel {
          display: flex;
          align-items: center;

          .infoIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -2px;
            margin-left: 4px;
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.priceAdjustmentTooltipContent {
  max-width: 200px;
}

@include from1023andLower {
  .footer {
    align-items: flex-start;
    flex-direction: column;

    .buttonWrapper {
      margin-bottom: 24px;
    }

    .paymentAndPriceWrapper {
      justify-content: space-between;
    }

    .paymentFailedWrapper {
      a {
        font-size: 0.875rem;
        line-height: 142.857% !important;
      }
    }

    .paymentRetryText {
      font-size: 0.875rem !important;
      line-height: 142.857% !important;
    }
  }
}

@include from767andLower {
  .footer {
    align-items: flex-start;
    flex-direction: column;

    .paymentAndPriceWrapper {
      align-items: flex-start;
      flex-direction: column;
    }

    .priceWrapper {
      width: 100%;
    }

    .buttonWrapper {
      margin-bottom: 24px;
    }
  }
}

@include from524andLower {
  .footer {
    align-items: flex-start;
    flex-direction: column;

    .priceWrapper {
      width: 100%;

      .price {
        width: 100%;
      }
    }
  }
}
