@import './reset.module.scss';
@import './fonts.module.scss';
@import './constants/styles/fonts.constant.module.scss';
@import './constants/styles/breakpoints.constant.module.scss';

body,
html {
  font-size: 16px;
  line-height: 26px;
}

* {
  font-family: $font-family-regular;
}
