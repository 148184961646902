@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: initial;
}

.twoInRowContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px;

  .input {
    flex: 1;
  }
}

div.inputWrapper {
  border-radius: 100px;
}

.fieldSpacer {
  width: 100%;
  flex: 1;
}

:global(.select__single-value) {
  border-radius: 100px !important;
}

:global(.select__control) {
  border-radius: 100px !important;
}

:global(.select__value-container) {
  border-radius: 100px !important;
}

.invoiceToggleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid $color-grey-3;
}

.toggleLabel {
  opacity: 0;
}

.invoiceToggle {
  margin: 0 !important;
}

@include from767andLower {
  .input {
    flex: none;
    min-width: 100%;
  }

  .scrollable {
    overflow-y: auto;
    max-height: 300px;
  }

  .fieldSpacer {
    display: none;
  }
}

.toggleLabel {
  opacity: 0;
}

.toggleDisabled {
  pointer-events: none;
  span[class*='_checked_'] {
    background-color: $color-light-blue;
  }
}
