@import 'constants/styles/breakpoints.constant.module.scss';
@import '../common.module';

.reverse {
}

.address {
  width: 93px;
}

.emailWrapper {
  padding-right: 23px;
  position: relative;
  width: fit-content;
}

.copy {
  position: absolute;
  right: 0;
  top: 3px;
  cursor: pointer;
}

@include from767andLower {
  .reverse {
    flex-direction: column-reverse;

    .smallColumn {
      margin-bottom: 0;
      margin-right: 0;
      width: 100%;
      max-width: initial;
    }

    .bigColumn {
      margin-bottom: 24px;
      width: 100%;
      margin-right: 0;
    }

    .address {
      width: 100%;
    }
  }
}
