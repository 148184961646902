@import 'constants/styles/colors.constant.module.scss';
@import '../common.module';

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.exit {
  opacity: 1;
}

.toggleLabel {
  margin-right: auto;
  width: 100%;
  color: $color-primary-text;
}

.exitActive {
  opacity: 0;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.bodyClassName {
  min-width: 400px !important;
}

.title {
  @extend .title;
  margin-bottom: 4px;
  display: block;
}

.line {
  margin-bottom: 8px;
  white-space: nowrap;

  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.toggle {
  margin: 0;
  cursor: pointer;
  width: 100%;
}

.reset {
  text-align-last: left;
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.adornmentInfo {
  display: inline-flex;
  transform: translateY(2px);
}

.adornmentInfoIcon {
  display: inline-block;
  * {
    display: inline-block;
  }
}

.toggleDisabled {
  pointer-events: none;
  cursor: not-allowed;

  span[class*='_checked_'] {
    background-color: $color-light-blue !important;
  }
}
