@import 'constants/styles/breakpoints.constant.module.scss';

.smallText {
  margin-left: 10px;
  white-space: nowrap;
}

.wrapper {
  margin-bottom: 128px;
  min-height: 500px;
}

.heading {
  gap: 12px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.additionalComponent {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
}

.container {
  display: flex;
  justify-content: space-between;
}

.block {
  display: flex;
  flex-direction: column;

  & > div:first-child {
    margin-bottom: 16px;
  }
}

.right {
  @extend .block;
}

.left {
  @extend .block;
}

@include from1439andLower {
  .container {
    flex-direction: column;
  }
  .left {
    margin-bottom: 16px;
  }
  .right {
    flex-direction: row;
    justify-content: space-between;
  }
}

@include from1049andLower {
  .right {
    flex-direction: column;
  }
}

@include from1920andBigger {
  .wrapper {
    width: 1376px;
  }
}
