@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.header {
  background-color: $color-main;
  height: $header-height;
  width: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $header-vertical-padding 0;
  position: fixed;
  z-index: 10;
}

.headerWrapper {
  max-width: $header-width-1440-and-higher;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.noLink {
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 28px;
  justify-content: center;
}

.logo {
  width: 72.29px;
  height: 36px;
}

.burgerMenu {
  display: none;
}

.burgerIcon {
  cursor: pointer;
}

.cellphone {
  width: 17.42px;
  height: 17.42px;
  margin-right: 10px;
}

.divider {
  width: 1px;
  height: 24px;
  background-color: $color-white;
  opacity: 0.25;
  margin: 0 30px;
  margin-left: auto;
}

@include from1439andLower {
  .divider,
  .headerMenu {
    display: none;
  }

  .noLink {
    margin-left: 28px;
  }

  .burgerMenu {
    display: flex;
    margin-left: auto;
  }

  .headerWrapper {
    margin: 0 24px;
  }
}

@include from767andLower {
  .phoneNumber {
    display: none;
  }
  .cellphone {
    margin-right: 0;
  }
}
