@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.pageInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root {
  max-width: 976px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.header {
  display: block !important;
  margin-bottom: 24px;
}

.section,
.sectionTable {
  width: 100%;
  padding: 30px 40px;
  overflow: initial !important;
}

.content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  position: relative;
}

.pagination {
  display: flex;
  justify-content: center;
}

.skeletonItem {
  height: 26px !important;
  margin: 16px 0 32px !important;
}

.select {
  width: 106px;
  height: 36px;
  margin-bottom: 15px;
}

.headerList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error {
  display: none;
}

@include from767andLower {
  .wrapperTable {
    min-width: 720px;
  }
  .sectionTable {
    overflow: scroll !important;
  }

  .headerList {
    width: 327px;
    display: block;
  }

  .select {
    width: 247px;
    & button {
      width: 247px;
    }
  }
}
