@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 912px !important;
  z-index: 13;
  padding: 24px;

  & > hr {
    margin-top: 8px;
  }
}

.description {
  margin-bottom: 24px;
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.labelClassName {
  color: $color-primary-text;
}

.contentWrapper {
  margin-top: 24px;
  margin-bottom: -6px;
}

.errorClassName {
  margin-bottom: 0;
  height: 20px;
}

.inputClassName {
  text-transform: lowercase;
}

.text {
  margin-bottom: 16px;
  display: block;
}

.footer {
  margin-top: 24px;
  display: flex;
  .btnHover:hover {
    background-color: $color-white;
  }
  > button {
    width: 224px;
    height: 48px !important;
    &:disabled {
      cursor: not-allowed;
    }
  }
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .dialogContentWrapperClassName {
    height: auto !important;
  }

  .titleClassName {
    max-width: 250px;
  }

  .footer {
    flex-direction: column-reverse;

    & > button {
      min-width: 100%;
      width: 100%;
    }
  }
}
