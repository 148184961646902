@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.enter {
  opacity: 0.1;
  margin: 0;
  max-height: 0px;
}

.enterActive {
  opacity: 1;
  max-height: 130px;
  margin-bottom: 25px;
  transition:
    max-height 400ms,
    opacity 300ms,
    margin-bottom 400ms;
}

.enterDone {
  margin-bottom: 25px;
}

.exit {
  opacity: 0.7;
  margin-bottom: 25px;
  max-height: 130px;
}

.exitActive {
  opacity: 0;
  max-height: 0px;
  margin: 0;
  transition:
    max-height 300ms,
    opacity 300ms,
    margin-bottom 300ms;
}

.enterSwitch {
  opacity: 0.1;
  max-height: 26px;
  margin-bottom: 25px;
}

.enterActiveSwitch {
  opacity: 1;
  max-height: 78px;
  margin-bottom: 25px;
  transition: all 200ms;
}

.enterDoneSwitch {
  margin-bottom: 25px;
}

.exitSwitch {
  opacity: 1;
  margin-bottom: 25px;
  max-height: 78px;
}

.exitActiveSwitch {
  opacity: 0.1;
  max-height: 26px;
  margin-bottom: 25px;
  transition: all 200ms;
}

.requirementsList {
  margin-top: 0;
  padding: 0 10px;
  display: flex;
  list-style: none;
  font-family: $font-family-regular;

  .requirementsBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;

    .requirement {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      color: $color-primary-purple;
      transition: color 250ms ease-in-out;

      &::before {
        content: '';
        margin-right: 10px;
        min-height: 4px;
        min-width: 4px;
        border-radius: 50%;
        background: $color-primary-purple;
        display: inline-block;
        vertical-align: middle;
      }

      &.doneRequirement {
        color: $mono-gray-2;

        &::before {
          background: $mono-gray-2;
        }
      }

      &.maxCharactersRequirement {
        opacity: 0;
        transition: opacity 150ms ease-in-out;
      }

      &.maxCharactersRequirementAppear {
        opacity: 1;
      }
    }
  }
}

.passwordSecure {
  text-align: left;
  color: $color-green;
  font-size: 14px;
  line-height: 26px;
}

@include from767andLower {
  .requirementsList {
    .requirementsBlock {
      grid-template-columns: 1fr;
    }
  }
}
