@import 'constants/styles/colors.constant.module.scss';

.label {
  margin-bottom: 4px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 20px;
}

.storeName {
  position: relative;
  display: flex;
  padding: 11px 16px;
  border-radius: 32px;
  background-color: $color-grey-4;
  height: 48px;
  align-items: center;
}

.inputSizer {
  display: inline-grid;

  position: relative;
  font: inherit;
  padding: 0;
  margin: 0;

  &::after,
  .storePrefixName {
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    font: inherit;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    text-align: left;
    border: none;
    padding: 0;
    outline: none;
    height: 1.5rem;
    line-height: 1.5rem;
  }

  &::after {
    content: attr(data-value);
    visibility: hidden;
    white-space: pre-wrap;
  }
}


.inputEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inputEllipsis:focus {
  text-overflow: clip;
}

.errorMessage {
  color: $color-error-text;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
  padding: 0 16px;
}
