@import 'constants/styles/colors.constant.module.scss';

.progressWrapper {
  padding: 28px 24px 48px;
  background: $color-grey-4;
  border-radius: 15px;
}

.revenue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
}

.revenueInfo {
  color: $color-primary-text;
}
