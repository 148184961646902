@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';

.contentWrapper {
  z-index: 13;
}

.overlay {
  z-index: 11;
}

.footer {
  width: 50%;
  margin-left: 50%;
}

.content {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  padding: 2px;
  box-sizing: border-box;
}

.content::-webkit-scrollbar {
  -webkit-appearance: none;
}

.content::-webkit-scrollbar:vertical {
  width: 11px;
}

.content::-webkit-scrollbar:horizontal {
  height: 11px;
}

.content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid $color-white; /* should match background, can't be transparent */
  background-color: $color-grey-1;
}

.input {
  flex: 1;
}

.twoInRowContainer {
  display: flex;
  align-items: flex-end;
  gap: 6px;
}

@include from767andLower {
  .input {
    flex: none;
    min-width: 100%;
  }
  .twoInRowContainer {
    flex-wrap: wrap;
    gap: 0;
  }
  .footer {
    margin: 0;
    width: 100%;
  }
}
