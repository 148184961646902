@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/animation.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.smallText {
  margin-left: 10px;
  white-space: nowrap;
}

.grid {
  display: grid;
  column-gap: 16px;
  row-gap: 16px;

  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: 160px 354px;

  grid-template-areas:
    'total leads tier'
    'orders orders tier';
}

.boxTotal {
  grid-area: total;
}

.boxLeads {
  grid-area: leads;
}

.boxOrders {
  grid-area: orders;
}

.boxTier {
  grid-area: tier;
}

.inputSizer {
  display: grid;
  width: 100%;

  position: relative;
  font: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;

  .storePrefixName {
    min-width: 1em;
    grid-area: 1 / 1;
    font: inherit;
    margin: 0;
    resize: none;
    text-align: left;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    height: 1.5rem;
    line-height: 1.5rem;
    cursor: pointer;
    color: $color-typography-body-greyed;
    cursor: text;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.copy {
  cursor: pointer;
}

.storeName {
  position: relative;
  display: flex;
  padding: 11px 16px;
  border-radius: 32px;
  background-color: $color-grey-4;
  align-items: center;
}

.storeName .copy {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  right: 16px;
  top: 50%;
  height: 20px;
  min-width: 20px;
  width: 'auto';
  cursor: pointer;
  min-width: 20px;
  width: 20px;
  max-width: 20px;
}

.storeNameLabel {
  margin-bottom: 4px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.row {
  display: grid;
  grid-template-columns: 146px 1fr 154px;
  column-gap: 16px;
  align-items: start;
  grid-template-rows: 1fr;
}

.header {
  padding: 32px 32px 24px 32px;
}

.dataLines {
  overflow: hidden;
}

.dataLineWrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  &:not(:first-child) {
    display: flex;
    margin-top: 5px;
  }
}

.dataLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@include from1439andLower {
  .grid {
    grid-template-columns: 1fr 1fr;

    grid-template-areas:
      'total leads'
      'orders orders'
      'tier tier';
  }
}

@include from767andLower {
  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    grid-template-areas:
      'total'
      'leads'
      'orders'
      'tier';
  }
  .boxTier {
    height: auto;
  }

  .inputSizer {
    .storePrefixName {
      width: unset;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    column-gap: unset;
    row-gap: 8px;
    align-items: start;
  }

  .firstRow {
    display: none;
  }
}
