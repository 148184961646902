@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.dialogOverlay {
  z-index: 11;
}

.dialogContent {
  width: 913px !important;
  z-index: 13;
  padding: 24px;
  hr {
    margin-top: 24px;
  }
}

.noScroll {
  overflow: unset !important;
}

.dialogBody {
  margin-top: 16px;
  overflow: auto;
}

.button {
  min-width: 224px;
  height: 48px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
}
.subTitle {
  margin: 24px 0px;
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

@include from1049andLower {
  .dialogContent {
    width: unset !important;
  }
}

@include from524andLower {
  .footer {
    flex-direction: column;

    .button {
      width: 100%;
    }
  }

  .dialogBody {
    margin-top: 8px;
  }

  .dialogDescription {
    margin-top: 8px;
  }

  .dialogContent {
    height: calc(100% - 48px);
    display: grid;
    grid-template-rows: 51px auto 24px 138px;
  }
}

@media screen and (max-height: 864px) and (min-width: $bp524) {
  // max-height == 816(popup height) + 24 + 24)
  .dialogContent {
    height: calc(100% - 48px);
    display: grid;
    grid-template-rows: 51px auto 24px 72px;
  }
}
