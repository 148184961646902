@import '../../constants/styles/breakpoints.constant.module.scss';

div.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root {
  max-width: 976px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

@include from767andLower {
  .root {
    gap: 16px;
  }
}
