@import 'constants/styles/breakpoints.constant.module.scss';

.container {
  width: 1344px;
  margin: 0 auto;
}

.toolbar {
  margin-bottom: 24px;
}

@media screen and (max-width: 1440px) {
  .container {
    width: 100%;
    margin: 0;
  }
}
