@import 'constants/styles/breakpoints.constant.module.scss';

.container {
  display: flex;
  align-items: center;
  padding: 32px 0;
  max-width: 912px;
  min-width: 912px;
  max-height: 179px;
  min-height: 179px;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mobileWrapper {
  display: none;
  flex-wrap: wrap;
}

.mobileRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:first-child {
    margin-bottom: 32px;
  }
  &:last-child {
    margin-top: 32px;
  }
}

.mobileColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 24px;

  &:first-child {
    width: 118px;
  }
  &:last-child {
    width: 112px;
  }
}

.row {
  padding: 0 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
    margin-top: 32px;
  }
}

.column {
  display: flex;
  width: 25%;
  justify-content: flex-start;
}

.title {
  margin: 0;
}

@include from1919andLower {
  .container {
    max-width: 891px;
    min-width: 891px;
    max-height: 179px;
  }
}

@include from1439andLower {
  .container {
    max-width: 976px;
    min-width: 976px;
    max-height: 179px;
  }
}

@include from1049andLower {
  .container {
    max-width: 720px;
    min-width: 720px;
    max-height: 171px;
  }
}

@include from767andLower {
  .container {
    max-width: 327px;
    min-width: 327px;
    max-height: 269px;
  }
  .wrapper {
    display: none;
  }
  .mobileWrapper {
    display: flex;
  }
  .title {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
