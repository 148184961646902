@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.dialogOverlay {
  z-index: 11;
}

.dialogContent {
  width: 912px !important;
  min-width: unset !important;
  z-index: 13;
  padding: 24px;

  hr {
    border-color: $color-divider;
  }

  & > hr {
    margin-top: 24px;
  }
}

.dialogBody {
  margin-top: 24px;
}

.button {
  height: 48px;
  width: 224px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
}

.overlay {
  z-index: 15;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.prices {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  align-items: flex-end;
}

@include from1439andLower {
  .dialogContent {
    width: 976px !important;
  }
}

@include from1023andLower {
  .dialogContent {
    width: 720px !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@include from767andLower {
  .dialogBody {
    margin-top: 0;
  }
  .dialogContent {
    width: 90% !important;
  }
  .footer {
    justify-content: space-between;
  }
  .button {
    width: calc(50% - 4px);
  }
}
