@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/animation.constant.module.scss';

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.section {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textBlock {
  display: flex;
  flex-direction: column;
  max-width: 460px;
  width: 100%;
  margin-top: 30px;
  align-self: flex-start;
}

.mainHeading {
  margin-top: 26px;
}

.errorText {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  margin-top: 5px;
}

.text {
  margin-top: 32px;
}

.outerBoxClassName {
  max-width: 1035px;
  width: 100%;
  height: fit-content;
  margin-left: 48px;
}

.btn {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  width: 224px;
  margin: auto;
}

.innerBoxClassName {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 152px 50px auto;
  column-gap: 16px;
  row-gap: 16px;
  padding: 43px;
  width: 100%;
}

.dealComparisonWidget {
  display: flex;
  flex-direction: column;
  padding: 11px;
}

.header {
  grid-area: header;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
}

.closeIconWrapper {
  cursor: pointer;
  height: 40px;
  display: flex;
}

.closeIcon {
  border-radius: 50%;
  border: solid 1px $color-border-default;
  padding: 10px;
}

.leftSideTitle {
  align-self: start;
}

.icons {
  font-size: 32px;
  margin-bottom: 10px;
}

.innerColumnThird {
  padding: 12px;
  background-color: $color-tabs-selection;
}

.lowMarginText {
  grid-row: 3 / 4;
  grid-column: 1 / 3;
}

.widget {
  border-radius: 15px;
  padding: 24px;
}

.widget:not(.widgetMargin) {
  background-color: $color-grey-4;
}

.widgetMargin {
  border: solid 1px $color-neutral-300;
}

div.headingWidget {
  display: block !important;
  text-align: center !important;
}

.percent {
  outline: none;
  display: flex;
  width: 100%;
  border: none;
  background-color: transparent;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  align-items: center;
  -moz-appearance: textfield;
  margin: 0;
  position: relative;
  padding: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.percentSign {
  position: absolute !important;
  left: 18px;
  top: 12px;
}

.list {
  display: grid;
  grid-template-rows: 26px 26px;
  grid-template-columns: 3fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 16px;
}

.value {
  display: flex;
  justify-content: flex-end;
}

.fakeInput {
  background-color: $color-grey-3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-radius: 32px;
  margin-top: 24px;
  position: relative;
  padding: 11px 16px 11px 44px;
}

@include from1919andLower {
  .textBlock {
    max-width: 397px;
  }

  .outerBoxClassName {
    max-width: 891px;
  }
}

@include from1439andLower {
  .section {
    flex-direction: column;
  }

  .textBlock {
    max-width: 728px;
    text-align: center;
    align-self: unset;
    margin-top: 0;
  }

  .outerBoxClassName {
    max-width: 976px;
    margin-top: 48px;
    margin-left: 0;
  }
}

@include from1023andLower {
  .textBlock {
    max-width: 536px;
    text-align: center;
  }

  .outerBoxClassName {
    max-width: 720px;
  }
}

@include from767andLower {
  .textBlock {
    max-width: 100%;
    text-align: center;
  }

  .outerBoxClassName {
    margin: 0;
    margin-top: 40px;
  }

  .innerBoxClassName {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
    column-gap: 0;
    row-gap: 16px;
    width: auto;
    padding: 19px;
  }

  .btn {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }

  .lowMarginText {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
    margin-top: 0;
  }

  .widget {
    padding: 24px;
  }
}
