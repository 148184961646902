$bp1919: 1919px;
$bp1439: 1439px;
$bp1049: 1049px;
$bp1023: 1023px;
$bp767: 767px;
$bp524: 524px;
$bp374: 374px;

// CUSTOM SIZE

$bp1819: 1819px;
$bp1719: 1719px;
$bp1619: 1619px;
$bp1519: 1519px;
$bp1419: 1419px;
$bp1319: 1319px;
$bp1219: 1219px;

@mixin from1920andBigger {
  @media screen and (min-width: $bp1919 + 1) {
    @content;
  }
}

@mixin from1919andLower {
  @media screen and (max-width: $bp1919) {
    @content;
  }
}

@mixin from1439andLower {
  @media screen and (max-width: $bp1439) {
    @content;
  }
}

@mixin from1049andLower {
  @media screen and (max-width: $bp1049) {
    @content;
  }
}

@mixin from1023andLower {
  @media screen and (max-width: $bp1023) {
    @content;
  }
}

@mixin from767andLower {
  @media screen and (max-width: $bp767) {
    @content;
  }
}

@mixin from524andLower {
  @media screen and (max-width: $bp524) {
    @content;
  }
}

@mixin from374andLower {
  @media screen and (max-width: $bp374) {
    @content;
  }
}

//CUSTOM MEDIA QUERIES

@mixin from1819andLower {
  @media screen and (max-width: $bp1819) {
    @content;
  }
}

@mixin from1719andLower {
  @media screen and (max-width: $bp1719) {
    @content;
  }
}

@mixin from1619andLower {
  @media screen and (max-width: $bp1619) {
    @content;
  }
}

@mixin from1519andLower {
  @media screen and (max-width: $bp1519) {
    @content;
  }
}

@mixin from1419andLower {
  @media screen and (max-width: $bp1419) {
    @content;
  }
}

@mixin from1319andLower {
  @media screen and (max-width: $bp1319) {
    @content;
  }
}

@mixin from1220andBigger {
  @media screen and (min-width: $bp1219 + 1) {
    @content;
  }
}

@mixin from1219andLower {
  @media screen and (max-width: $bp1219) {
    @content;
  }
}
