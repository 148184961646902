@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.navBar {
  background-color: $color-white;
  display: flex;
  justify-content: center;
  height: $nav-bar-height;
  width: 100%;
  min-width: 100%;
  padding: 0;
  top: calc(#{$header-height} + #{$trial-label-height});
  position: fixed;
  box-shadow: $box-shadow;
  z-index: 5;

  &WithContractBar {
    top: calc(#{$header-height} + #{$trial-label-height} + #{$contract-bar-height});
  }
}

.navBarWrapper {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  max-width: $header-width-1440-and-higher;
}

.withdrawSectionWrapper {
  display: flex;
}

.withdrawMobileSectionWrapper {
  display: none;
}

.withdrawWrapper {
  height: 19px;
  display: flex;
}

.withdrawSection {
  background-color: $color-primary-background;
  height: $nav-bar-height;
  padding: $withdraw-section-padding-vertical $withdraw-section-padding-horizontal;
  display: flex;
  flex-direction: column;

  &:nth-child(2) {
    border-left: 1px solid $color-divider;
    border-right: 1px solid $color-divider;
  }
}

.withdrawSectionAvailable {
  white-space: nowrap;
}

.withdrawBottomSection {
  display: flex;
}

.withdrawSectionWrapper.popupView {
  & .withdrawBottomSection {
    justify-content: center;
  }
}

.withdraw {
  margin-left: 5px;
}

.inlineText {
  display: flex !important;
  align-items: center;
}

.triggerClassName {
  margin-left: 5px;
}

.navOptions {
  display: flex;
  align-items: center;
  // temporary here
  // justify-content: space-between;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.navOptionsMobile {
  display: none;
}

.link {
  height: 100%;
  display: flex;
  align-items: center;

  // temporary here
  // flex-grow: 1;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.unlink {
  text-decoration: none;
}

.middleWithdrawSection {
  min-width: 187px;
  box-sizing: border-box;
}

div.navOption {
  white-space: nowrap;
  height: $nav-bar-height;
  display: flex;
  align-items: center;
  background-image: linear-gradient($color-primary-blue, $color-primary-blue);
  background-size: 0% 4px;
  // temporary here
  // padding-left: 12px;
  // padding-right: 12px;

  padding-left: 17px;
  padding-right: 17px;

  &:hover {
    background-size: 100% 4px;
  }
}

.bodyClassName {
  max-width: 420px;
}

div.navOptionSelected {
  background-color: $color-grey-4;
}

@include from1439andLower {
  .navOptions {
    margin-right: 0px;
  }

  .navBarWrapper {
    margin: 0 24px;
  }

  .link {
    flex-grow: unset;
  }

  .withdrawSectionWrapper {
    display: none;
  }

  .bodyClassName {
    max-width: 280px;
  }

  .withdrawMobileSectionWrapper:not(.popupView) {
    position: fixed;
    left: 0;
    top: $header-height + $trial-label-height + $nav-bar-height;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: $color-primary-background;
    justify-content: space-between;
    height: $mob-withdraw-section-height;
    padding: 8px 32px;

    .withdraw {
      margin-bottom: 0;
    }

    .withdrawBottomSection {
      margin-left: 8px !important;
      justify-content: center;
    }

    .withdrawAmount {
      margin-left: 8px;
    }

    .withdrawSection {
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: row;

      &:nth-child(2) {
        border-left: none;
        border-right: none;
      }
    }
  }

  .withdrawMobileSectionWrapperWithContractBar {
    top: calc(
      #{$header-height} + #{$trial-label-height} + #{$nav-bar-height} + #{$contract-bar-height}
    ) !important;
  }
}

@include from1023andLower {
  div.navOption {
    height: $nav-bar-height-mobile;
  }

  .navOptions {
    display: none;
  }

  .navOptionsMobile {
    display: block;
    width: 100%;
    height: $nav-bar-height-mobile;
  }

  .navOptionsMobileValueContainer {
    flex: 1;
    height: $nav-bar-height-mobile;
    display: flex;
    align-items: center;
    padding: 0;

    > * {
      font-size: 1rem !important;
      font-family: $font-family-bold !important;
      padding: 0 !important;
    }
  }

  .navOptionsMobileControl {
    display: flex;
    border: none;
    background: $color-white;
    border-radius: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .navOptionsMobileMenu {
    padding: 2px;
    width: calc(100% + 50px);
    background-color: $color-white;
    height: auto;
    position: relative;
    box-shadow: $box-shadow;
    border-radius: 8px;
    margin-left: -24px;
    z-index: 5;
  }

  .navOptionsMobileOption {
    background-color: $color-white;
    padding: 14px 22px;
    height: $nav-bar-height-mobile;
    cursor: pointer;
    font-size: 0.9rem;

    .unlink {
      color: $color-text-900;
      font-family: $font-family-bold;
    }
  }
}

@include from1049andLower {
  .navBarWrapper {
    flex-direction: column;
    margin: 0;
    padding: 0 50px;
  }

  .navBar {
    height: $nav-bar-height-tablet;
  }

  .navOptions {
    margin-right: 0;
  }

  div.navOption {
    height: $nav-bar-height-tablet;
  }

  .withdrawMobileSectionWrapper:not(.popupView) {
    top: $header-height + $trial-label-height + $nav-bar-height-tablet;
    z-index: 4;

    .withdrawSection {
      height: auto;
      width: auto;
      padding: 0;
      display: flex;

      &:nth-child(2) {
        border-left: none;
        border-right: none;
      }
    }
  }

  .withdrawMobileSectionWrapperWithContractBar {
    top: calc(
      #{$header-height} + #{$trial-label-height} + #{$nav-bar-height-tablet} + #{$contract-bar-height}
    ) !important;
  }

  .withdrawSection {
    width: calc(100% + 100px);
    height: 36px;
    padding: $withdraw-section-padding-vertical-mobile
      $withdraw-section-padding-horizontal-mobile;
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 12px;
    position: relative;
    z-index: 4;
  }
}

.popupDividerBottom,
.popupDividerTop {
  display: none;
}

@include from767andLower {
  .navBar {
    height: $nav-bar-height-mobile;

    &WithContractBar {
      top: calc(
        #{$header-height} + #{$trial-label-height} + #{$contract-bar-height-mobile}
      );
    }
  }

  div.navOption {
    height: $nav-bar-height-mobile;
  }

  .withdrawMobileSectionWrapper:not(.popupView) {
    flex-wrap: wrap;
    height: $mob-withdraw-section-height-mobile;
    padding: 8px 24px;
    gap: 0;
    z-index: 4;

    .withdrawSection {
      height: auto;
      width: auto;
      padding: 0;
      display: flex;

      &:nth-child(2) {
        border-left: none;
        border-right: none;
      }
    }
  }

  .withdrawMobileSectionWrapperWithContractBar {
    top: calc(
      #{$header-height} + #{$trial-label-height} + #{$nav-bar-height-tablet} + #{$contract-bar-height-mobile}
    ) !important;
  }

  .withdrawMobileSectionMiddleWrapper {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    order: 3;
  }

  .popupDividerBottom,
  .popupDividerTop {
    display: block;
    width: 100%;
  }

  .popupDividerBottom {
    margin-top: 12px !important;
  }

  .popupDividerTop {
    margin-bottom: 12px !important;
  }
}
