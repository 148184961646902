@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.orderCard {
  display: flex;
  flex-direction: column;
  background: $color-white;
  border-radius: 15px;
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.1),
    0px 2px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  margin-bottom: 40px;
}

@include from767andLower {
  .orderCard {
    margin-bottom: 24px;
  }
}
