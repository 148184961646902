@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.header {
  display: flex;
  flex-direction: column;
  background-color: $color-pink;
}

.header > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content {
  padding: 32px !important;
}

.footer {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  gap: 4px 16px;
  > div {
    flex: 1;
  }
}

.tiers {
  flex: 50%;
  display: flex;
  flex-direction: column;
  gap: 4px 0;
  padding-top: 4px;
}

.tierItem {
  display: flex;
  justify-content: space-between;
}

.tiersDates {
  flex: 50%;
  display: flex;
  flex-direction: column;
  gap: 4px 0;
  padding-top: 4px;
}

.revenueDate,
.revenueLink {
  text-align: right;
}

@include from767andLower {
  .revenue > div {
    flex: 1;
  }
  .content {
    padding: 32px 24px;
  }
}
