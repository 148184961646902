@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/colors.constant.module';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  z-index: 13;
  padding: 24px;

  & hr {
    margin: 24px 0 0 0;
  }

  & > div[class*='_content_'] {
    margin-top: 16px;
  }

  & > p[class*='_description_'],
  & > div[class*='_footer_'] {
    margin-top: 24px;
  }
}

.inputSelect {
  margin-bottom: 16px;

  :global(div.select__control) {
    border-radius: 32px !important;
  }
}

.button {
  min-width: 224px;
}

.overlay {
  z-index: 13;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $color-white;
  opacity: 0.5;
  border-radius: 15px;
}

@include from767andLower {
  .dialogContentWrapperClassName {
    padding: 16px;

    & hr[class*='_footerDivider_'] {
      margin: 16px 0 0 0;
    }

    & > div[class*='_content_'],
    & > p[class*='_description_'],
    & > div[class*='_footer_'] {
      margin-top: 16px;
    }
  }

  .footerClassName {
    gap: 8px;
  }

  .button {
    min-width: auto;
    flex: 50%;
  }
}
