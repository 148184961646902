@import 'constants/styles/breakpoints.constant.module.scss';

.toolbar {
  justify-content: space-between;
}

.wrapper {
  margin-bottom: 128px;
  min-height: 500px;
}

.pagination {
  margin-top: 48px;
  justify-content: center;
}

@include from1023andLower {
  .pagination {
    margin-top: 32px;
  }
}
