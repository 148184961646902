@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 32px;
  min-height: 70px;
  position: relative;
}

.toolbar {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}

.filter {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.searchInput {
  flex: 1;
}

.searchAdornmentIcon {
  display: flex;
  width: 20px;
  margin-right: 8px;
}

.mobileToolbar {
  display: none;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.spaceBetween {
  justify-content: space-between;
}

.select {
  max-width: 135px;
}

.mobilePagination {
  justify-content: space-between;
  flex: 1;
}

.flex1 {
  flex: 1;
}

.downloadCsvContainer {
  max-width: 140px;
  display: flex;
  justify-content: flex-end;
}

.mobileSearch {
  left: 0;
  justify-content: flex-start;
}

.children {
  z-index: 1;
}

.error {
  display: none !important;
}

.mobileSelect {
  width: 100%;

  button {
    width: 100%;
  }
}

@include from767andLower {
  .container {
    min-height: 144px;
    flex-direction: column;
  }

  .toolbar {
    display: none;
  }

  .mobileToolbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;
  }

  .container {
    padding: 16px;
  }
}
