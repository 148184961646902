@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.accordion {
  overflow-y: scroll;
  max-height: 95%;
  margin: 0 0 10px 0;
  padding: 0;
  width: 100%;
}

.accordionItem {
  padding: 20px 5px;
}

.accordionItemContent {
  padding: 0 24px 0 24px;
  margin: 0;
}

.skipOverflow {
  overflow: initial;
  z-index: 1;
  position: relative;
}

.dropzoneLabel {
  margin-bottom: 4px;
  padding-left: 16px;
  display: flex;
}

.subInputInfo {
  margin-left: 16px;
  margin-top: 4px;
  display: flex;
}

.subInputInfoFullWidth {
  display: flex;
}

.inputWrapper {
  label {
    color: $color-text-900;
  }
  input {
    color: $color-text-900;
    font-family: $font-family-regular;
    line-height: 162.5%;
  }
}

.textareaWrapper {
  align-items: start;

  > textarea {
    min-height: 110px;
    color: $color-text-900;
    font-family: $font-family-regular;
    line-height: 162.5%;
  }
}

.textarea {
  column-gap: 0px;
  margin-bottom: 0px;
  div {
    margin: 0;
  }
}

.circle {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 0px;
}

.circleOption {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}
.navOptionsMobileOption {
  display: flex;
  align-items: center;
  background-color: $color-white;
  padding: 14px 22px;
  height: $nav-bar-height-mobile;
  cursor: pointer;
  font-size: 0.9rem;
}
.navOptionsMobileOption:hover {
  background-color: $color-grey-3;
}
.selectedOption {
  background-color: $color-grey-3;
}
.navOptionsMobileValueContainer {
  display: flex;
  align-items: center;

  > * {
    font-size: 1rem !important;
  }
}

.textareaLabel {
  font-family: $font-family-regular;
  color: $color-text-900;
}

.dropzoneButton {
  & > div {
    font-size: 0.875rem;
  }
}

.previewImageWrapper {
  img {
    max-width: 150px;
    margin-bottom: 10px;
  }
}

.uploadTextWrapper {
  text-align: center;
}

.imageLoader {
  display: flex;
  flex-direction: column;

  button {
    width: auto;
    height: 40px;
    max-width: 70%;
  }
}

.imagesList {
  display: flex;
  row-gap: 16px;
  flex-direction: column;
}

.collectionCheckBox {
  margin-top: 4px;
}

.disabledBlock {
  position: absolute;
}

.disabledOverlay {
  opacity: 0.5;
  height: calc(100% - 40px);
  bottom: 0;
  width: 100%;
  z-index: 10;
  background-color: $color-white;
}

.lastSelect {
  margin-bottom: 4px;
}

.dropzoneHelperText {
  margin: 4px 16px !important;
  margin-bottom: 0 !important;
}

@include from1439andLower {
  .imageLoader {
    padding: 16px;
  }
}

@include from1023andLower {
  .imageLoader {
    div {
      display: flex;
      flex-direction: column;
    }

    img {
      width: 100px;
    }

    .dropzoneButton {
      padding: 0 12px !important;
      width: 100% !important;
      max-width: 100px !important;
      height: 32px !important;
      & > div {
        font-size: 0.875rem !important;
      }
    }
  }
}

.sidebarMainBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  position: relative;
}

@include from767andLower {
  iframe {
    height: 75%;
  }

  .imageLoader {
    div {
      display: flex;
      flex-direction: column;
    }

    img {
      width: 100px;
    }

    .dropzoneButton {
      padding: 0 12px !important;
      width: 100% !important;
      max-width: 100px !important;
      height: 32px !important;
      & > div {
        font-size: 0.875rem !important;
      }
    }
  }

  .accordion {
    height: auto;
    max-height: unset;
  }

  .accordionItemContent {
    & > div {
      font-size: 0.875rem !important;
    }
  }

  .header {
    border-bottom: none;
    margin-bottom: 0;
  }

  .dropzoneButton {
    padding: 0 12px !important;
    width: 100% !important;
    max-width: 100px !important;
    height: 32px !important;
    & > div {
      font-size: 0.875rem !important;
    }
  }
}

@include from1920andBigger {
  .accordion {
    max-height: calc(100% - 74px);
  }
  .previewImageWrapper {
    img {
      max-width: 290px;
      margin-bottom: 10px;
    }
  }
}

@include from1919andLower {
  .accordion {
    max-height: calc(100% - 74px);
  }
  .previewImageWrapper {
    img {
      max-width: 150px;
      margin-bottom: 10px;
    }
  }
}
