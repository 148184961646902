@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/colors.constant.module';

.certificates {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;

  .contentList {
    height: 100%;
  }
  .contentList .divider {
    margin: 30px 0 0 0;
  }
  .contentList .dividerLast {
    margin: 30px 0 0px 0;
  }
}

.content {
  position: relative;
  margin-bottom: 30px;
  height: 87px;
  overflow: hidden;
  transition: height 0.4s ease;
}

.item {
  padding-top: 30px;
}

.contentWithBottomBorder {
  border-bottom: 1px solid $color-grey-3;
}

.fixedHeightContent {
  height: 348px;
}

.loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  line-height: 0;
}

.disabledArrows {
  pointer-events: none;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button {
  width: 195px;
}

.noCertificates {
  border-radius: 16px;
  background-color: $color-neutral-100;
  height: 87px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-text-600;
  font-family: $font-family-bold;
}

@include from767andLower {
  .fixedHeightContent {
    height: 531px;
  }

  .footer {
    flex-direction: column-reverse;
    justify-items: center;
    align-items: center;
    gap: 24px;

    .button {
      width: 100%;
    }
  }
}
