@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 1344px !important;
  z-index: 13;
  padding: 24px !important;
  max-height: 1200px;
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.btn {
  min-width: 224px;
  height: 48px !important;

  &:disabled {
    cursor: not-allowed;
  }
}

.btnLeft {
  flex: 1;
  display: flex;
  align-items: center;
}

.dialogContentWrapper {
  margin-top: 24px;
}

.label {
  position: absolute;
  left: 32px;
  top: 32px;
  border-radius: 24px;
  width: auto;
  height: 20px;
  width: auto !important;
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s;
  background-color: $color-light-blue;
}

.contentWrapper {
  overflow-y: auto;
  min-height: 52px;
  margin-top: 24px;
  height: calc(80vh - 262px);
  display: grid;
  grid-template-areas:
    'mockup text'
    'mockup divider'
    'mockup pagination'
    'mockup buttons'
    'mockup revision'
    'mockup .';
  grid-template-columns: 529px 1fr;
  grid-template-rows: auto 33px 124px 64px 148px;
  column-gap: 24px;
  margin-bottom: -6px;

  &.compareMode {
    grid-template-areas: 'mockup customerMockup';
    grid-template-columns: 529px 529px;
    grid-template-rows: 1fr;
  }
}

.closeBtn {
  padding: 11px 16px;
  margin-right: auto;
  height: 48px;
}

.mockupImg {
  grid-area: mockup;
}

.paginationBlock {
  width: 100%;
  padding-top: 24px;
  background-color: $color-white;
  display: grid;
  grid-template-areas: 'mockup customerMockup';
  grid-template-columns: 529px 529px;
  grid-template-rows: 1fr;
  column-gap: 24px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
}

.customerMockupImg,
.mockupImg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .label {
      transition: opacity 0.2s;
      opacity: 0;
    }
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.text {
  grid-area: text;
}

.textDivider {
  grid-area: divider;
  margin: 16px 0;
  border-width: 0px 0px thin;
}

.revisionWrapper {
  grid-area: revision;
  border-radius: 16px;
  margin-top: 16px;
  box-shadow: $box-shadow;
  border: solid 1px $color-grey-3;
  padding: 32px;
  display: flex;
  flex-direction: column;
  height: 220px;

  & > div:first-child {
    padding-left: 16px;
    margin-bottom: 4px;
  }
}

.textArea {
  resize: none !important;
  background-color: $color-primary-background;
  height: 100%;
}

.inputWrapperClassName {
  max-height: 74px;
  border-radius: 16px;
  background-color: $color-primary-background;
  min-height: 132px;
}

.shouldHide {
  transition:
    opacity 300ms,
    max-width 300ms;
  opacity: 0;
  max-width: 0;
  width: 0;
  pointer-events: none;
  z-index: 0;
  height: 0 !important;
  padding: 0;
}

.shouldHideOpacity {
  transition: opacity 300ms;
  opacity: 0;
  pointer-events: none;
  z-index: 0;
}

.shouldShowOpacity {
  transition: opacity 300ms;
  transition-delay: 300ms;
  opacity: 1;
}

.shouldMoveSecondaryBtn {
  z-index: 1;
  transform: translateX(100%);
}

.productionMockupViewerClassName {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loaderClassName {
  width: 20px !important;
  height: 20px !important;
  left: 15px !important;
  top: 20px !important;
  position: absolute;

  & * {
    width: 20px !important;
    height: 20px !important;
  }
}

.pagePreviewClassName {
  display: flex;
  align-items: center;
  justify-content: center;

  canvas {
    width: auto !important;
    height: auto !important;
    max-width: 529px;
  }
}

.smallPagePreviewClassName {
  display: flex;
  align-items: center;
  justify-content: center;

  canvas {
    width: auto !important;
    height: auto !important;
    max-width: 47px;
    max-height: 60px;
  }
}

.pagination {
  grid-area: pagination;
  border-radius: 16px;
  box-shadow: $box-shadow;
  border: solid 1px $color-grey-3;
  padding: 32px 36px;
  display: grid;
  grid-template-areas:
    'mockupPreview paginationText'
    'mockupPreview paginationValue';
  grid-template-columns: 47px 1fr;
  grid-template-rows: 26px 26px;
  column-gap: 24px;
  column-gap: 16px;
  row-gap: 8px;

  & .mockupImgPreview {
    grid-area: mockupPreview;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & .paginationText {
    grid-area: paginationText;
  }

  & .paginationValue {
    grid-area: paginationValue;
  }
}

.rightSidePaginationBlock {
  & > div {
    grid-row: 1/2;
    grid-column: 2/3;
  }
}

.downloadBtn {
  margin-right: 16px;
  max-width: 202px;
  padding: 12px 16px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
      margin-right: 8px;
    }
  }
}

.buttons {
  grid-area: buttons;
  display: flex;
  align-items: flex-end;
}

.withdrawBottomSection {
  margin-top: 5px;
}

@include from1439andLower {
  .dialogContentWrapperClassName {
    width: 976px !important;
  }

  .paginationBlock {
    grid-template-columns: 452px 452px;
  }

  .pagePreviewClassName {
    canvas {
      max-width: 452px;
    }
  }

  .contentWrapper {
    grid-template-columns: 452px 1fr;
    grid-template-rows: 78px 33px 124px 64px 148px;
    column-gap: 24px;
    margin-bottom: -6px;

    &.compareMode {
      column-gap: 24px;
      grid-template-columns: 452px 452px;
    }
  }
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: 720px !important;
    min-width: 720px !important;
    margin: auto;
  }

  .revisionWrapper {
    padding: 16px;
    height: 188px;
  }

  .dialogContentWrapper {
    margin-top: 16px;
  }

  .pagePreviewClassName {
    canvas {
      max-width: 311px;
    }
  }

  .paginationBlock {
    grid-template-columns: 311px 311px;
  }

  .contentWrapper {
    grid-template-columns: 311px 1fr;
    grid-template-rows: 104px 33px 108px 64px 148px;
    column-gap: 24px;
    margin-bottom: -6px;
    overflow-y: scroll;

    &.compareMode {
      grid-template-columns: 311px 311px;
    }
  }

  .pagination {
    padding: 24px;
  }

  .buttons {
    align-items: flex-start;
    flex-direction: column;
  }

  .downloadBtn {
    margin-top: 16px;
  }
}

@include from767andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
    min-width: unset !important;
    padding: 16px !important;
    margin: auto;
  }

  .pagePreviewClassName {
    canvas {
      max-width: unset;
      max-height: unset;
      width: 100% !important;
    }
  }

  .contentWrapper {
    margin-top: 16px;
    grid-template-areas:
      'mockup'
      'pag3'
      'text'
      'divider'
      'pagination'
      'buttons'
      'revision';
    grid-template-columns: 1fr;
    grid-template-rows: auto 68px auto 33px 108px 64px 146px;
    column-gap: unset;
    margin-bottom: -6px;

    &.compareMode {
      grid-template-areas:
        'mockup'
        'pag1'
        'customerMockup'
        'pag2';
      grid-template-rows: auto 68px auto 68px;
      column-gap: unset;
      row-gap: 16px;
      grid-template-columns: 1fr;

      &.contentWrapperWithoutCMPagination {
        grid-template-areas:
          'mockup'
          'pag1'
          'customerMockup';
        grid-template-columns: 1fr;
        column-gap: unset;
        grid-template-rows: auto 68px auto;
      }
    }
  }

  .contentWrapperWithoutPMPagination {
    grid-template-areas:
      'mockup'
      'text'
      'divider'
      'pagination'
      'buttons'
      'revision';
    grid-template-columns: 1fr;
    column-gap: unset;
    grid-template-rows: auto auto 33px 108px 64px 146px;

    &.compareMode {
      grid-template-areas:
        'mockup'
        'customerMockup'
        'pag2';
      grid-template-columns: 1fr;
      column-gap: unset;
      row-gap: 16px;
      grid-template-rows: auto auto 68px;

      &.contentWrapperWithoutCMPagination {
        grid-template-areas:
          'mockup'
          'customerMockup';
        grid-template-rows: auto auto;
        column-gap: unset;
        row-gap: 16px;
        grid-template-columns: 1fr;
      }
    }
  }

  .text {
    margin-top: 16px;
  }

  .shouldMoveSecondaryBtn {
    z-index: 1;
    transform: translateX(0);
  }

  .paginationBlockMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .paginationBlockContentView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .titleClassName {
    max-width: 250px;
  }

  .closeBtn {
    width: 100%;
  }

  .footerButtons {
    flex-direction: column;
  }
}

@include from767andLower {
  .footerButtons {
    flex-direction: column;
  }

  .btnLeft {
    justify-content: center;
    order: 1;
  }
}
