@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.container {
  width: 1344px;
  margin: 0 auto;
}

.toolbar {
  margin-bottom: 24px;
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.emptyStateWrapper {
  margin-bottom: 128px;
}

.containerWrapperEmpty {
  text-align: center;
}

.buildBtn {
  height: 40px;
  width: 10.594rem;
  white-space: nowrap;
  margin-right: 24px;

  & * {
    font-size: 0.875rem;
  }
}

.goToBtn {
  cursor: pointer;
}

.number {
  min-width: 45px;
  margin-right: 24px;
}
.sentAt {
  margin-left: 5px;
}

.toolbarClassName {
  position: relative;
}

.companyTooltip {
  display: flex;
  flex-direction: column;
}

.manageWrapper {
  display: flex;

  .trashIcon {
    border-radius: 50%;
    min-height: 32px !important;
    min-width: 32px !important;
    background-color: $color-divider;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;

    &:last-of-type {
      margin-right: 16px;
    }
  }

  button {
    margin-left: auto;
  }
}

.filterClassName {
  margin-left: 0;
  max-width: 281px;
}

.searchClassName,
.searchWrapperClassName {
  max-width: unset;
  width: 100%;
}

.searchWrapperClassName {
  & > div {
    &:last-child {
      & > div {
        margin-right: 0;
      }
    }
  }
}

.smallBtn {
  height: 32px;
  cursor: text;
  pointer-events: none;
}

.rightAligned {
  margin-left: auto;
}

.purple {
  background-color: $color-primary-purple;
  cursor: pointer;
  pointer-events: initial;
}

.grey {
  background-color: $color-divider;
  color: $color-text-500;
}

.pagination {
  margin-top: 48px;
  margin-bottom: 128px;
  justify-content: center;
}

button.emptyStateButton {
  width: 224px;
  height: 48px;
}

.inventoryOrderTypeWrapper {
  font-size: 0.875rem;
  line-height: 142.857%;
}

.assignToWrapper {
  padding: 1px;
}

.contractSigned {
  color: $color-light-green;
}

.orderTypeCell {
  width: 125px;
  max-width: 125px;
}

.checkboxCell {
  width: 150px;
  max-width: 150px;
}

.viewContract {
  color: $color-pink;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    width: 100%;
    margin: 0;
  }
}

@include from1023andLower {
  .emptyStateWrapper {
    margin-bottom: 96px;
  }

  .searchClassName,
  .searchWrapperClassName {
    width: 200px;
  }

  .pagination {
    margin-top: 32px;
    margin-bottom: 96px;
  }
}

@include from767andLower {
  .buildBtn {
    margin-bottom: 24px;
    margin-right: 0;
    width: 279px;
  }

  .mobileSearchRowClassName {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mobileInputClassName {
    justify-content: center;
    position: relative;
  }

  .mobilePaginationClassName {
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .mobileInputComponentClassName {
    width: 100%;
    min-width: 100%;
    & > div {
      &:last-child {
        & > div {
          margin-right: 0;
        }
      }
    }
  }

  button.emptyStateButton {
    width: 189px;
  }
}
