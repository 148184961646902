@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module';

div.container {
  display: flex;
  padding: 32px 0 28px;
  max-width: 912px;
  min-width: 912px;
  max-height: 749px;
  min-height: 159px;
  height: initial;
  flex-direction: column;
}

.tableWrapper {
  width: 912px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 32px;
  position: sticky;
  left: 0;
}

.headerDivider {
  position: sticky;
  left: 0;
}

.footer {
  margin-top: 20px;
  padding: 0 32px;
}

.title {
  margin-bottom: 0;
}

div.tableHeader {
  background: $color-white;
  padding: 0 32px;
  height: initial;
}

div.tableRow {
  @extend .tableHeader;
  border-bottom-width: 1px;
  position: relative;

  &:last-child {
    & > div {
      padding-bottom: 0;
    }
  }
}

.tableRowGrouped:not(.tableLastRowGrouped):not(.tableFirstRowGrouped) {
  border-bottom: none;
  .cell {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.tableLastRowGrouped {
  .cell {
    padding-top: 8px;
  }
}

.tableFirstRowGrouped {
  border-bottom: none;
  .cell {
    padding-bottom: 8px;
  }
}

div.cell {
  margin-right: 24px;
  padding: 16px 0;
  height: auto;

  &:last-child {
    margin-right: 0;
  }
}

div.number,
div.paidAt,
div.total {
  @extend .cell;
  width: 150px;
}

div.status,
div.products {
  @extend .cell;
  width: 230px;
}

.rowNumber {
  div {
    span {
      cursor: pointer;
      color: $color-pink;
    }
  }
}

.inline {
  display: inline;
}

@include from1919andLower {
  div.container {
    max-width: 891px;
    min-width: 891px;
  }

  .tableWrapper {
    width: 891px;
  }
}

@include from1439andLower {
  div.container {
    max-width: 976px;
    min-width: 976px;
  }
  .tableWrapper {
    width: 976px;
  }
}

@include from1049andLower {
  div.container {
    max-width: 720px;
    min-width: 720px;
  }
}

@include from767andLower {
  div.container {
    max-width: 327px;
    min-width: 327px;
    max-height: 753px;
    min-height: 269px;
  }
}
