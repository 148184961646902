@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/table.constant.module';

.container {
  width: 1344px;
  margin: 0 auto;
}

.toolbar {
  margin-bottom: 24px;
}

.emptyStateWrapper {
  margin-bottom: 128px;
}

.buildBtn {
  height: 40px;
  width: 154px;
  margin-right: 16px;

  & * {
    font-size: 0.875rem;
  }
}

.headerCell {
  overflow: visible;
}

.number {
  @include indexColumn;
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.manageWrapper {
  display: flex;
  width: 175px;

  .trashIcon {
    border-radius: 50%;
    min-height: 32px !important;
    min-width: 32px !important;
    background-color: $color-divider;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;

    &:last-of-type {
      margin-right: 14px;
    }
  }

  button {
    margin-left: auto;
  }
}

.managementColumn {
  width: 200px;
}

.smallBtn {
  height: 32px;
  cursor: text;
  pointer-events: none;
}

.yellow {
  background-color: $color-pending-500;
}

.green {
  background-color: $color-success-700;
}

button.emptyStateButton {
  width: 224px;
  height: 48px;
}

.pagination {
  margin-top: 48px;
  margin-bottom: 128px;
  justify-content: center;
}

.assignToWrapper {
  padding: 1px;
}

@media screen and (max-width: 1440px) {
  .container {
    width: 100%;
    margin: 0;
  }
}

@include from1023andLower {
  .emptyStateWrapper {
    margin-bottom: 96px;
  }

  .pagination {
    margin-top: 32px;
    margin-bottom: 96px;
  }
}

.buildBtnWrapper {
  width: auto;
  .buildBtnDivider {
    display: none;
  }
}

@include from767andLower {
  .buildBtn {
    margin-right: 0px;
    width: 100%;
  }

  .buildBtnWrapper {
    width: 100%;
    .buildBtnDivider {
      display: block;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  button.emptyStateButton {
    width: 189px;
  }
}
