@import '../common.module';
@import 'constants/styles/colors.constant.module';
@import 'constants/styles/table.constant.module';

.root {
  @extend .table;
}

.number {
  @include indexColumn;
}

.naStyle span {
  color: $color-text-500 !important;
}

.cell {
  padding: 19px 0;
}

.status {
  overflow: initial !important;
}

.cartSyncedAt {
  width: 160px;
}

.selectWrapper {
  padding: 1px;

  &.colorGreen {
    :global(div.select__single-value) {
      color: $color-green-primary;
    }
  }
}
