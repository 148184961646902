@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/single-container.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

$main-padding-top: 48px;
$main-padding-top-768: 32px;

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.root {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-color: $color-primary-background;
  overflow: hidden;
}

.onboardContentWrapper {
  display: flex;
  justify-content: center;
  padding-top: $header-height;
  padding-bottom: 158px;
  height: 100%;
  min-height: calc(100vh - $header-height - $footer-height);
}

.onboardContent {
  padding-top: $main-padding-top;
  width: 100%;
  max-width: $maxWidthContent1920;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 103px min-content;
  row-gap: 53px;
}

.outletWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stars1 {
  position: fixed;
  left: 131px;
  bottom: 196px;
  width: 58px !important;
  height: 58px !important;
}

.stars2 {
  position: fixed;
  left: 600px;
  top: 329px;
  width: 90px;
  height: 90px;
}

.ring1 {
  position: fixed;
  top: 0px;
  left: 480px;
  width: 398px;
  height: 296px;
}

.ring2 {
  position: fixed;
  bottom: 30%;
  left: 0px;
  height: 395px;
  width: 223px;
}

.ring3 {
  position: fixed;
  bottom: 40px;
  left: 548px;
  height: 312px;
}

.footer {
  position: fixed;
  width: 100vw;
  padding: 24px 48px;
  bottom: 0;
  left: 0;
  background: $color-white;
  box-shadow: 0px 2px 24px 12px rgba(108, 109, 110, 0.07);
  z-index: 3;

  &Frame {
    bottom: 48px;
  }
}

.footerContent {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  max-width: $maxWidthContent1920;
  width: 100%;
}

.stepperWrapper {
  z-index: 2;
}

.nextBtnWrapper {
  margin-left: auto !important;
}

.prevBtn {
  margin-right: 48px;

  &:disabled {
    & > * {
      color: $color-text-disabled !important;
    }
  }

  & > * {
    color: $color-typography-body !important;
  }
}

button.prevBtn,
button.nextBtn {
  width: 343px;
}

.headerWrapperClassName {
  max-width: $maxWidthContent1920;
  width: 100%;
  margin: 0;
}

.headerClassName {
  padding: 10px 48px;
  min-width: 100%;
  width: 100%;
}

@include from1919andLower {
  .onboardContent {
    max-width: $maxWidthContent1440;
  }

  .headerWrapperClassName {
    max-width: $maxWidthContent1440;
  }

  .footerContent {
    max-width: $maxWidthContent1440;
  }
}

@include from1439andLower {
  .onboardContent {
    max-width: $maxWidthContent1024;
    row-gap: 48px;
  }

  .outletWrapper {
    align-items: flex-start;
  }

  .headerWrapperClassName {
    max-width: $maxWidthContent1024;
  }

  .footerContent {
    max-width: $maxWidthContent1024;
  }

  .headerClassName {
    padding: 10px 24px;
    min-width: 100%;
    width: 100%;
  }
}

@include from1023andLower {
  .onboardContent {
    max-width: $maxWidthContent768;
    padding-top: $main-padding-top-768;
    row-gap: 32px;
  }

  .headerWrapperClassName {
    max-width: $maxWidthContent768;
  }

  .footerContent {
    max-width: $maxWidthContent768;
  }

  .footer {
    padding: 24px;
  }
}

@include from767andLower {
  .onboardContent {
    max-width: $maxWidthContent525;
  }

  .headerWrapperClassName {
    max-width: $maxWidthContent525;
  }

  .footerContent {
    max-width: $maxWidthContent525;
  }

  .withOneBtn {
    justify-content: flex-end;
  }

  button.prevBtn,
  button.nextBtn {
    width: 115px;
    margin: 0;
  }
}

@include from524andLower {
  .onboardContent {
    max-width: $maxWidthContent375;
  }

  .headerWrapperClassName {
    max-width: $maxWidthContent375;
  }

  .footerContent {
    max-width: $maxWidthContent375;
  }
}
