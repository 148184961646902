@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/animation.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 15;
  hr {
    margin-top: 0px;
  }
}

.twoInRowContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .input {
    flex: 1;
  }
}

.label {
  color: $color-grey-dark;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
  margin-top: 24px;
  button {
    min-width: 178.5px;
    width: 224px;
    height: 48px;
  }
}

@include from767andLower {
  .formFields {
    overflow-y: auto;
    max-height: 300px;
  }
  .input {
    flex: none;
    min-width: 100%;
  }
  .btn {
    width: 178.5px;
  }
  .twoInRowContainer {
    flex-direction: column;
  }
}

@include from524andLower {
  .footer {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
      margin-top: 5px;
    }
  }
}
