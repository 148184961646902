@import 'constants/styles/colors.constant.module.scss';

.toggle {
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin: 6px 0 0;

  .label {
    padding-right: 52px;
    color: $color-primary-text;
  }

  .checked {
    position: relative;
    width: 44px;
    height: 24px;
    margin-left: -44px;

    &:after {
      height: 16px;
      width: 16px;
    }
  }

  input:checked ~ .checked:after {
    transform: translate(24px, -50%) !important;
  }
}
