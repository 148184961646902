@import 'constants/styles/breakpoints.constant.module.scss';

.emptyStateWrapper {
  margin-bottom: 128px;
}

@include from1023andLower {
  .emptyStateWrapper {
    margin-bottom: 96px;
  }
}
