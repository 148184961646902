@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';
@import 'constants/styles/fonts.constant.module.scss';

.accordion {
  overflow-y: scroll;
  max-height: 95%;
  margin: 0px 0px 10px 0px;
  padding: 0px;
  width: 100%;
}

.accordionItem {
  padding: 20px 5px;
}

.accordionItemContent {
  padding: 0px 24px 0px 24px;
  margin: 0px;
}

.editorTitle {
  font-family: $font-family-regular;
  color: $color-text-icon;
  margin: 24px 24px;
}

.editorText {
  font-family: $font-family-regular;
  color: $color-text-icon;
  margin-top: 15px;
}

.maxLengthText {
  color: $color-text-500;
  font-family: $font-family-regular;
  font-size: 0.875rem;
  margin-left: 10px;
}

.inputWrapper {
  display: grid;
}

.textarea {
  margin-top: 15px;
  column-gap: 0px;
  margin-bottom: 0px;

  div {
    margin: 0px;
  }
}

.textareaLabel {
  font-family: $font-family-regular;
}

.dropzoneButton {
  & > div {
    font-size: 0.875rem;
  }
}

.dropzoneHelperText {
  margin: 4px 10px !important;
  margin-bottom: 0 !important;
}

.previewImageWrapper {
  img {
    max-width: 150px;
    margin-bottom: 10px;
  }
}

.uploadTextWrapper {
  text-align: center;
}

.imageLoader {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  button {
    width: auto;
    height: 40px;
    max-width: 70%;
  }
}

@include from1319andLower {
  .previewImageWrapper {
    max-width: 185px;
  }
}

@include from1023andLower {
  .imageLoader {
    div {
      display: flex;
      flex-direction: column;
    }

    img {
      width: 100px;
    }

    .dropzoneButton {
      padding: 0 12px !important;
      width: 100% !important;
      max-width: 100px !important;
      height: 32px !important;

      & > div {
        font-size: 0.875rem !important;
      }
    }
  }

  .previewImageWrapper {
    max-width: initial;
  }
}

@include from767andLower {
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 95%;
  }

  iframe {
    height: 75%;
  }

  .header {
    margin: 16px 24px;
    align-items: center;
    justify-content: space-between;
  }

  .previewSection {
    width: 100%;
    height: 80%;
    border-radius: 0;
    position: absolute;
    left: 0;
    bottom: 150px;
  }

  .imageLoader {
    div {
      display: flex;
      flex-direction: column;
    }

    img {
      width: 100px;
    }

    .dropzoneButton {
      padding: 0 12px !important;
      width: 100% !important;
      max-width: 100px !important;
      height: 32px !important;

      & > div {
        font-size: 0.875rem !important;
      }
    }
  }

  .roundButton {
    display: flex !important;
    padding: 10px !important;
    height: auto !important;

    .roundButtonText {
      line-height: 0 !important;
    }
  }

  .buttonGroup {
    background-color: $color-primary-background;
    width: 100%;
    padding: 16px 24px 24px 24px;
    gap: 16px;
    left: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 15;
    height: 150px;

    button {
      height: 80px;
      width: 100%;
      margin: 0;
    }
  }

  .accordion {
    height: auto;
    max-height: unset;
  }

  .accordionItemContent {
    & > div {
      font-size: 0.875rem !important;
    }
  }

  .header {
    border-bottom: none;
    margin-bottom: 0;
  }

  .dropzoneButton {
    padding: 0 12px !important;
    width: 100% !important;
    max-width: 100px !important;
    height: 32px !important;

    & > div {
      font-size: 0.875rem !important;
    }
  }
}

@include from1920andBigger {
  .accordion {
    max-height: 90%;
  }

  .previewImageWrapper {
    img {
      max-width: 290px;
      margin-bottom: 10px;
    }
  }
}

@include from1919andLower {
  .accordion {
    max-height: calc(100% - 74px);
  }

  .previewImageWrapper {
    img {
      max-width: 150px;
      margin-bottom: 10px;
    }
  }
}
