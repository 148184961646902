@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/animation.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: initial;
}

.formBrand {
  display: flex;
  gap: 60px;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: initial;
}

.formLink {
  position: relative;
}

.infoIcon {
  margin-right: 21.5px;
}

.colors {
  background: transparent !important;
  box-shadow: none !important;
  flex: 1;
  padding: 35px 0 !important;
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.imageUpload {
  overflow: hidden !important;
  height: auto !important;
}

.twoInRowContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .input {
    flex: 1;
  }
}

.inputEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inputEllipsis:focus {
  text-overflow: clip;
}

.containerWithCheckbox {
  flex: 1;
  margin-bottom: 24px;
}

.inputErrorWithCheckbox {
  margin-bottom: 8px;
}

.controlBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button {
    min-width: 224px;
    height: 48px;
    margin: 5px;
  }
}

.assignmentOrderContainer {
  margin-top: 30px;
  background: $color-grey-4;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 66px;
  width: 100%;

  & > div {
    z-index: 0;
  }
}

.toggleLabel {
  color: $color-primary-text;
}

.imageUploadTools {
  width: 411px;
}

@include from1023andLower {
  .imageUploadTools {
    width: 428px;
  }
}

@include from767andLower {
  .input {
    flex: none;
    min-width: 100%;
  }
  .imageUploadTools {
    width: 300px;
  }
}

@include from1023andLower {
  .formBrand {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
  .colors {
    padding: 0 !important;
  }
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.storeName {
  position: relative;
  display: flex;
  padding: 11px 16px;
  border-radius: 32px;
  background-color: $color-grey-4;
  height: 48px;
  align-items: center;
}

.storeName .copy {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  right: 16px;
  top: 50%;
  height: 20px;
  min-width: 20px;
  width: 'auto';
}

.storeNameLabel {
  margin-bottom: 4px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 20px;
}

.storeAndHomepageTitle {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  > div {
    flex: 1;
  }
}

.adornmentInfo {
  width: 16px;
  height: 16px;
}

.adornmentInfoIcon {
  line-height: 0;
}

.inputSizer {
  display: inline-grid;

  position: relative;
  font: inherit;
  padding: 0;
  margin: 0;

  &::after,
  .storePrefixName {
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    font: inherit;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    text-align: left;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    height: 1.5rem;
    line-height: 1.5rem;
  }

  &::after {
    content: attr(data-value);
    visibility: hidden;
    white-space: pre-wrap;
  }
}

.connectDomainBtn {
  height: 48px;
  width: 237px;
  padding: 20px;
  margin: 24px 0px;
}

.copy {
  cursor: pointer;
}

div.errorText {
  padding: 0 16px;
}

@include from767andLower {
  .storeAndHomepageTitle,
  .twoInRowContainer {
    flex-direction: column;
  }

  .mobileHidden {
    display: none;
  }
}
