@import 'constants/styles/breakpoints.constant.module.scss';

.expired {
  display: grid;
  grid-template-columns: 520px;
  grid-template-rows: auto;
  row-gap: 20px;
}
.mainBlock {
  text-align: center;
}
@include from1439andLower {
  .expired {
    grid-template-columns: 632px;
  }
}

@include from1023andLower {
  .expired {
    grid-template-columns: 456px;
  }
}

@include from767andLower {
  .expired {
    grid-template-columns: 279px;
  }
}
