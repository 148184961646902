@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 913px !important;
  z-index: 13;
}

.btn {
  min-width: 178.5px;
}

.textWrapper {
  min-height: 52px;
}

.withdrawBottomSection {
  margin-top: 5px;
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .titleClassName {
    max-width: 250px;
  }

  .btn {
    min-width: 100%;
  }
}
