@import '../../../../../../constants/styles/breakpoints.constant.module';

.card {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  max-width: 33%;
}

.image {
  width: 100%;
  height: 150px;
  border-radius: 12px;
  object-fit: cover;
  margin-bottom: 14px;
  aspect-ratio: 2/1;
  object-position: top;
}

@include from767andLower {
  .card {
    max-width: 100%;
  }
}
