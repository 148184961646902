@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 912px !important;
  z-index: 13;
  padding: 24px;

  & > hr {
    margin-top: 8px;
  }
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.copy {
  cursor: pointer;
}

.description {
  margin-bottom: 24px;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;
  max-width: 100%;
}

.inputClassName {
  width: 100%;
}

.error {
  background-color: $color-error-text;
}

.aRecordWrapper {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dnsSettingsRecord {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  border-radius: 32px;
  background-color: $color-grey-4;
  margin: 12px 0px;
  overflow-wrap: anywhere;
}

.subTitle {
  display: block;
  padding: 12px 0px;
  font-weight: bold;
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.labelClassName {
  color: $color-primary-text;
}

.contentWrapper {
  margin-top: 24px;
  padding: 0px 0px 24px 0px;
  max-height: 60vh;
  overflow-y: scroll;
}

.errorClassName {
  margin-bottom: 0;
  height: 20px;
}

.text {
  margin-bottom: 16px;
  display: block;
}

.footer {
  margin-top: 24px;
  display: flex;

  .btnHover:hover {
    background-color: $color-white;
  }

  > button {
    width: 224px;
    height: 48px !important;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .dialogContentWrapperClassName {
    height: auto !important;
  }

  .description {
    margin: 0px;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .titleClassName {
    max-width: 250px;
  }

  .footer {
    flex-direction: column-reverse;

    & > button {
      min-width: 100%;
      width: 100%;
    }
  }
}
