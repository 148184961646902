@import 'constants/styles/colors.constant.module.scss';

.container {
  width: 100%;
  border-width: 1px 0 thin;
}

.verticalContainer {
  height: 100%;
  width: 1px;
  background: $color-divider;
}
