@import 'constants/styles/breakpoints.constant.module.scss';

.row {
  display: flex;
  gap: 12px;

  > * {
    flex: 1;
  }
}

.form {
  padding: 0 4px;
}

@include from767andLower {
  .row {
    flex-direction: column;
    gap: 0;
  }
}
