@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 890px !important;
  z-index: 13;
  padding: 24px !important;
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.contentWrapper {
  margin-top: 24px;
  margin-bottom: -6px;
}

.footerClassName {
  margin-top: 24px;
}

.btn {
  min-width: 224px;
  height: 48px !important;
}

.withdrawBottomSection {
  margin-top: 5px;
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .titleClassName {
    max-width: 250px;
  }

  .btn {
    min-width: 100%;
  }
}

@include from524andLower {
  .dialogContentWrapperClassName {
    height: calc(100% - 48px);
    display: grid;
    grid-template-rows: 51px auto 24px 72px;
  }

  .contentWrapper {
    overflow: auto;
  }
}

@media screen and (max-height: 480px) and (min-width: $bp524) {
  // 480px === 432(popup height) + 24 + 24)
  .dialogContentWrapperClassName {
    height: calc(100% - 48px);
    display: grid;
    grid-template-rows: 51px auto 24px 72px;
  }
  .contentWrapper {
    overflow: auto;
  }
}
