@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/animation.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';

.section {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.textBlock {
  display: flex;
  flex-direction: column;
  max-width: 460px;
  width: 100%;
}

.mainHeading {
  margin-top: 26px;
}

.text {
  margin-top: 32px;
}

.outerBoxClassName {
  max-width: 1019px;
  width: 100%;
  height: 100%;
  margin-left: 48px;
  max-height: 450px;
}

.innerBoxClassName {
  display: flex;
  padding: 43px;
  width: 100%;
  height: 100%;
  position: relative;
}

div.alignCenter {
  text-align: center;
  display: block;
}

div.italic {
  font-style: italic;
}

.listPoint {
  list-style: outside;
}

.listLatin {
  list-style: upper-latin outside;
}

.listLatin > .listItem::marker {
  font-family: $font-family-bold;
}

.link {
  text-decoration: none;
}

.listItem {
  margin-left: 48px;
}

div.textBase {
  text-align: justify;
}

.contractTextWrapper {
  text-align: justify;
  background-color: $color-white;
  box-shadow: 0px 2px 24px 12px rgba(108, 109, 110, 0.07);
  border-radius: 15px;
  width: 100%;
  height: auto;
  padding: 24px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(100px, 1fr) 50px;
  row-gap: 44px;
}

.textWrapper {
  text-align: justify;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding-right: 63px;
}

.jumper {
  background-color: $color-white;
  border: solid 1px $color-border-subdued-color;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 24px;
  bottom: 98px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: $color-white;
  width: 100%;
}

.arrow {
  width: 16px;
  transform: rotateX(180deg);
  transition: 0.2s transform;
}

.isBottom {
  transform: rotateX(0deg);
}

.acceptButton,
.skipButton {
  max-width: 167px;
}

.buttonsContainer {
  gap: 8px;
  display: flex;
  justify-content: flex-end;
  width: 384px;
}

@include from1919andLower {
  .outerBoxClassName {
    max-width: 891px;
  }

  .textBlock {
    max-width: 397px;
  }
}

@include from1439andLower {
  .section {
    flex-direction: column;
    justify-content: flex-start;
  }

  .outerBoxClassName {
    max-width: 100%;
    margin: 0;
    margin-top: 48px;
  }

  .textBlock {
    max-width: 728px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@include from767andLower {
  .jumper {
    bottom: 161px;
  }

  .outerBoxClassName {
    max-height: 554px;
  }

  .textBlock {
    max-width: 536px;
  }

  .contractTextWrapper {
    row-gap: 8px;
    grid-template-rows: minmax(100px, 1fr) 138px;
  }

  .innerBoxClassName {
    padding: 19px;
    width: 100%;
    height: 100%;
  }

  .textWrapper {
    padding-right: 0;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
  }

  .buttonsContainer {
    flex-direction: column-reverse;
    margin-top: 20px;
    width: 100%;
  }

  .acceptButton,
  .skipButton {
    max-width: 100%;
  }
}

.pointerText {
  cursor: pointer;
}
