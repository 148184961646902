@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';

.preview {
  width: 80%;
  background: $color-white;
  margin-right: 24px;
  border: 1px solid $color-white;
  border-radius: 20px;
  position: relative;
}

.loader {
  position: absolute;
  opacity: 0.1;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background: black;
  border-radius: 20px;
  z-index: 2;
}

.loaderClassName {
  left: 50% !important;
  top: 50% !important;
  position: absolute;
  z-index: 9999;
}

.hiddenLoader,
.hidden {
  display: none;
}

@include from1023andLower {
  .preview {
    margin-right: 10px;
  }
}

@include from767andLower {
  .preview {
    width: 100%;
    border-radius: 0;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 197px;
    border: 0;
    background-color: $color-primary-background;
  }
}
