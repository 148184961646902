@font-face {
  font-family: 'nunito-sans-300';
  src: url('./assets/font/nunito-sans/nunito-sans-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito-sans-400';
  src: url('./assets/font/nunito-sans/nunito-sans-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans Regular';
  src: url('./assets/font/nunito-sans/nunito-sans-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito-sans-600';
  src: url('./assets/font/nunito-sans/nunito-sans-semi-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito-sans-700';
  src: url('./assets/font/nunito-sans/nunito-sans-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans Bold';
  src: url('./assets/font/nunito-sans/nunito-sans-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito-sans-800';
  src: url('./assets/font/nunito-sans/nunito-sans-extra-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans Extra Bold';
  src: url('./assets/font/nunito-sans/nunito-sans-extra-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito-sans-900';
  src: url('./assets/font/nunito-sans/nunito-sans-black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'integral-cf-400';
  src: url('./assets/font/integral-cf/integral-cf-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'integral-cf-500';
  src: url('./assets/font/integral-cf/integral-cf-medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Integral CF Medium';
  src: url('./assets/font/integral-cf/integral-cf-medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'integral-cf-600';
  src: url('./assets/font/integral-cf/integral-cf-semi-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'integral-cf-700';
  src: url('./assets/font/integral-cf/integral-cf-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'integral-cf-800';
  src: url('./assets/font/integral-cf/integral-cf-extra-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'integral-cf-900';
  src: url('./assets/font/integral-cf/integral-cf-heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
