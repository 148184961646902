@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module';

.container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 16px;

    div.name {
      display: block;
    }
  }

  &NotLast {
    margin-bottom: 30px;
  }
}

div.lastActivity {
  flex: 1 0 auto;
}

.tooltipWrapper {
  flex-shrink: 1;
  max-width: 100%;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltipWrapper,
.tooltipTrigger {
  @extend .textEllipsis;

  div {
    @extend .textEllipsis;
  }
}

.tooltipTrigger {
  color: $color-text-900;
}

.tooltipBody {
  width: 100%;
  word-wrap: break-word;
}

@include from767andLower {
  .container {
    .row {
      flex-direction: column;
      gap: 4px !important;
    }
  }
}
