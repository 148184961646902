@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.dialogOverlay {
  z-index: 11;
}

.dialogContent {
  width: 913px !important;
  z-index: 13;
}

.dialogBody {
  margin-top: 16px;
}

.button {
  min-width: 178.5px;
  height: 48px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@include from1049andLower {
  .dialogContent {
    width: unset !important;
  }
}

@include from767andLower {
  .dialogBody {
    margin-top: 8px;
    max-height: 300px;
    overflow: scroll;
  }
}

@include from524andLower {
  .footer {
    flex-direction: column;

    .button {
      width: 100%;
    }
  }

  .dialogDescription {
    margin-top: 8px;
  }
}
