@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/animation.constant.module.scss';

.menu {
  width: 173px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  transition: $typography-transition-time transform;

  &:hover .dropDown,
  .arrowHover.dropDown {
    transform: rotateX(180deg);
    transition: $typography-transition-time transform;
  }
}

.userLogo {
  color: $color-white;
  height: 20px;
  width: 20px;
}

.dropDown {
  color: $color-white;
  height: 6px;
  width: 10px;
}

.menuText {
  margin: 0 5px;
}

.content {
  margin-top: 10px;
  width: 300px;
  z-index: 10 !important;
}

.unlink {
  text-decoration: none;
  color: $color-dark-blue;
  width: 100% !important;
  display: block;

  &:hover {
    text-decoration: none;
  }
}
