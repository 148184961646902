@import '../../fonts.module.scss';

// nunito-sans as main font
$font-family-light: 'nunito-sans-300', 'San Francisco', 'Segoe UI', 'Helvetica Neue',
  'Lucida Grande', 'Arial', sans-serif;

$font-family-regular: 'nunito-sans-400', 'San Francisco', 'Segoe UI', 'Helvetica Neue',
  'Lucida Grande', 'Arial', sans-serif;

$font-family-semi-bold: 'nunito-sans-600', 'San Francisco', 'Segoe UI', 'Helvetica Neue',
  'Lucida Grande', 'Arial', sans-serif;

$font-family-bold: 'nunito-sans-700', 'San Francisco', 'Segoe UI', 'Helvetica Neue',
  'Lucida Grande', 'Arial', sans-serif;

$font-family-extra-bold: 'nunito-sans-800', 'San Francisco', 'Segoe UI', 'Helvetica Neue',
  'Lucida Grande', 'Arial', sans-serif;

$font-family-black: 'nunito-sans-900', 'San Francisco', 'Segoe UI', 'Helvetica Neue',
  'Lucida Grande', 'Arial', sans-serif;

// integral-cf as secondary font

$font-family-regular-secondary: 'integral-cf-400', 'San Francisco', 'Segoe UI',
  'Helvetica Neue', 'Lucida Grande', 'Arial', sans-serif;

$font-family-medium-secondary: 'integral-cf-500', 'San Francisco', 'Segoe UI',
  'Helvetica Neue', 'Lucida Grande', 'Arial', sans-serif;

$font-family-semi-bold-secondary: 'integral-cf-600', 'San Francisco', 'Segoe UI',
  'Helvetica Neue', 'Lucida Grande', 'Arial', sans-serif;

$font-family-bold-secondary: 'integral-cf-700', 'San Francisco', 'Segoe UI',
  'Helvetica Neue', 'Lucida Grande', 'Arial', sans-serif;

$font-family-extra-bold-secondary: 'integral-cf-800', 'San Francisco', 'Segoe UI',
  'Helvetica Neue', 'Lucida Grande', 'Arial', sans-serif;

$font-family-black-secondary: 'integral-cf-900', 'San Francisco', 'Segoe UI',
  'Helvetica Neue', 'Lucida Grande', 'Arial', sans-serif;
