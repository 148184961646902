@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.list {
  display: flex;
}

.items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 24px;
  padding: 32px;
}

.item,
.itemValue {
  justify-content: flex-start;
  display: flex;
  flex: 1;
}

.listMobile {
  display: none;
}

.itemsMobile {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 32px 24px;
}

.itemMobile {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 8px;
  flex: 1;

  & > :first-child {
    white-space: nowrap;
  }
}

.verticalDivider {
  width: 1px;
  margin: 0 24px;
  background: $color-neutral-200;
}

.itemValue {
  font-size: 1.5rem;
}

@include from767andLower {
  .list {
    display: none;
  }

  .listMobile {
    display: flex;
  }

  .itemValue {
    font-size: 1rem;
  }
}
