@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/animation.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.section {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textBlock {
  display: flex;
  flex-direction: column;
  max-width: 460px;
  width: 100%;
}

.mainHeading {
  margin-top: 26px;
}

.outerBoxClassName {
  max-width: 1019px;
  width: 100%;
  margin-left: 48px;
  box-sizing: border-box;
}

.innerBoxClassName {
  padding: 43px;
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;

  // temporary here
  // grid-template-rows: auto auto 1fr;
  grid-template-rows: auto auto;

  row-gap: 20px;
  grid-template-columns: 100%;
}

.widget {
  background: $color-white;
  box-shadow: 0px 2px 24px 12px rgba(108, 109, 110, 0.07);
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
}

.widgetPriceRange {
  // temporary here
  // margin-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.tabsContent {
  min-height: 82px;
  box-sizing: border-box;
}

.disableLine {
  margin-top: 16px;
  background: $color-grey-4;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;

  & > div {
    z-index: 0;
  }
}

.categoriesRow {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  & > div {
    margin-right: 25px;
  }
}

.widgetProductsList {
  & > div {
    &:nth-child(1) {
      & > div {
        &:nth-child(2) {
          z-index: 2;
        }
      }
    }
  }
}

.acceptButton {
  max-width: 360px;
  width: 100%;
  margin: auto !important;
  border-color: $color-primary-purple;

  &:hover {
    border-color: $color-primary-purple;
  }
}

.tooltipWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.iconWrapper {
  margin-left: 6px;
  margin-bottom: 4px;
}

.tooltipText {
  width: 308px;
  height: auto;
}

.verticalDivider {
  width: 2px;
  height: 30px;
  background: $color-typography-body-greyed;
}

.verticalDividerToggles {
  @extend .verticalDivider;
}

.disableLine2 {
  margin-top: 14px;
  background: $color-grey-4;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
}

.disableLine2Toggles {
  @extend .verticalDivider;
}

.verticalDividerTablet {
  display: none;
}

.defaultSortingWrapper {
  width: 100%;
}

.defaultSortingSelect {
  margin-top: 26px;
}

@include from1919andLower {
  .outerBoxClassName {
    max-width: 891px;
  }
  .textBlock {
    max-width: 397px;
  }
}

@include from1439andLower {
  .textBlock {
    max-width: 728px;
    text-align: center;
  }

  .section {
    flex-direction: column;
  }

  .outerBoxClassName {
    margin-top: 48px;
    width: 976px;
    max-width: unset;
    margin-left: 0;
  }
}

@include from1023andLower {
  .textBlock {
    max-width: 536px;
  }

  .outerBoxClassName {
    max-width: 720px;
    width: 100%;
    margin-left: 0;
  }

  .verticalDivider {
    display: none;
  }

  .verticalDividerToggles {
    display: block;
  }

  .verticalDividerTablet {
    position: absolute;
    display: block;
    width: 1px;
    height: 80px;
    background: $color-typography-body-greyed;
    bottom: 44px;
  }

  .disableLine2 {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 41px;
    height: auto;
    box-sizing: border-box;
    padding: 22px 42.5px;

    & > div {
      width: 100%;

      & label {
        padding-right: 0 !important;
        width: 100%;
      }
    }
  }
}

@include from767andLower {
  .verticalDividerTablet {
    display: none;
  }

  .disableLine2 {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: 1fr;
    row-gap: 20px;
    column-gap: 41px;
    height: auto;
    box-sizing: border-box;
    padding: 22px 42.5px;
  }

  .innerBoxClassName {
    padding: 19px;
  }

  .tooltipText {
    width: 180px;
    height: auto;
  }

  .disableLine2Toggles {
    flex-direction: column;
    width: 100%;
    padding: 20px 13px;
    justify-content: space-between !important;
    height: 132px !important;
  }

  .verticalDividerToggles {
    display: none;
  }
}

@include from524andLower {
  .disableLine2 {
    padding: 22px 18px;
  }
}
