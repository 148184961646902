@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/single-container.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

$main-padding-top: 48px;
$main-padding-top-768: 32px;

.root {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-color: $color-primary-background;
  overflow: hidden;
}

.stars1 {
  position: fixed;
  left: 131px;
  bottom: 196px;
  width: 58px !important;
  height: 58px !important;
}

.stars2 {
  position: fixed;
  left: 600px;
  top: 329px;
  width: 90px;
  height: 90px;
}

.ring1 {
  position: fixed;
  top: 0px;
  left: 480px;
  width: 398px;
  height: 296px;
}

.ring2 {
  position: fixed;
  bottom: 30%;
  left: 0px;
  height: 395px;
  width: 223px;
}

.ring3 {
  position: fixed;
  bottom: 40px;
  left: 548px;
  height: 312px;
}

.headerWrapperClassName {
  max-width: $maxWidthContent1920;
  width: 100%;
  margin: 0;
}
.headerClassName {
  padding: 10px 48px;
  min-width: 100%;
  width: 100%;
}

.onboardContentWrapper {
  display: flex;
  justify-content: center;
  padding-top: $header-height;
  padding-bottom: 158px;
  height: 100%;
  min-height: calc(100vh - $header-height - $footer-height);
}

.onboardContent {
  padding-top: 140px;
  width: 100%;
  max-width: $maxWidthContent1920;
  display: grid;
  row-gap: 53px;
}

.outletWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  position: fixed;
  width: 100vw;
  padding: 24px 48px;
  bottom: 0;
  left: 0;
  background: $color-white;
  box-shadow: 0px 2px 24px 12px rgba(108, 109, 110, 0.07);
  z-index: 3;
  display: flex;
  justify-content: flex-end;
}

.footerContent {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: flex-end;
  max-width: $maxWidthContent1920;
  width: 100%;
}
.finishBtn {
  width: 224px;
  height: 48px;
}

.footerIndent {
  bottom: 48px;
}

@include from1919andLower {
  .onboardContent {
    max-width: $maxWidthContent1440;
  }

  .headerWrapperClassName {
    max-width: $maxWidthContent1440;
  }
  .footerContent {
    max-width: $maxWidthContent1440;
  }
}
@include from1439andLower {
  .onboardContent {
    max-width: $maxWidthContent1024;
    row-gap: 48px;
    padding-top: $main-padding-top;
  }

  .outletWrapper {
    align-items: flex-start;
  }

  .headerWrapperClassName {
    max-width: $maxWidthContent1024;
  }

  .headerClassName {
    padding: 10px 24px;
    min-width: 100%;
    width: 100%;
  }
  .footerContent {
    max-width: $maxWidthContent1024;
  }
}

@include from1023andLower {
  .onboardContent {
    max-width: $maxWidthContent768;
    padding-top: $main-padding-top-768;
    row-gap: 32px;
  }

  .headerWrapperClassName {
    max-width: $maxWidthContent768;
  }
  .footerContent {
    max-width: $maxWidthContent768;
  }

  .footer {
    padding: 24px;
  }
}

@include from767andLower {
  .onboardContent {
    max-width: $maxWidthContent525;
  }

  .headerWrapperClassName {
    max-width: $maxWidthContent525;
  }
  .withOneBtn {
    justify-content: flex-end;
  }

  button.prevBtn,
  button.nextBtn {
    width: 115px;
    margin: 0;
  }
  .footerContent {
    max-width: $maxWidthContent525;
  }
}

@include from524andLower {
  .onboardContent {
    max-width: $maxWidthContent375;
  }

  .headerWrapperClassName {
    max-width: $maxWidthContent375;
  }
  .finishBtn {
    width: 100% !important;
  }
}
