@import 'constants/styles/colors.constant.module';
@import 'constants/styles/breakpoints.constant.module.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.section {
  width: 100%;
  padding: 30px 40px;
  overflow: initial !important;
}

.underline {
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background-color: $color-grey-3;
}

.content,
.contentNoMargin {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.contentNoMargin {
  margin-top: 0px;
}

.header {
  display: flex;
  gap: 6px;
}

@include from767andLower {
  .section {
    padding: 32px 24px;
  }
}
