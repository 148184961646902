@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/animation.constant.module.scss';

.wrapperGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'column1 column2 column3';
  column-gap: 16px;
  row-gap: 16px;
}

.columnInnerClassName {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.columnFirst {
  grid-area: column1;
  outline: 1px solid $color-primary-background;
}

.columnSecond {
  grid-area: column2;
  outline: 1px solid $color-primary-background;
}

.columnThird {
  grid-area: column3;
}

.greyBlock {
  margin-bottom: 12px;
  background-color: $color-primary-background;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
}

.divider {
  margin: 12px 0 !important;
  width: 100%;
}

.contentRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftSideTitle {
  align-self: start;
}

.icons {
  font-size: 32px;
  margin-bottom: 10px;
}

.innerColumnThird {
  padding: 12px;
  background-color: $color-tabs-selection;
}

.swagSpaceGreyBlock {
  background-color: $color-light-blue;
}

.swagSpaceCostsBlock {
  height: 100%;
  justify-content: flex-start;
}

.iconsBlock {
  height: 153px !important;
  min-height: 153px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftSideText {
  max-width: 156px;
}

@include from1023andLower {
  .wrapperGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      '. column3  column3 .'
      'column1 column1  column2 column2';
  }
}

@include from767andLower {
  .wrapperGrid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'column3'
      'column2'
      'column1';
  }

  .preOrderBreak {
    display: none;
  }
}
