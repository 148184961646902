@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 913px !important;
  z-index: 13;
}

.popupView {
  /* not empty */
}

.withdrawSectionWrapper {
  padding: 43px 185px;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}

.btn {
  min-width: 178.5px;
}

.textWrapper {
  min-height: 52px;
}

.errorText {
  justify-content: center;
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.withdrawSectionClassName {
  background-color: transparent;
  padding: 0 24px;
  height: unset;
  white-space: nowrap;

  &:nth-child(1) {
    padding-left: 0;
  }
  &:nth-child(3) {
    padding-right: 0;
  }
}

.withdrawBottomSection {
  margin-top: 5px;
}

@include from1439andLower {
  .withdrawSectionWrapper {
    display: flex;
  }
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .withdrawSectionWrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
  }

  .titleClassName {
    max-width: 250px;
  }

  .withdrawSectionClassName {
    width: auto;
    min-width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-left: unset !important;
    border-right: unset !important;
    margin: 10px 0;
  }
}
