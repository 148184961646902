@import 'constants/styles/colors.constant.module';
@import 'constants/styles/breakpoints.constant.module.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.section {
  width: 100%;
  padding: 30px 40px;
}

.toggleClassName {
  margin: 0;

  &:first-child {
    margin-right: 20px;
  }

  &:last-child {
    margin-left: 20px;
  }
}

.togglesLine {
  margin-top: 30px;
  background: #fafafa;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
}

.underline {
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background-color: $color-grey-3;
}

.underlineNoMargin {
  width: 100%;
  height: 1px;
  background-color: $color-grey-3;
}

.content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 29.5px;
}

.tooltipText {
  width: 308px;
  height: auto;
}

div.header {
  display: flex;
}

.pricePointsText {
  margin-right: 6.5px;
}

.icon {
  height: 17px !important;
  width: 17px !important;
}

.widgetPriceRangeClassName,
.widgetProductsListClassName {
  width: 100%;
  padding: 0;
  box-shadow: none;
}

.disableLineClassName {
  margin-top: 30px;
}

.verticalDividerTabletClassName {
  bottom: 24px;
}

.toggleText {
  max-width: 400px;
}

.toggle {
  margin: 0 !important;
}

.toggleContainer {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.toggleLabel {
  font-weight: 800 !important;
}

.input {
  outline: none;
  width: 20px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  -moz-appearance: textfield;
  text-align: end;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.tooltipTextInputTrigger {
  margin-bottom: 4px;
}

.tooltipBody {
  width: 315px;
}

.tooltipTextInput {
  height: auto;
}

.lowMarginContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.hideButton {
  width: 224px !important;
  padding: 11px 16px !important;
  height: 48px !important;
}

.calculationsContainer {
  flex: 1;
  background-color: $color-primary-background;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.calculationsResults {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.calculationsResultItem {
  display: flex;
  gap: 4px;
}

.marginContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin: 30px 0 16px;
  flex-wrap: wrap;
}

.centerAlignedContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.inputWrapper {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.fakeWrapper {
  display: flex;
  justify-content: space-between;
}

.label {
  padding: 0 16px;
}

.fakeInput {
  background-color: $color-grey-3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 11px 20px;
  border-radius: 32px;
}

div.errorText {
  width: 100%;
  bottom: -24px;
  position: absolute;
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 300ms;
  transition-delay: 150ms;
}

.hideSectionFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.visibleOverflow {
  overflow: visible;
}

.openDealComparisonPopupButton {
  min-width: 224px;
}

@include from767andLower {
  .inputWrapper {
    width: 100%;
  }

  .disableLine2ClassName {
    padding: 22px 18px;
  }

  .marginContainer {
    flex-direction: column;
    gap: 16px;
  }

  .calculationsResults {
    gap: 12px;
  }

  .toggleContainer {
    gap: 12px;
  }

  .demoBtn {
    width: 100% !important;
  }

  .tooltipText {
    width: 208px;
    height: auto;
  }

  .calculationsResultItem {
    justify-content: space-between;
    width: 100%;
  }

  .hideButton {
    width: 100% !important;
  }

  .hideSectionFooter {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
    align-items: flex-start;
  }

  .toggleClassName {
    margin: 0;

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
    }
  }

  .openDealComparisonPopupButton {
    min-width: 100%;
  }
}
