@import 'constants/styles/breakpoints.constant.module.scss';

.title {
  margin-bottom: 0;
}

.block {
  padding: 24px 32px;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

.innerRow {
  @extend .row;
  margin-bottom: 0;
}

div.edit {
  position: absolute !important;
  right: 0;
  top: 0;
  white-space: nowrap;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.smallColumn {
  @extend .column;
  max-width: 141px;
}

.bigColumn {
  @extend .column;
  width: calc(100% - 93px - 48px);
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    text-decoration: none;
  }
}

.radio {
  margin-right: 21px;
}

@include from767andLower {
  .row {
    flex-direction: column;
  }
  .smallColumn {
    margin-bottom: 24px;
  }
}
