@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.titleClassName {
  line-break: anywhere;
}

.dialogContentWrapperClassName {
  width: 912px !important;
  z-index: 13;
  padding: 0;
  max-height: 636px;
  height: 80vh;
  display: grid;
  grid-template-rows: auto 2fr 1px 96px;

  & > hr {
    margin: 0 24px;
  }

  & > h2 {
    padding: 24px 24px 0;
  }

  & > p {
    padding-left: 24px;
    padding-right: 24px;
  }

  & > div {
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.pre {
  overflow: hidden;
  white-space: pre-line;
  word-break: break-word;
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.contentWrapper {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  overflow-y: scroll;
}

.errorClassName {
  margin-bottom: 0;
  height: 20px;
}

.text {
  display: block;
}

.footer {
  margin-top: 24px;
  display: flex;
  > button {
    width: 224px;
    height: 48px !important;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.triggerClassName {
  padding: 0 !important;
}

.contentClassName {
  padding: 0 !important;
}

.firstGeneralNote {
  display: grid;
  grid-template-areas:
    'label date'
    'text accordion';
  grid-template-columns: 1fr 160px;
  grid-template-rows: 24px 1fr;
  width: 100%;
  column-gap: 16px;
  row-gap: 8px;
}

.generalNote {
  display: grid;
  grid-template-areas: 'otherGeneralNote otherGeneralDate';
  grid-template-columns: 1fr 160px;
  grid-template-rows: 1fr;
  width: 100%;
  column-gap: 10px;
}

.generalNoteDate {
  grid-area: otherGeneralDate;
  justify-self: flex-end;
  align-self: center;
}

.newestNoteLabelFirstGeneral {
  grid-area: label;
}

.firstGeneralNoteDate {
  grid-area: date;
  justify-self: flex-end;
  align-self: center;
}

.firstGeneralNoteAccordion {
  grid-area: accordion;
  justify-self: flex-end;
  align-self: flex-end;
}

.firstGeneralNoteText {
  grid-area: text;
}

.whiteBox {
  margin-top: 16px;
  padding: 16px;
}

.newestNoteLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: $color-light-blue;
  width: 99px;
  height: 24px;
  margin-bottom: 10px;
}

.chevron {
  transition: 0.2s transform;
}

.chevronDown {
  transform: rotate(180deg);
}

.accordion {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.accordionItem {
  border-bottom: unset !important ;
  border-top: unset !important;
}

.chevronClassName {
  display: none;
}

.divider {
  display: flex;
  width: 100%;
  height: 1px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: $color-divider;
  margin: 16px 0;
}

.firstIndividualProductNote {
  display: grid;
  grid-template-areas:
    'individualImg individualName individualDate'
    'individualImg individualText individualAcc';
  grid-template-columns: 46px 1fr 160px;
  grid-template-rows: 26px 1fr;
  column-gap: 16px;
  row-gap: 4px;
  width: 100%;
}

.firstIndividualProductNewestNoteLabel {
  align-self: center;
  margin-bottom: 0;
  position: absolute;
  right: 0;
  top: 1px;
}

.individualProductImage {
  grid-area: individualImg;
  width: 46px;
  height: 56px;
  object-fit: contain;
}

.firstIndividualProductName {
  grid-area: individualName;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  padding-right: 107px;
  position: relative;
  max-width: fit-content;

  & > .newestNoteLabel {
    margin-bottom: 0;
  }
}

.firstIndividualProductDate {
  grid-area: individualDate;
  justify-self: flex-end;
  align-self: center;
}

.firstIndividualProductText {
  grid-area: individualText;
}

.firstIndividualProductAccordion {
  grid-area: individualAcc;
  justify-self: flex-end;
  align-self: flex-end;
}

.individualProductNote {
  display: grid;
  grid-template-areas: 'dummy otherIndividualNote otherIndividualDate';
  grid-template-columns: 46px 1fr 160px;
  grid-template-rows: 1fr;
  width: 100%;
  column-gap: 16px;
}

.individualProductNote {
  grid-area: otherIndividualNote;
}

.individualProductDate {
  grid-area: otherIndividualDate;
  justify-self: flex-end;
  align-self: center;
}

.sectionWithPadding {
  padding-top: 16px;
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .dialogContentWrapperClassName {
    height: 80vh !important;
    max-height: 80vh;
    grid-template-rows: auto 2fr 1px 80px;

    & > hr {
      margin: 0 16px;
    }

    & > h2 {
      padding: 16px 16px 0;
    }

    & > p {
      padding: 16px !important;
    }

    & > div {
      padding-bottom: 16px;
      margin-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .firstGeneralNote {
    grid-template-areas:
      'label'
      'date'
      'text'
      'accordion';
    grid-template-columns: 1fr;
    grid-template-rows: 24px 20px 1fr 32px;
    column-gap: 10px;
    width: 100%;
    row-gap: 8px;
  }

  .firstGeneralNoteSingle {
    grid-template-areas:
      'label'
      'date'
      'text';
    grid-template-columns: 1fr;
    grid-template-rows: 24px 20px 1fr;
    column-gap: 10px;
    width: 100%;
    row-gap: 8px;
  }

  .generalNote {
    grid-template-areas:
      'otherGeneralDate'
      'otherGeneralNote';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    column-gap: 10px;
    width: 100%;
    row-gap: 8px;
  }

  .firstGeneralNoteAccordion,
  .generalNoteDate,
  .firstGeneralNoteDate {
    justify-self: flex-start;
  }

  .generalNoteDate,
  .firstGeneralNoteDate,
  .firstGeneralNoteAccordion {
    align-self: center;
  }

  .firstIndividualProductNote {
    grid-template-areas:
      'individualImg individualName'
      'individualDate individualDate'
      'individualText individualText'
      'individualAcc individualAcc';
    grid-template-columns: 46px 1fr;
    grid-template-rows: 56px 20px 1fr 32px;
    width: 100%;
    column-gap: 16px;
    row-gap: 8px;
  }

  .firstIndividualProductNoteSingle {
    grid-template-areas:
      'individualImg individualName'
      'individualDate individualDate'
      'individualText individualText';
    grid-template-columns: 46px 1fr;
    grid-template-rows: 56px 20px 1fr;
    width: 100%;
    column-gap: 16px;
    row-gap: 8px;
  }

  .firstIndividualProductName {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 0.875rem !important;
    line-height: 142.857% !important;
    padding-right: unset;
  }

  .firstIndividualProductNewestNoteLabel {
    position: static;
    align-self: unset;
    margin-bottom: 4px !important;
  }

  .firstIndividualProductDate {
    align-self: center;
    justify-self: flex-start;
  }

  .firstIndividualProductAccordion {
    grid-area: individualAcc;
    justify-self: flex-start;
    align-self: center;
  }

  .individualProductNote {
    grid-template-areas:
      'otherIndividualDate'
      'otherIndividualNote';
    grid-template-columns: 1fr;
    width: 100%;
    grid-template-rows: 20px 1fr;
    column-gap: 16px;
    row-gap: 8px;
  }

  .individualProductText {
    grid-area: otherIndividualNote;
  }

  .individualProductDate {
    grid-area: otherIndividualDate;
    justify-self: flex-start;
    align-self: center;
    align-self: center;
  }

  .contentWrapper {
    max-height: unset;
  }

  .dummy {
    display: none;
  }

  .titleClassName {
    line-break: auto;
    word-break: break-word;
  }

  .newestNoteLabel {
    margin-bottom: 0px;
  }

  .divider {
    margin: 8px 0;
  }

  .footer {
    flex-direction: column-reverse;

    & > button {
      min-width: 100%;
      width: 100%;
    }
  }
}
