@mixin indexColumn {
  width: 40px;
  margin-right: 12px;
}

@mixin flexCellsExcludingIndex {
  &:not(.number) {
    flex: 1;
  }
}
