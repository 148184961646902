@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module';

.dialogContentWrapperClassName {
  position: fixed;
  z-index: 13;
  background-color: $color-primary-background;
  display: flex;
  flex-direction: column;
}

.dialogContentWrapperClassName + div {
  z-index: 13 !important;

  & > div {
    box-shadow: none;
  }
}

.dialogOverlayClassName {
  z-index: 11;
}

.dropzoneHelperText {
  margin: 4px 16px !important;
  margin-bottom: 0 !important;
}

.content {
  gap: 48px;
  display: flex;
  padding: 25px 0 0 0;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  flex-direction: row;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 2px solid $color-neutral-200;

  .buttonContainers {
    display: flex;
    align-items: center;
    gap: 8px;

    .button {
      width: 192px !important;
    }
  }

  .headerMobileRoundButton {
    display: none;
  }
}

.pagesSidebarPagesContainer {
  position: relative;
  height: 100%;
  overflow-y: scroll;
}

.pagesSidebarLoader {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: $color-white;
  opacity: 0.5;
}

.pagesSidebar,
.contentSidebar {
  position: relative;
  width: 381px;
  height: 100%;
  min-height: 826px;
  display: flex;
  background-color: $color-white;
  box-shadow: $box-shadow-deep;
  border-radius: 16px;
  flex-direction: column;

  .pagesSidebarPages {
    gap: 16px;
    display: flex;
    flex-direction: column;
    padding: 25px 30px;
    height: 100%;
  }

  .pagesSidebarHeader,
  .contentSidebarHeader {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    box-shadow: $box-shadow-deep;
    border-radius: 16px;

    .pagesSidebarHeaderText,
    .contentSidebarHeaderText {
      margin: 30px;
      height: 16px !important;
    }

    .contentSidebarHeaderTextWithoutHide {
      margin: 30px;
      height: 16px !important;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .contentSidebarHeaderText {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .contentSidebarHeader {
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .contentSidebarFields {
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &Hidden {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.pagesViewer {
  align-self: stretch;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.previewSectionDocument {
  z-index: 0 !important;
  line-height: 0;
}

.previewSectionPage,
.previewSectionPage > img {
  border-radius: 13px;
  width: inherit;
  height: inherit;
  line-height: 0;
}

.previewSection {
  position: relative;
  flex: 1 1 auto;
  height: 100%;
  gap: 24px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;

  .previewSectionPageWrapper {
    position: relative;
    border-radius: 16px;
    width: auto;
    height: auto;
    min-width: 1002px;
    min-height: 750px;
    box-shadow: $box-shadow-deep;
    background-color: $color-white;

    .previewSectionOverflow {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    .previewSectionHidden {
      position: sticky;
      border-radius: 13px;
      left: 0;
      top: 0;
      z-index: 20;
      background-color: $color-primary-background;
      opacity: 0.5;
      width: 100%;
      height: 100%;
    }

    .previewSectionLoader {
      position: sticky !important;
      border-radius: 13px !important;
      left: 0 !important;
      top: 0 !important;
      opacity: 0.5 !important;
      z-index: 20;
      background-color: $color-primary-background;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.pagesSidebarMobile {
  z-index: 50;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -24px;
  transform: translateY(52px);

  .pagesSidebarPagesContainer {
    position: relative;
    overflow-y: hidden;
  }

  .pagesSidebarPages {
    margin: 16px 0;
    gap: 16px;
    display: flex;
    flex-direction: row;
    height: calc(210px + 16px * 2);
    padding: 0;
    max-height: 100%;
    width: 100%;
  }

  .pagesSidebarMobileContent {
    box-shadow: $box-shadow-deep;
    width: 100%;
    border-radius: 16px;
    padding: 16px;
    background-color: $color-white;

    .pagesSidebarMobileHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;

      .pagesSidebarMobileChevron {
        width: 20px;
        height: 20px;
        transition: all 0.3s ease-in-out;

        &Opened {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.pagePreviewFilterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  .pagePreviewFilterPage {
    position: relative;
    width: 281px;
    height: 210px;
    box-sizing: content-box;
    border-radius: 16px;
    box-shadow: $box-shadow-deep;
    flex-shrink: 0;
    overflow: hidden;

    &Selected:before {
      content: '';
      border: 3px solid $color-interactive;
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      border-radius: 16px;
    }

    &Hidden {
      :before {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: $color-white;
        opacity: 0.5;
        content: ' ';
        border-radius: 16px;
      }
    }

    &Hidden.pagePreviewFilterPageSelected {
      :before {
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
      }
    }
  }
}

.sidebarButtonGroup {
  display: none;
}

.multipleImageUploader {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .multipleImageUploaderRow {
    display: flex;
    flex-direction: row;
    gap: 12px;
    max-width: 100%;

    .dropzoneWrapper {
      flex: 1 1 0;
      max-width: calc(50% - 12px);
      height: 100%;
    }

    .dropzoneLabel {
      margin: 0 16px;
    }

    .uploadWrapperClassname {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }

    .dropzoneButton {
      padding: 6px 16px;
      height: 32px;
      width: unset !important;
      max-width: 100%;

      & > div {
        font-size: 14px;
      }
    }

    .dropzonePreview {
      flex: 1;
      justify-content: space-between;

      img {
        max-height: 100px;
        max-width: 130px;
        margin-bottom: 10px;
      }
    }

    .dropzoneUploadTextWrapper {
      text-align: center;
    }

    .dropzonePreviewButtons {
      flex-direction: column;
      gap: 8px;
    }

    .dropzone {
      width: 100%;
      height: 100%;
      min-height: 250px;
      display: flex;
      flex-direction: column;
      margin-top: 4px;
      padding: 16px;
    }
  }
}

.singleImageUploaderWithExample,
.reviewContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .dropzoneWrapper {
    flex: 1 1 0px;
    height: 100%;
  }

  .label {
    margin: 0 16px;
  }

  .uploadWrapperClassname {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .dropzoneButton {
    padding: 6px 16px;
    height: 32px;
    max-width: 100%;
    width: unset;

    & > div {
      font-size: 14px;
    }
  }

  .dropzonePreview {
    img {
      max-width: 150px;
      margin-bottom: 10px;
    }
  }

  .dropzoneUploadTextWrapper {
    text-align: center;
  }

  .dropzonePreviewButtons {
    flex-direction: column;
    gap: 8px;
  }

  .dropzone {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 4px;
  }

  .exampleImageContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    img {
      width: 100%;
      border-radius: 16px;
      aspect-ratio: 107/70;
      object-fit: cover;
      border: 1px dashed $color-white;
    }
  }
}

.reviewContainer {
  margin-top: 16px;
}

.thankYouForm {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.discardButton {
  background-color: $color-primary-background;
}

.tabsContainer {
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 0;
  box-shadow: none;
}

.tabsMobileContainer {
  display: none;
  min-width: 100%;
  justify-content: center;
}

.tabItem {
  border-radius: 100px;
  padding: 8px 16px;
  margin-right: 24px;
  height: 40px;
  white-space: nowrap;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
}

.mobileTrigger {
  width: 100%;
  justify-content: center;
}

.blockedPaginationEvent {
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #edeeef;
}

@include from1220andBigger {
  .pagesSidebarMobile {
    display: none;
  }
}

@include from1919andLower {
  .content {
    gap: 12px;
  }

  .previewSection {
    .previewSectionPageWrapper {
      min-width: 897px;
      min-height: 712px;
    }
  }

  .pagesSidebar,
  .contentSidebar {
    width: 392px;
    min-height: 712px;
  }

  .pagePreviewFilterContainer {
    .pagePreviewFilterPage {
      width: 278px;
      height: 208px;
      border-radius: 19px;

      &Selected:before {
        border: 2px solid $color-interactive;
        border-radius: 19px;
      }
    }
  }
}

@include from1819andLower {
  .content {
    gap: 12px;
  }

  .previewSection {
    .previewSectionPageWrapper {
      min-width: 897px;
      min-height: 672px;
    }
  }

  .pagesSidebar,
  .contentSidebar {
    width: 369px;
    min-height: 667px;
  }

  .pagePreviewFilterContainer {
    .pagePreviewFilterPage {
      width: 262px;
      height: 196px;
    }
  }

  div.tabsMobileContainer {
    display: flex;
    cursor: pointer;
    background-color: $color-tabs-selection;
    border-radius: 100px;
    padding: 8px 16px;
    height: 40px;

    & > div {
      display: flex;
      padding: 0 8px;
      align-items: center;
    }
  }

  div.mobileDropdownItem {
    border-radius: 8px;
    padding: 13px 8px;
    width: 353px;
    margin: 2px 0;
    font-size: 1rem;
  }

  .mobileTrigger {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 100px;
  }

  .arrow {
    margin-left: 7px;
  }

  .tabItem {
    display: none;
  }
}

@include from1719andLower {
  .previewSection {
    .previewSectionPageWrapper {
      min-width: 845px;
      min-height: 634px;
    }
  }

  .pagesSidebar,
  .contentSidebar {
    width: 345px;

    .contentSidebarFields {
      padding: 7px 16px 16px;
    }

    .contentSidebarHeader,
    .pagesSidebarHeader {
      .contentSidebarHeaderTextWithoutHide {
        margin: 24px 16px 24px;
        height: initial !important;
      }

      .contentSidebarHeaderText {
        flex-direction: column;
        align-items: flex-start;
        margin: 30px 30px 18px;
        height: 60px !important;
      }
    }
  }

  .pagePreviewFilterContainer {
    .pagePreviewFilterPage {
      width: 247px;
      height: 185px;
    }
  }

  .multipleImageUploader {
    margin-top: 16px;

    .multipleImageUploaderRow {
      flex-direction: column;

      .dropzoneWrapper {
        max-width: 100%;
      }
    }
  }

  div.mobileDropdownItem {
    width: 328px;
  }
}

@include from1619andLower {
  .previewSection {
    .previewSectionPageWrapper {
      min-width: 793px;
      min-height: 594px;
    }
  }

  .pagesSidebar,
  .contentSidebar {
    width: 321px;

    .contentSidebarHeader {
      .contentSidebarHeaderTextWithoutHide {
        margin: 30px;
        height: 16px !important;
      }

      .contentSidebarHeaderText {
        flex-direction: column;
        align-items: flex-start;
        margin: 30px 30px 18px;
        height: 60px !important;

        & > div {
          white-space: nowrap;
        }
      }
    }
  }

  .pagePreviewFilterContainer {
    .pagePreviewFilterPage {
      width: 224px;
      height: 168px;
    }
  }

  .multipleImageUploader {
    margin-top: 16px;

    .multipleImageUploaderRow {
      flex-direction: column;

      .dropzoneWrapper {
        max-width: 100%;
      }
    }
  }

  div.mobileDropdownItem {
    width: 305px;
  }
}

@include from1519andLower {
  .previewSection {
    .previewSectionPageWrapper {
      min-width: 748px;
      min-height: 561px;
    }
  }

  .pagesSidebar,
  .contentSidebar {
    width: 294px;
  }

  .pagePreviewFilterContainer {
    flex-direction: column-reverse;

    .pagePreviewFilterPage {
      width: 206px;
      height: 155px;
    }
  }

  div.mobileDropdownItem {
    width: 278px;
  }
}

@include from1419andLower {
  .previewSection {
    .previewSectionPageWrapper {
      min-width: 714px;
      min-height: 535px;
    }
  }

  .pagesSidebar,
  .contentSidebar {
    width: 256px;
  }

  .pagePreviewFilterContainer {
    .pagePreviewFilterPage {
      width: 193px;
      height: 144px;
    }
  }

  div.mobileDropdownItem {
    width: 240px;
  }

  .tabsContainer {
    padding: 16px 20px;
  }

  .singleImageUploaderWithExample,
  .reviewContainer {
    .label {
      margin: 0;
    }
  }
}

@include from1319andLower {
  .previewSection {
    .previewSectionPageWrapper {
      min-width: 646px;
      min-height: 484px;
    }
  }

  .pagesSidebar,
  .contentSidebar {
    width: 240px;

    .contentSidebarHeader {
      .contentSidebarHeaderText {
        margin: 30px 26px 18px;
      }
    }
  }

  .pagePreviewFilterContainer {
    .pagePreviewFilterPage {
      width: 178px;
      height: 133px;
    }
  }

  .multipleImageUploader {
    margin-top: 16px;

    .multipleImageUploaderRow {
      flex-direction: column;

      .dropzoneWrapper {
        max-width: 100%;
      }
    }
  }

  div.mobileDropdownItem {
    width: 224px;
  }
}

@include from1219andLower {
  :global(.react-pdf__Page__canvas) {
    border-radius: 8px;
  }

  div.tabsMobileContainer {
    display: none;
  }

  div.tabsContainer {
    justify-content: flex-start;
    padding: 0;
  }

  div.tabItem {
    display: flex;
  }

  .pagesSidebar {
    display: none;
  }

  .pagesSidebarPagination {
    display: none;
  }

  .pagesSidebarLoader {
    position: sticky;
    top: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 50;
    background-color: $color-white;
    opacity: 0.5;
  }

  .dialogContentWrapperClassName {
    padding: 24px;
  }

  .content {
    gap: 12px;
  }

  .pagesSidebarPagesContainer {
    overflow-y: hidden;
  }

  .pagesSidebarMobile {
    .pagesSidebarPagesContainer {
      height: calc(92px + 16px * 2);
    }

    .pagesSidebarPages {
      height: 92px;
    }
  }

  .previewSectionPage,
  .previewSectionPage > img {
    border-radius: 8px;
  }

  .contentSidebar {
    width: 295px;
  }

  .pagePreviewFilterContainer {
    flex-direction: column-reverse;
    gap: 8px;

    .pagePreviewFilterPage {
      width: 80.4px;
      height: 60px;
      border-radius: 8px;

      &Selected:before {
        border: 2px solid $color-interactive;
        border-radius: 8px;
      }

      &Hidden {
        :before {
          border-radius: 8px;
        }
      }
    }
  }

  .previewSection {
    .previewSectionPage {
      border-radius: 16px;
    }

    .previewSectionPageWrapper {
      min-width: 657px;
      min-height: 492px;
    }
  }

  .pagesSidebar,
  .contentSidebar {
    min-height: 568px;

    .contentSidebarHeader {
      .contentSidebarHeaderText {
        margin: 30px 30px 18px;
      }
    }
  }
}

@include from1023andLower {
  .contentSidebar {
    width: 295px;
  }

  .content {
    gap: 24px;
  }

  .dialogContentWrapperClassName {
    position: absolute;
    bottom: -45%;
    left: 0;
    padding: 24px;
  }

  .pagesSidebarMobile {
    padding: 0;
    bottom: 0;
    transform: translateX(0);

    .pagesSidebarPagesContainer {
      height: calc(68px + 16px * 2);
    }

    .pagesSidebarPages {
      height: 68px;
    }

    .pagesSidebarMobileContent {
      .pagesSidebarMobileHeader {
        display: none;
      }
    }
  }

  .pagePreviewFilterContainer {
    flex-direction: column-reverse;
    gap: 8px;

    .pagePreviewFilterPage {
      width: 53.6px;
      height: 40px;
      border-radius: 8px;

      &Selected:before {
        border: 2px solid $color-interactive;
        border-radius: 8px;
      }

      &Hidden {
        :before {
          border-radius: 8px;
        }
      }
    }
  }

  .previewSection {
    .previewSectionPageWrapper {
      min-width: 401px;
      min-height: 292px;
    }
  }

  .pagesSidebar,
  .contentSidebar {
    min-height: 292px;
  }
}

@include from767andLower {
  .dialogContentWrapperClassName {
    padding: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 95%;
  }

  .contentSidebar {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
    padding: 0;
  }

  .header {
    padding: 16px 24px;

    .headerMobileRoundButton {
      display: flex !important;
      padding: 10px !important;
      height: auto !important;

      .headerMobileRoundButtonText {
        line-height: 0 !important;
      }
    }

    .buttonContainers {
      display: none;
    }
  }

  .pagesSidebarMobile {
    .pagesSidebarPagesContainer {
      height: calc(88px + 16px * 2);
    }

    .pagesSidebarPages {
      height: 88px;
    }
  }

  .pagePreviewFilterContainer {
    flex-direction: column-reverse;
    gap: 8px;

    .pagePreviewFilterPage {
      width: 80.4px;
      height: 60px;
      border-radius: 8px;

      &Selected:before {
        border: 2px solid $color-interactive;
        border-radius: 8px;
      }
    }
  }

  .hidden {
    display: none;
  }

  .pagesSidebarMobile {
    bottom: 16px;

    .pagesSidebarMobileContent {
      border-radius: 0;
    }
  }

  .contentSidebar {
    .contentSidebarFields {
      flex: 1;
    }

    .sidebarButtonGroup {
      width: 100wh;
      padding: 16px 40px;
      margin-bottom: 24px;
    }
  }

  .sidebarButtonGroup {
    background-color: $color-primary-background;
    width: calc(100% + 32px);
    margin-left: -16px;
    padding: 16px 24px 8px 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;

    button {
      height: 48px;
      padding: 11px 16px;
    }
  }

  .previewSection {
    .previewSectionPageWrapper {
      min-width: 375px;
      min-height: 273px;
    }
  }

  .pagesSidebar,
  .contentSidebar {
    min-height: 273px;

    .contentSidebarHeader {
      .contentSidebarHeaderTextWithoutHide {
        margin: 24px 16px 24px;
        height: initial !important;
        flex-direction: row;
      }

      .contentSidebarHeaderText {
        align-items: center;
        margin: 24px 16px 24px;
        height: initial !important;
      }
    }
  }
}
