@import 'constants/styles/breakpoints.constant.module.scss';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.editor {
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.button {
  width: 224px !important;
  padding: 11px 16px !important;
  height: 48px !important;
}

.hidden {
  display: none;
}

.loaderWrapper {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@include from767andLower {
  .buttons {
    flex-direction: column;
    align-items: center;
  }
  .button {
    width: 100% !important;
  }
}
