@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 913px;

  z-index: 13;
  padding: 24px;
  hr {
    margin-top: 24px;
  }
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.btn {
  width: 224px;
  height: 48px;
}

.textWrapper {
  min-height: 52px;
  margin-top: 24px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
}

@include from1023andLower {
  .dialogContentWrapperClassName {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: $maxWidthContent768;
  }

  .textWrapper {
    max-height: 60vh;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
}

@include from767andLower {
  .dialogContentWrapperClassName {
    max-width: $maxWidthContent525;
  }

  .titleClassName {
    max-width: 250px;
  }

  .btn {
    min-width: 178.5px;
  }
}

@include from524andLower {
  .dialogContentWrapperClassName {
    max-width: $maxWidthContent375;
  }

  .btn {
    width: 100%;
  }
}
