@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
  padding: 0px;
  margin: 0px;
}

.dialogContentWrapperClassName {
  width: 913px !important;
  z-index: 13;
  padding: 24px;
}

.title {
  margin-bottom: 24px;
}

.btn {
  width: 224px;
  height: 48px;
  margin-left: 5px;
}

.textWrapper {
  padding: 24px 0px;
  min-height: 52px;
  margin: 0px;
}
.controlButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0px;
}

.divider {
  margin: 0px;
}

.footer {
  margin-top: 24px;
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .titleClassName {
    max-width: 250px;
  }
  .controlButtons {
    flex-direction: column;
  }
  .btn {
    min-width: 100%;
    margin-bottom: 10px;
  }
}
