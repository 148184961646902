@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/animation.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.section {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textBlock {
  display: flex;
  flex-direction: column;
  max-width: 460px;
  width: 100%;
}

.mainHeading {
  margin-top: 26px;
}

.text {
  margin-top: 32px;
}

.outerBoxClassName {
  max-width: 1019px;
  width: 100%;
  margin-left: 48px;
}

.innerBoxClassName {
  padding: 43px;
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
}

.secondaryColorPickerWrapperClassName,
.primaryColorPickerWrapperClassName {
  top: -100px !important;
  z-index: 7 !important;
  left: 50% !important;

  & > div {
    overflow: hidden !important;
  }
}

.colorSelector {
  padding: 35px;
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@include from1919andLower {
  .outerBoxClassName {
    max-width: 891px;
  }

  .textBlock {
    max-width: 397px;
  }
}

@include from1439andLower {
  .textBlock {
    max-width: 728px;
    text-align: center;
  }

  .section {
    flex-direction: column;
  }

  .outerBoxClassName {
    margin-top: 48px;
    width: 976px;
    max-width: unset;
    margin-left: 0;
  }

  .innerBoxClassName {
    & > div {
      width: 100%;
    }
  }
}

@include from1023andLower {
  .textBlock {
    max-width: 536px;
  }

  .outerBoxClassName {
    max-width: unset;
    width: 720px;
    margin-left: 0;
  }

  .innerBoxClassName {
    & > div {
      width: 300px !important;
    }
  }

  .secondaryColorPickerWrapperClassName,
  .primaryColorPickerWrapperClassName {
    left: 0% !important;
  }
}

@include from767andLower {
  .outerBoxClassName {
    width: 477px;
    max-width: unset;
    margin-left: 0;
  }

  .innerBoxClassName {
    padding: 19px;
    position: relative;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    column-gap: 24px;
    row-gap: 24px;
  }

  .innerBoxClassName {
    & > div {
      width: 100% !important;
      padding: 32px;
      height: auto;
      min-height: 318px;
    }
  }

  .primaryColorPickerWrapperClassName {
    left: 50% !important;
    transform: translate(-50%, -72%) !important;

    & > div {
      overflow: hidden;
    }
  }

  .secondaryColorPickerWrapperClassName {
    left: 50% !important;
    transform: translate(-50%, -72%) !important;

    & > div {
      overflow: hidden;
    }
  }
}

@include from524andLower {
  .outerBoxClassName {
    max-width: unset;
    width: 327px;
    margin-left: 0;
  }
  .innerBoxClassName {
    & > div {
      width: 279px !important;
    }
  }
}
