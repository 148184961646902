@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
.root {
  width: 100%;
  border-radius: 16px;
  background-color: $color-neutral-100;
}

.codeMirrorRoot {
  width: 100%;
  border-radius: 16px;

  :global(.cm-editor) {
    background-color: $color-neutral-100;
  }

  :global(.cm-gutters) {
    background-color: $color-neutral-250;
  }

  :global(.cm-gutterElement) {
    color: $color-text-500;
    text-align: center !important;
    font-family: $font-family-regular;
  }

  :global(.cm-editor.cm-focused) {
    outline: none;
  }

  :global(.cm-placeholder) {
    color: $color-text-600;
    font-family: $font-family-regular;
  }
}
