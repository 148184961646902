@import 'constants/styles/breakpoints.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 913px !important;
  z-index: 13;
  padding: 24px;

  & > hr {
    margin-top: 24px;
  }
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.contentWrapper {
  margin-top: 24px;
  margin-bottom: -6px;
}

.errorClassName {
  margin-bottom: 0;
  height: 20px;
}

.text {
  margin-bottom: 16px;
  display: block;
}

.footer {
  margin-top: 24px;
  align-items: center;
  display: flex;
  > button {
    width: 224px;
    height: 48px !important;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.goBack {
  margin-right: auto;
  cursor: pointer;
}

.overlay {
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .dialogContentWrapperClassName {
    height: auto !important;
  }

  .titleClassName {
    max-width: 250px;
  }

  .goBack {
    margin-right: unset;
  }

  .footer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    & > button {
      min-width: 100%;
      width: 100%;
    }
  }
}
