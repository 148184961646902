@import 'constants/styles/breakpoints.constant.module';
@import 'constants/styles/colors.constant.module';

.sidebar {
  border: 1px solid $color-white;
  border-radius: 20px;
  background: $color-white;

  > hr {
    padding: 0;
    margin: 0;
  }
}

.sidebarTitleText {
  padding: 24px 24px;
}

.hidden {
  display: none;
}

@include from1920andBigger {
  .sidebar {
    width: 440px;
  }
}

@include from1919andLower {
  .sidebar {
    width: 381px;
  }
}

@include from1023andLower {
  .sidebar {
    width: 294px;
  }
}

@include from767andLower {
  .sidebar {
    width: 100%;
    max-width: 100%;
    margin-bottom: 200px;
    border-radius: 0;
  }
}
