@import 'constants/styles/colors.constant.module.scss';
@import 'constants/styles/trial-label.constant.module.scss';
@import 'constants/styles/header.constant.module.scss';
@import 'constants/styles/nav-bar.constant.module.scss';
@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/common.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 913px !important;
  z-index: 13;
  padding: 24px;
  hr {
    margin-top: 24px;
  }
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.btn {
  width: 224px;
  height: 48px;
}

.textWrapper {
  margin-top: 24px;
  min-height: 52px;
  overflow: auto;
}
.controlButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.footer {
  margin-top: 24px;
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .btn {
    width: 178.5px;
  }
  .titleClassName {
    max-width: 250px;
  }
}

@include from524andLower {
  .controlButtons {
    flex-direction: column;
    button {
      width: 100%;
      margin-top: 5px;
    }
  }
  .dialogContentWrapperClassName {
    height: calc(100% - 48px);
    display: grid;
    grid-template-rows: 51px auto 24px 138px;
  }
}

@media screen and (max-height: 424px) and (min-width: $bp524) {
  // 424px === 376(popup height) + 24 + 24)
  .dialogContentWrapperClassName {
    height: calc(100% - 48px);
    display: grid;
    grid-template-rows: 51px auto 24px 72px;
  }
}
