@import 'constants/styles/colors.constant.module';
@import 'constants/styles/breakpoints.constant.module';

.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.noPaymentMethods {
  width: 100%;
  padding: 48px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: $color-primary-background;
}

.cardWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: $color-primary-background;
  gap: 8px;

  .column {
    flex: 1;
    gap: 8px;
    display: flex;
    flex-direction: column;

    .numberWrapper {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }
  }
}

.cardWrapperError {
  background-color: $color-destructive-100;
}

@include from767andLower {
  .buttonWrapper {
    width: 100%;
  }

  .cardWrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}
