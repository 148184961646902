@import 'constants/styles/breakpoints.constant.module.scss';

.resetPassword {
  display: grid;
  grid-template-columns: 568px;
  grid-template-rows: auto;
  row-gap: 48px;
}

.rainbowBlock {
  padding: 40px;
  width: inherit;
  height: inherit;
}

.textClassName,
.headingClassName {
  width: 397px;
}

.formContainer {
  padding: 32px 48px;
}

@include from1439andLower {
  .resetPassword {
    grid-template-columns: 632px;
  }
}

@include from1023andLower {
  .formContainer {
    padding: 32px;
  }
  .resetPassword {
    grid-template-columns: 640px;
  }

  .rainbowBlock {
    padding: 32px;
  }
}

@include from767andLower {
  .formContainer {
    padding: 16px;
  }
  .resetPassword {
    grid-template-columns: 279px;
  }

  .rainbowBlock {
    padding: 24px 16px;
  }
}
