@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.row {
  display: flex;
  align-items: center;
}

/* START: ROOT DIALOG STYLES */

.dialogOverlay {
  z-index: 11;
}

.overflow {
  z-index: 13;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dialogContent {
  margin-top: 16px;
  width: 913px !important;
  z-index: 13;
  padding: 24px;
  margin-bottom: 48px;

  &::-webkit-scrollbar {
    width: 1px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: $color-grey-4;
    width: 1px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-grey-1;
    width: 1px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-typography-body;
  }
}

.dialogDescription {
  margin-top: 24px;
}

.footer {
  display: flex;
  border-top: 1px solid $color-divider;
  padding-top: 24px;
  margin-top: 24px;

  > button {
    width: 224px;
    height: 48px;
  }
}

.checkbox {
  width: 20px;
  height: 20px;

  & > span {
    width: 12px;
    height: 12px;
    top: 3px;
  }
}

.dialogBody {
  margin-top: 16px;
}

@include from1049andLower {
  .dialogContent {
    width: unset !important;
  }
}

@include from524andLower {
  .footer {
    flex-direction: column;

    > button {
      width: 100%;
    }
  }

  .dialogBody {
    margin-top: 8px;
  }

  .dialogDescription {
    margin-top: 8px;
  }
}

/* END: ROOT DIALOG STYLES */

/* START: PRODUCTS LIST*/

.list {
  width: 100%;
  min-height: 450px;
}

@include from1919andLower {
  .list {
    min-height: initial;
    height: 332px !important;
  }
}

@include from1439andLower {
  .list {
    min-height: initial;
    height: 302px !important;
  }
}

@include from767andLower {
  .list {
    min-height: initial;
    height: 252px !important;
  }
}

@include from524andLower {
  .list {
    height: 96px !important;
  }
}

/* END: PRODUCTS LIST*/

/* START: PRODUCTS LIST ITEM*/

.name {
  color: $color-primary-text;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rowWrapper {
  border-bottom: 1px solid $color-divider;
  padding: 0 8px;
  transition: background-color 300ms ease-in;

  &:first-child {
    border-top: 1px solid $color-divider;
  }
  &:hover {
    background-color: $color-divider;
  }
}

.product {
  @extend .row;
  padding-left: 8px;
  width: 100%;
  justify-content: space-between;
  min-height: 52px;
  max-height: 52px;

  &:hover {
    cursor: pointer;

    .name {
      color: $color-pink;
    }
  }
}

.image {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
}

.left,
.right {
  @extend .row;
}

.left {
  width: calc(100% - 115px);
}

.right {
  justify-content: flex-end;
}

.loader {
  & > div {
    width: 20px;
    height: 20px;

    div {
      width: 20px;
      height: 20px;
      margin: 0;
      border-width: 2px;
    }
  }
}

/* END: PRODUCTS LIST ITEM*/

/* START: PRODUCTS FILTER*/

.filter {
  display: flex;
  height: 48px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 16px;
}

.adornment {
  margin-right: 8px;
}

.checkboxLabel {
  margin-left: 16px;

  & > div {
    color: $color-text-600 !important;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
}

.optionTypography {
  font-size: 0.875rem;
}

.search {
  display: flex;
  justify-content: space-between;
  width: 454px;
  align-items: center;
}

.searchInput {
  width: 308px;
}

.searchError {
  margin: 0;
}

.filterSelect {
  width: 130px;
  height: 48px;

  button {
    width: 130px;
    height: 48px;
  }
}

.filterError {
  display: none !important;
}

.checkboxBlock {
  /* not empty */
}

@include from767andLower {
  .filter {
    flex-direction: column-reverse;
    height: unset;
    justify-content: initial;
    align-items: flex-start;
    padding-left: 0;
  }

  .search {
    margin-bottom: 14px;
    width: 100%;
  }

  .checkboxBlock {
    padding-left: 16px;
  }
}

@include from524andLower {
  .search {
    flex-direction: column;
  }

  .searchInput {
    width: 100%;
    margin-bottom: 10px;
  }

  .filterSelect {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

/* END: PRODUCTS FILTER*/
