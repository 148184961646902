@import '../common.module';

$link-width: 85.5px;

div.link {
  @extend .edit;
  white-space: pre-wrap;
  text-align: right;
}

.heading {
  @extend .title;
  margin-bottom: 8px;
}

.selectHeading {
  @extend .title;
  margin-bottom: 5px;
}

.methods {
  max-width: 289px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;

  .radio {
    margin-bottom: 8px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.select {
  cursor: pointer;
}

div.radioWrapper {
  > label:last-child {
    margin-right: 0;
  }
}

.taxExemptWrapper {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
}

.taxRadioButtonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.submissionPending {
  position: absolute;
  top: 3px;
  right: -140px;
}

@include from767andLower {
  .heading {
    width: calc(100% - $link-width);
    margin-bottom: 0;
  }
  .link {
    width: $link-width;
  }
  div.radioWrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .methods {
    .radio {
      margin-bottom: 16px;
    }
  }
  .radio {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .submissionPending {
    top: inherit;
    right: inherit;
    bottom: -9px;
    left: 0;
    white-space: nowrap;
  }
}
