@import 'constants/styles/breakpoints.constant.module';

.roundButton {
  display: none !important;
}

@include from767andLower {
  .roundButton {
    display: flex !important;
    padding: 10px !important;
    height: auto !important;

    .roundButtonText {
      line-height: 0 !important;
    }
  }
}
