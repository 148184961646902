@import 'constants/styles/breakpoints.constant.module.scss';
@import 'constants/styles/fonts.constant.module.scss';
@import 'constants/styles/colors.constant.module.scss';

.dialogOverlayClassName {
  z-index: 11;
}

.dialogContentWrapperClassName {
  width: 913px !important;
  height: calc(50vh + 96px + 24px + 94px) !important;
  overflow: hidden;
  z-index: 13;
  padding: 24px;

  & > hr {
    margin-top: 24px;
  }
}

.closeIconClassName {
  & * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
  }
}

.contentWrapper {
  margin-top: 24px;
  margin-bottom: -6px;
  height: 50vh;
  overflow-y: scroll;

  color: $color-text-subdued;
  background: $color-white;
  font-size: 1rem;
  line-height: 162.5%;
  z-index: 1;

  .bold,
  strong,
  b {
    font-family: $font-family-bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $font-family-bold;
    color: $color-text-subdued;
    text-align: left;
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.125em;
    padding-bottom: 20px;
    text-transform: uppercase;
  }

  section {
    margin-bottom: 2em;
  }

  .textCenter {
    text-align: center;
  }

  .paragraphTitle {
    display: block;
    font-family: $font-family-bold;
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .companyName {
    font-family: $font-family-bold;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  p {
    color: $color-text-subdued;
    line-height: 162.5%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol,
  ul {
    padding-left: 30px;

    li {
      margin-bottom: 16px;
      line-height: 162.5%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style-type: disc;
  }

  a {
    text-decoration: underline;
  }

  .bold {
    font-family: $font-family-bold;
  }

  .italic {
    font-style: italic;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.errorClassName {
  margin-bottom: 0;
  height: 20px;
}

.text {
  margin-bottom: 16px;
  display: block;
}

.footer {
  margin-top: 24px;
  align-items: center;
  display: flex;
  > button {
    width: 224px;
    height: 48px !important;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.goBack {
  margin-right: auto;
  cursor: pointer;
}

@include from1049andLower {
  .dialogContentWrapperClassName {
    width: unset !important;
  }
}

@include from767andLower {
  .dialogContentWrapperClassName {
    height: auto !important;
  }

  .titleClassName {
    max-width: 250px;
  }

  .goBack {
    margin-right: unset;
  }

  .footer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    & > button {
      min-width: 100%;
      width: 100%;
    }
  }
}
