@import '../../constants/styles/colors.constant.module.scss';
@import '../../constants/styles/breakpoints.constant.module';

.pageInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root {
  max-width: 976px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

div.container {
  padding: 30px 40px;
}

div.header {
  display: block;
  margin-bottom: 24px;
}

div.bodyText {
  display: block;
  margin: 30px 0;
}

button.button {
  min-width: 224px;
  height: 48px;
}

button.buttonDanger {
  background-color: $color-error-text !important;

  &:hover {
    background-color: $color-destructive-700 !important;
  }
}

button.buttonDangerDisabled {
  background-color: $color-destructive-100 !important;

  &:hover {
    background-color: $color-destructive-100 !important;
  }
}

.permissionContainer {
  margin-top: 30px;
}

.permissions {
  display: flex;
  gap: 10px 20px;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 30px;

  &Last {
    margin-bottom: 0;
  }
}

button.buttonDangerLoading {
  background-color: $color-destructive-100 !important;

  &:hover {
    background-color: $color-destructive-100 !important;
  }
}

.row {
  margin-top: 6px;
  display: flex;
  gap: 16px;
}

.rowFirst {
  margin-top: 30px;
  display: flex;
  gap: 16px;
}

.input {
  flex: 1;
}

.label {
  color: $color-text-900 !important;
}

.buttons {
  display: flex;
  flex-direction: row;
  width: 100%;

  button {
    margin-right: 16px;

    &:last-child {
      margin: 0;
    }
  }
}

.tooltipWrapper {
  display: flex;
  flex-direction: row;
}

.iconWrapper {
  margin-left: 6px;
  margin-top: 7px;
}

.tooltipText {
  width: 320px;
  height: auto;
}

@include from1439andLower {
  .buttons {
    button {
      margin-right: 30px;
    }
  }
}

@include from1023andLower {
  .buttons {
    button {
      margin-right: 16px;
    }
  }

  .iconWrapper {
    margin-top: -1px;
  }
}

@include from767andLower {
  div.container {
    padding: 32px 24px;
  }

  .row {
    gap: 6px;
    flex-direction: column;
  }

  button.button {
    width: 100%;
  }

  .buttons {
    flex-direction: column;

    button {
      margin-right: 0;
      margin-bottom: 16px;

      &:last-child {
        margin: 0;
      }
    }
  }

  .permissions {
    flex-direction: column;
    gap: 16px;
  }

  .tooltipText {
    width: 180px;
    height: auto;
  }
}
